"use es6";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";

import { gapi } from "gapi-script";
import jwt_decode from "jwt-decode";
import Button from "../Buttons/Button";
import H1 from "../Text/H1";

import sfh_wordmark2 from "../../graphics/branding/sfh_wordmark2.svg";

import chevron from "../../graphics/icons/chevron.svg";
import H3 from "../Text/H3";
import H2 from "../Text/H2";
import P from "../Text/P";
import HR from "../Text/HR";
import Viewport from "./Viewport";

const AboutViewport = ({
  isDesktop = null,
  children = "[insert text]",
  credentials = null,
  setCredentials = {},
  updateCredentials = {},
  language = null,
  translation = null,
  aboutViewportVisible = false,
  setAboutViewportVisible = {},
}) => {
  const navigate = useNavigate();

  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  return (
    <Viewport
      isDesktop={isDesktop}
      heading={translation.navigation.about_us}
      viewportVisible={aboutViewportVisible}
      setViewportVisible={setAboutViewportVisible}
    >
      <div style={{ height: 10 }} />
      <div
        style={{
          margin: "auto",
          justifyContent: "center",
          textAlign: "center",
          maxHeight: 550,
          overflowY: "scroll",
        }}
      >
        <img
          src={sfh_wordmark2}
          style={{
            marginTop: 20,
            width: "50%",
            paddingBottom: isDesktop && 5,
          }}
        />
        <div style={{ margin: 50, textAlign: "left" }}>
          <br />
          <br />
          <H2 style={{ marginBottom: 15 }}>
            {translation.homepage.mission_header}
          </H2>
          <P style={{ marginBottom: 30 }}>
            {translation.homepage.mission_body}
          </P>
          <H2 style={{ marginBottom: 15 }}>
            {translation.homepage.process_header}
          </H2>
          <P style={{ marginBottom: 10 }}>
            {translation.homepage.process_body}
          </P>
        </div>
      </div>
    </Viewport>
  );
};

export default AboutViewport;

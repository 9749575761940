"use es6";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import xmark from "../../graphics/icons/xmark.svg";
import P from "../Text/P";
import Button from "../Buttons/Button";
import HR from "../Text/HR";
import DifferencesViewport from "../Viewports/DifferencesViewport";
import Loading from "../Loading/Loading";

const Notification = ({
  credentials = {},
  isDesktop = {},
  updateCredentials = {},
  translation = null,
  setNotificationsViewportVisible = {},
  notification = [],
  loadedNotifications = {},
  setLoadedNotifications = null,
  setDeletedNotifications = null,
  notificationId = null,
  ...buttonProps
}) => {
  const navigate = useNavigate();

  const [differencesViewportVisible, setDifferencesViewportVisible] =
    useState(false);

  const [sender, setSender] = useState(null);
  const [deleted, setDeleted] = useState(false);
  const [loading, setLoading] = useState(false);

  const [company, setCompany] = useState(null);

  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  const getSender = async () => {
    axios
      .get(
        `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${notification[1].sender}`,
        requestConfig
      )
      .then((res) => {
        setSender(res.data);
        if (!notification[1].data.id) {
          setLoadedNotifications((prevLoadedNotifications) => ({
            ...prevLoadedNotifications,
            ...{ [notificationId]: true },
          }));
        }
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  const getCompany = async () => {
    axios
      .get(
        `${process.env.REACT_APP_COMPANYS_API_URL}/companys/${notification[1].data.id}`,
        requestConfig
      )
      .then((res) => {
        setCompany(res.data);
        if (!!notification[1].data.id) {
          setLoadedNotifications((prevLoadedNotifications) => ({
            ...prevLoadedNotifications,
            ...{ [notificationId]: true },
          }));
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (!!!loadedNotifications[notificationId]) {
      getSender();
      if (!!notification[1].data.id) {
        getCompany();
      }
    }
  }, []);

  const deleteNotification = async (id) => {
    const requestBody = {
      notificationId: id,
      deleteNotifications: true,
    };
    axios
      .post(
        `${process.env.REACT_APP_NOTIFICATIONS_API_URL}/notifications/${credentials.id}`,
        requestBody,
        requestConfig
      )
      .then(() => {
        setDeleted(true);
        setDeletedNotifications(
          (prevDeletedNotifications) => prevDeletedNotifications + 1
        );
      })
      .catch((error) => {});
  };

  const sendAcceptance = async (recipient) => {
    const requestBody = {
      action: {
        id: Date.now(),
        sender: credentials.id,
        type: "acceptance",
        data: {
          type: !!company ? "edited" : "added",
          name: notification[1].data.name,
          slug: notification[1].data.slug,
        },
      },
    };
    await axios
      .post(
        `${process.env.REACT_APP_NOTIFICATIONS_API_URL}/notifications/${recipient}`,
        requestBody,
        requestConfig
      )
      .then(async () => {
        // update company & allot badges
        const newpayload = {
          ...notification[1].data,
        };
        await axios
          .post(
            `${process.env.REACT_APP_COMPANYS_API_URL}/companys/${
              !!company ? notification[1].data.id : ""
            }`,
            !!company ? notification[1].data : newpayload,
            requestConfig
          )
          .then(async () => {
            await axios
              .get(
                `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${recipient}`,
                requestConfig
              )
              .then(async (res) => {
                let newReservations = res.data.reservations;
                delete newReservations[notification[1].data.id];
                await axios
                  .post(
                    `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${recipient}`,
                    { reservations: newReservations },
                    requestConfig
                  )
                  .then(async () => {
                    await axios.post(
                      `${process.env.REACT_APP_EMAILS_API_URL}/authenticate`,
                      { message: "notification", to: sender.email },
                      requestConfig
                    );
                    deleteNotification(notification[0]);
                  });
              });
          })
          .catch((error) => {
            // console.log(error);
          });
      })
      .catch((error) => {});
  };

  const sendRejection = async (recipient) => {
    const reason = prompt(translation.company.rejection_prompt);
    const requestBody = {
      action: {
        id: Date.now(),
        sender: credentials.id,
        type: "rejection",
        data: { reason: reason },
      },
    };
    await axios
      .post(
        `${process.env.REACT_APP_NOTIFICATIONS_API_URL}/notifications/${recipient}`,
        requestBody,
        requestConfig
      )
      .then(async () => {
        await axios
          .get(
            `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${recipient}`,
            requestConfig
          )
          .then(async (res) => {
            let newReservations = res.data.reservations;
            newReservations[notification[1].data.id] = Date.now();
            axios
              .post(
                `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${recipient}`,
                { reservations: newReservations },
                requestConfig
              )
              .then(async () => {
                axios.post(
                  `${process.env.REACT_APP_EMAILS_API_URL}/authenticate`,
                  { message: "notification", to: sender.email },
                  requestConfig
                );
                deleteNotification(notification[0]);
              });
          });
      })
      .catch((error) => {});
  };

  return !!sender && !deleted ? (
    <div>
      <HR />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 5,
        }}
      >
        <div style={{ display: "flex", alignContent: "center" }}>
          <img
            src={
              sender.image ||
              "https://sfh-defaults.s3.amazonaws.com/defaultprofile.png"
            }
            style={{
              width: 48,
              height: 48,
              objectFit: "cover",
              borderWidth: 1,
              borderColor: "#101010",
              borderStyle: "solid",
              borderRadius: 48,
              margin: 10,
            }}
            onClick={() => {
              navigate(`/${sender.username}`);
              setNotificationsViewportVisible(false);
            }}
            onMouseOver={({ currentTarget }) => {
              currentTarget.style.opacity = "50%";
            }}
            onMouseOut={({ currentTarget }) => {
              currentTarget.style.opacity = "100%";
            }}
          />
          <div>
            <P style={{ marginTop: 10, fontSize: 14 }}>
              @{sender.username}
              {notification[1].type === "request" && !!company
                ? `${translation.notification.request_edit} ${company.name} (${company.slug})`
                : notification[1].type === "request" && !company
                ? `${translation.notification.request_add} ${notification[1].data.name} (${notification[1].data.slug})`
                : notification[1].type === "acceptance"
                ? translation.notification.acceptance
                : notification[1].type === "rejection"
                ? translation.notification.rejection
                : notification[1].type === "relegate"
                ? `${translation.notification.relegate} ${notification[1].data.name} (${notification[1].data.slug})`
                : ""}
            </P>
            {notification[1].type === "request" ? (
              <Button
                type="solid"
                style={{
                  marginTop: 10,
                  fontSize: 14,
                  paddingTop: 5,
                  paddingRight: 10,
                  paddingBottom: 5,
                  paddingLeft: 10,
                  maxWidth: 200,
                }}
                onClick={() => {
                  setDifferencesViewportVisible(true);
                }}
              >
                {translation.notification.view_differences}
              </Button>
            ) : notification[1].type === "acceptance" ? (
              <div>
                <P>
                  {translation.company[notification[1].data.type] +
                    " " +
                    notification[1].data.name +
                    " (" +
                    notification[1].data.slug +
                    ")"}
                </P>
              </div>
            ) : notification[1].type === "rejection" ? (
              <div>
                <P style={{ color: "#555555", fontStyle: "italic" }}>
                  {translation.company.rejection_reasoning}
                </P>
                <P>"{notification[1].data.reason}"</P>
              </div>
            ) : notification[1].type === "relegate" ? (
              <Button
                type="solid"
                style={{
                  marginTop: 10,
                  fontSize: 14,
                  paddingTop: 5,
                  paddingRight: 10,
                  paddingBottom: 5,
                  paddingLeft: 10,
                  maxWidth: 200,
                }}
                onClick={() => {
                  setDifferencesViewportVisible(true);
                }}
              >
                {translation.notification.view_differences}
              </Button>
            ) : null}
          </div>
        </div>

        {notification[1].type === "request" ||
        notification[1].type === "relegate" ? (
          <div style={{ display: "flex" }}>
            {loading ? (
              <Loading
                style={{
                  width: 32,
                  height: 32,
                  margin: 0,
                }}
              />
            ) : null}
            <div>
              <Button
                type="solid"
                loading={loading}
                style={{
                  margin: 10,
                  fontSize: 14,
                  paddingTop: 5,
                  paddingRight: 10,
                  paddingBottom: 5,
                  paddingLeft: 10,
                  backgroundColor: "#64804d",
                  opacity: loading ? 0.5 : 1,
                }}
                onClick={() => {
                  if (!loading) {
                    setLoading(true);
                    sendAcceptance(notification[1].sender);
                  }
                }}
              >
                {translation.notification.accept}
              </Button>
              <Button
                type="solid"
                loading={loading}
                style={{
                  margin: 10,
                  fontSize: 14,
                  paddingTop: 5,
                  paddingRight: 10,
                  paddingBottom: 5,
                  paddingLeft: 10,
                  backgroundColor: "#eb5757",
                  opacity: loading ? 0.5 : 1,
                }}
                onClick={() => {
                  if (!loading) {
                    setLoading(true);
                    sendRejection(notification[1].sender);
                  }
                }}
              >
                {translation.notification.reject}
              </Button>
            </div>
          </div>
        ) : (
          <div
            onClick={() => {
              deleteNotification(notification[0]);
            }}
            onMouseOver={({ currentTarget }) => {
              currentTarget.style.opacity = "50%";
            }}
            onMouseOut={({ currentTarget }) => {
              currentTarget.style.opacity = "100%";
            }}
          >
            <img
              src={xmark}
              style={{
                width: 16,
                height: 16,
                margin: 10,
                marginTop: 12,
                filter: "brightness(0)",
              }}
            />
          </div>
        )}
      </div>
      {!!differencesViewportVisible ? (
        <DifferencesViewport
          isDesktop={isDesktop}
          credentials={credentials}
          updateCredentials={updateCredentials}
          translation={translation}
          differencesViewportVisible={differencesViewportVisible}
          setDifferencesViewportVisible={setDifferencesViewportVisible}
          sendAcceptance={sendAcceptance}
          sendRejection={sendRejection}
          deleteNotification={deleteNotification}
          notification={notification}
          currentData={company || null}
          newData={notification[1].data}
          loading={loading}
          setLoading={setLoading}
        />
      ) : null}
    </div>
  ) : null;
};

export default Notification;

"use es6";

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import H1 from "../components/Text/H1";
import H2 from "../components/Text/H2";

import sfh_wordmark2 from "../graphics/branding/sfh_wordmark2.svg";

import english from "../translations/english.json";
import spanish from "../translations/spanish.json";
import french from "../translations/french.json";
import chinese from "../translations/chinese.json";

import { useWindowDimensions } from "../utils/CustomHooks";

const UnknownPage = () => {
  const location = useLocation();

  const language = navigator.language;

  const translations = {
    en: english,
    es: spanish,
    fr: french,
    zh: chinese,
  };

  const translation = !!translations[language.substring(0, 2)].authentication
    ? translations[language.substring(0, 2)]
    : translations.en;

  const dimensions = useWindowDimensions();

  const isDesktop = dimensions.width > 910;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div
      style={{
        margin: 20,
        marginTop: 50,
        maxWidth: isDesktop ? dimensions.width - 360 : null,
      }}
    >
      <img src={sfh_wordmark2} style={{ height: 48, marginBottom: 10 }} />
      <H1 className="label" style={{ fontSize: "48px" }}>
        {translation.unknownpage.heading}
      </H1>
      <br />
      <br />

      <H2>{translation.unknownpage.subheading}</H2>
      <br />
    </div>
  );
};

export default UnknownPage;

"use es6";

import { useState } from "react";
import Loading from "../Loading/Loading";
import Button from "../Buttons/Button";
import P from "../Text/P";
import H2 from "../Text/H2";
import H3 from "../Text/H3";
import FinancesChart from "../Charts/FinancesChart";
import CompanyDataCell from "./CompanyDataCell";

const CompanyOverview = ({
  translation = null,
  isDesktop,
  dimensions,
  slug,
  company,
  currencycode,
  activeSustainabilityData = {},
  financials,
}) => {
  const MarketCapCurrency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const ShareCurrency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencycode,
  });

  const Currency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <div style={{}}>
      <div
        style={{
          display: isDesktop ? "flex" : "block",
          width: isDesktop && dimensions.width - 320,
          justifyContent: "flex-start",
          margin: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            overflowX: "scroll",
            overflowY: "hide",
            width: !isDesktop && dimensions.width - 85,
            marginTop: 20,
          }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            {!!company.bloombergreportdata ? (
              <div>
                <CompanyDataCell
                  key={0}
                  translation={translation}
                  activedata={activeSustainabilityData}
                  company={company}
                  bloombergDataVisible={false}
                  variable={"ghg_scope_1"}
                  year={"2022"}
                />
              </div>
            ) : null}
            {!!company.canadaownership &&
            Object.keys(company.canadaownership).length > 0 ? (
              <div style={{ marginLeft: 20 }}>
                <H3
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    padding: 0,
                    fontSize: 16,
                  }}
                >
                  Historical Canadian Ownership 🇨🇦 - No. Shares
                </H3>
                <div
                  style={{
                    fontSize: 13,
                    color: "#ffffff",
                    backgroundColor: "#ffffff",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      backgroundColor: "#64804d",
                    }}
                  >
                    <div
                      style={{
                        width: 120,
                        borderWidth: 1,
                        borderColor: "#ffffff",
                        borderStyle: "solid",
                        padding: 4,
                      }}
                    >
                      Name
                    </div>
                    {["2018", "2019", "2020", "2021", "2022", "2023"].map(
                      (year, index) => (
                        <div
                          key={index}
                          style={{
                            width: 90,
                            borderWidth: 1,
                            borderLeftWidth: 0,
                            borderColor: "#ffffff",
                            borderStyle: "solid",
                            padding: 4,
                          }}
                        >
                          {year}
                        </div>
                      )
                    )}
                  </div>
                  <div
                    style={{
                      height: 225,
                      overflowY: "scroll",
                      borderWidth: 0,
                      borderBottomWidth: 1,
                      borderColor: "#ffffff",
                      borderStyle: "solid",
                    }}
                  >
                    {Object.keys(company.canadaownership).map((firm, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          backgroundColor: "#000000",
                        }}
                      >
                        {firm.indexOf(":") !== -1 ? (
                          <div
                            style={{
                              cursor: "pointer",
                              width: 120,
                              borderWidth: 1,
                              borderTopWidth: 0,
                              borderColor: "#ffffff",
                              borderStyle: "solid",
                              padding: 4,
                              fontWeight: "bold",
                              color: "#64804d",
                            }}
                            onMouseOver={({ currentTarget }) => {
                              currentTarget.style.opacity = "50%";
                            }}
                            onMouseOut={({ currentTarget }) => {
                              currentTarget.style.opacity = "100%";
                            }}
                            onClick={() => {
                              openInNewTab(
                                `https://www.sustainablefinancehub.net/explore/${
                                  firm.split(":")[1]
                                }`
                              );
                            }}
                          >
                            {firm.split(":")[0]}
                          </div>
                        ) : (
                          <div
                            style={{
                              width: 120,
                              borderWidth: 1,
                              borderTopWidth: 0,
                              borderColor: "#ffffff",
                              borderStyle: "solid",
                              padding: 4,
                            }}
                          >
                            {firm.split(":")[0]}
                          </div>
                        )}
                        {["2018", "2019", "2020", "2021", "2022", "2023"].map(
                          (year, index) => (
                            <div
                              key={index}
                              style={{
                                width: 90,
                                borderWidth: 1,
                                borderLeftWidth: 0,
                                borderLeftWidth: 0,
                                borderColor: "#ffffff",
                                borderStyle: "solid",
                                padding: 4,
                              }}
                            >
                              {!!company.canadaownership[firm][year]
                                ? String(
                                    Currency.format(
                                      company.canadaownership[firm][year]
                                    )
                                  )
                                    .split(".")[0]
                                    .split("$")[1]
                                : ""}
                            </div>
                          )
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : null}

            {!!company.sib ? (
              <div style={{ marginLeft: 20 }}>
                <H3
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    padding: 0,
                    fontSize: 16,
                  }}
                >
                  Carbon Financing 🏦 - USD (
                  {`${Object.keys(company.financing).length} Companies`})
                </H3>
                <div
                  style={{
                    height: 200,
                    fontSize: 13,
                    color: "#ffffff",
                    backgroundColor: "#ffffff",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      backgroundColor: "#64804d",
                    }}
                  >
                    <div
                      style={{
                        width: 120,
                        borderWidth: 1,
                        borderColor: "#ffffff",
                        borderStyle: "solid",
                        padding: 4,
                      }}
                    >
                      Name
                    </div>
                    {["2018", "2019", "2020", "2021", "2022"].map(
                      (year, index) => (
                        <div
                          key={index}
                          style={{
                            width: 90,
                            borderWidth: 1,
                            borderLeftWidth: 0,
                            borderColor: "#ffffff",
                            borderStyle: "solid",
                            padding: 4,
                          }}
                        >
                          {year}
                        </div>
                      )
                    )}
                  </div>
                  <div
                    style={{
                      height: 225,
                      overflowY: "scroll",
                      borderWidth: 0,
                      borderBottomWidth: 1,
                      borderColor: "#ffffff",
                      borderStyle: "solid",
                    }}
                  >
                    {Object.keys(company.financing).map((firm, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          backgroundColor: "#000000",
                        }}
                      >
                        {firm.indexOf(":") !== -1 ? (
                          <div
                            style={{
                              cursor: "pointer",
                              width: 120,
                              borderWidth: 1,
                              borderTopWidth: 0,
                              borderColor: "#ffffff",
                              borderStyle: "solid",
                              padding: 4,
                              fontWeight: "bold",
                              color: "#64804d",
                            }}
                            onMouseOver={({ currentTarget }) => {
                              currentTarget.style.opacity = "50%";
                            }}
                            onMouseOut={({ currentTarget }) => {
                              currentTarget.style.opacity = "100%";
                            }}
                            onClick={() => {
                              openInNewTab(
                                `https://www.sustainablefinancehub.net/explore/${
                                  firm.split(":")[1]
                                }`
                              );
                            }}
                          >
                            {firm.split(":")[0]}
                          </div>
                        ) : (
                          <div
                            style={{
                              width: 120,
                              borderWidth: 1,
                              borderTopWidth: 0,
                              borderColor: "#ffffff",
                              borderStyle: "solid",
                              padding: 4,
                            }}
                          >
                            {firm.split(":")[0]}
                          </div>
                        )}
                        {["2018", "2019", "2020", "2021", "2022"].map(
                          (year, index) => (
                            <div
                              key={index}
                              style={{
                                width: 90,
                                borderWidth: 1,
                                borderLeftWidth: 0,
                                borderLeftWidth: 0,
                                borderColor: "#ffffff",
                                borderStyle: "solid",
                                padding: 4,
                              }}
                            >
                              {!!company.financing[firm][year]
                                ? String(
                                    Currency.format(
                                      company.financing[firm][year]
                                    )
                                  ).split(".")[0]
                                : ""}
                            </div>
                          )
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyOverview;

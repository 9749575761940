"use es6";

import React, { useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import axios from "axios";
import { useComponentVisible } from "../../utils/CustomHooks";

import P from "../Text/P";
import Button from "../Buttons/Button";
import search from "../../graphics/icons/search.svg";

const SearchBar = ({
  isDesktop = null,
  translation = null,
  sourceData = {},
  showSuggestions = true,
  setResult = {},
  light = false,
  browsingUsers = false,
  setActivePage = {},
  ...buttonProps
}) => {
  const navigate = useNavigate();

  const [filteredData, setFilteredData] = useState([]);
  const [query, setQuery] = useState("");
  const [suggestionsVisible, setSuggestionsVisible] = useState(true);

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(true);

  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  const sortedResults = (input, data) => {
    var first = [];
    var others = [];
    for (var i = 0; i < data.length; i++) {
      if (data[i].name.toUpperCase().startsWith(input.toUpperCase())) {
        first.push(data[i]);
      } else {
        others.push(data[i]);
      }
    }
    first.sort((a, b) => {
      var textA = a.name.toUpperCase();
      var textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    others.sort((a, b) => {
      var textA = a.name.toUpperCase();
      var textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    return [...first, ...others];
  };

  const queryFilter = (text) => {
    if (text) {
      const newData = sourceData.filter((item) => {
        const nameData = item.name ? item.name.toUpperCase() : "".toUpperCase();
        const numberData = item.number
          ? `+${item.number.countrycode} (${item.number.areacode}) ${item.number.prefix}-${item.number.subscriber}`
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return (
          nameData.indexOf(textData) !== -1 ||
          numberData.indexOf(textData) !== -1
        );
      });
      setFilteredData(newData.slice(0, 5));
    } else {
      setFilteredData(sourceData.slice(0, 5));
    }
    setQuery(text);
  };

  const browseFilter = (text) => {
    if (text) {
      axios
        .get(
          `${
            process.env.REACT_APP_COMPANYS_API_URL
          }/browsing/${text.toUpperCase()}`,
          requestConfig
        )
        .then((res) => {
          const data = res.data;
          setFilteredData(sortedResults(text, data).slice(0, 5));
        })
        .catch((error) => {});
    }
    setQuery(text);
  };

  const ItemView = ({ item }) => {
    return (
      <div
        style={{ display: "flex", cursor: "pointer" }}
        onClick={() => {
          setQuery(`${item.slug}`);
          if (browsingUsers) {
            navigate(`/explore/${item.slug}`);
            setActivePage("explore");
          } else {
            setResult(item);
          }
          setSuggestionsVisible(false);
        }}
      >
        <img
          src={
            item.image ||
            "https://sfh-defaults.s3.amazonaws.com/defaultprofile.png"
          }
          style={{
            margin: 10,
            borderWidth: 1,
            borderColor: "#ccc",
            borderRadius: 4,
            borderStyle: "solid",
            maxWidth: 40,
            maxHeight: 20,
            padding: 5,
            backgroundColor: "#ffffff",
            objectFit: "cover",
          }}
        />
        <P style={{ paddingTop: 16 }}>{item.name.substring(0, 60)}</P>
      </div>
    );
  };

  return !!translation ? (
    <div style={{}}>
      <div style={{ display: "flex" }}>
        <input
          type="text"
          style={{
            flex: 1,
            padding: 10,
            borderRadius: 8,
            backgroundColor: "#ffffff",
            borderStyle: "solid",
            borderWidth: 0.5,
            borderColor: "#ccc",
            color: light ? "#ffffff" : "#000000",
            fontSize: 16,
            marginRight: 10,
            minWidth: 318,
            maxWidth: 500,
          }}
          value={query}
          placeholder={translation.search_bar.search_cta}
          onChange={(event) => {
            setSuggestionsVisible(true);
            if (browsingUsers) {
              browseFilter(event.target.value);
            } else {
              queryFilter(event.target.value);
            }
            if (event.target.value.length === 0) {
              setFilteredData([]);
              setSuggestionsVisible(false);
            }
            setIsComponentVisible(true);
          }}
        />
        <Button
          type="solid"
          style={{ padding: 10 }}
          onClick={() => {
            navigate(`/${query}`);
            setActivePage("explore");
          }}
        >
          <img src={search} style={{ width: 24, height: 24, marginTop: -2 }} />
        </Button>
      </div>
      <div ref={ref}>
        {showSuggestions &&
          isComponentVisible &&
          suggestionsVisible &&
          filteredData.length > 0 && (
            <div
              style={{
                zIndex: 20,
                width: "100%",
                maxWidth: 320,
                marginLeft: 10,
                backgroundColor: "#ffffff",
                borderStyle: "solid",
                borderWidth: 0.5,
                borderColor: "#ccc",
                position: "absolute",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
            >
              {filteredData.map((data, index) => (
                <ItemView key={index} item={data} index={index} />
              ))}
            </div>
          )}
      </div>
    </div>
  ) : null;
};

export default SearchBar;

"use es6";

import React, { useState, useEffect, useRef, useMemo } from "react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";

import { gapi } from "gapi-script";
import jwt_decode from "jwt-decode";
import Button from "../Buttons/Button";
import H2 from "../Text/H2";

import chevron from "../../graphics/icons/chevron.svg";
import P from "../Text/P";
import HR from "../Text/HR";
import Viewport from "./Viewport";
import Bold from "../Text/Bold";
import Hint from "../Hint/Hint";
import A from "../Text/A";

const ReportsViewport = ({
  isDesktop = null,
  children = "[insert text]",
  credentials = null,
  setCredentials = {},
  updateCredentials = {},
  language = null,
  translation = null,
  reportsViewportVisible = false,
  setReportsViewportVisible = {},
  dataReports = {},
  setDataReports = {},
  type = null,
  company = {},
}) => {
  const navigate = useNavigate();

  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  const [newDataReports, setNewDataReports] = useState(dataReports);

  useEffect(() => {}, []);

  return (
    <Viewport
      isDesktop={isDesktop}
      heading={
        translation.company.update +
        translation.company[`${reportsViewportVisible}_sources`]
      }
      viewportVisible={reportsViewportVisible}
      setViewportVisible={setReportsViewportVisible}
    >
      <div
        style={{
          textAlign: "left",
          maxHeight: isDesktop ? 450 : null,
          overflowY: "scroll",
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        <br />
        {type !== "confirm" ? (
          <div style={{ display: "flex", marginBottom: 10 }}>
            <Button
              type="solid"
              style={{
                fontSize: 14,
                paddingTop: 5,
                paddingRight: 10,
                paddingBottom: 5,
                paddingLeft: 10,
                marginRight: 10,
              }}
              onClick={() => {
                const maxYear =
                  Object.keys(newDataReports).length > 0
                    ? Math.max(
                        ...Object.keys(newDataReports).map((element) =>
                          Number(element)
                        )
                      )
                    : Number(new Date().getFullYear() - 3);
                setNewDataReports({
                  ...newDataReports,
                  ...{
                    [`${maxYear + 1}`]: null,
                  },
                });
              }}
            >
              {translation.company.add_row}
            </Button>
            <Button
              type="solid"
              style={{
                fontSize: 14,
                paddingTop: 5,
                paddingRight: 10,
                paddingBottom: 5,
                paddingLeft: 10,
                backgroundColor: "#eb5757",
              }}
              onClick={() => {
                let tempReports = newDataReports;
                const maxYear = Math.max(
                  ...Object.keys(newDataReports).map((element) =>
                    Number(element)
                  )
                );
                delete tempReports[`${maxYear}`];
                setNewDataReports({ ...tempReports });
              }}
            >
              {translation.company.delete_row}
            </Button>
            {reportsViewportVisible === "annual" ? (
              <Hint
                style={{
                  margin: 4,
                  marginLeft: 10,
                  display: "bottom",
                }}
              >
                If the company has substantial business with the US, there's a
                good chance that a filing can be found{" "}
                <A href="https://www.sec.gov/edgar/searchedgar/companysearch">
                  here
                </A>{" "}
                (make sure the page fully loads before using the search
                function). If the company is based in the US you'll be looking
                for that year's 10-K filing, if it's based in Canada it'll be a
                40-F, otherwise it will be a 20-F. Otherwise, try looking up
                something like{" "}
                <A
                  href={`https://google.com/search?q=${company.name.replaceAll(
                    " ",
                    "%20"
                  )}%20${company.ticker.toLowerCase()}%20report%202022%20filetype:pdf`}
                >
                  this
                </A>{" "}
                in your preferred search engine. Once you find the correct
                report document, add a row for that year in the table below and
                paste in the link.
              </Hint>
            ) : (
              <Hint
                style={{
                  margin: 4,
                  marginLeft: 10,
                  display: "bottom",
                }}
              >
                In order to find the company sustainability report, try looking
                up something like{" "}
                <A
                  href={`https://google.com/search?q=${company.name.replaceAll(
                    " ",
                    "%20"
                  )}%20sustainability%20report%202022%20filetype:pdf`}
                >
                  this
                </A>{" "}
                in your preferred search engine. Once you find the correct
                report document, add a row for that year in the table below and
                paste in the link.
              </Hint>
            )}
          </div>
        ) : null}
        <div style={{ width: "100%", maxWidth: 1000, overflowX: "scroll" }}>
          <table id="companydata-dark" style={{ fontSize: 11 }}>
            <tr>
              <th style={{ width: "1%" }}>Year</th>
              <th>Document Link</th>
            </tr>
            {Object.keys(newDataReports)
              .reverse()
              .map((year, index) => (
                <tr key={index}>
                  <td style={{ opacity: "50%" }}>{year}</td>
                  <td>
                    <input
                      type="text"
                      value={newDataReports[year]}
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0,0,0,0)",
                        borderWidth: 0,
                      }}
                      onChange={(event) => {
                        setNewDataReports({
                          ...newDataReports,
                          [year]: event.target.value,
                        });
                      }}
                    />
                  </td>
                </tr>
              ))}
          </table>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <Button
          type="solid"
          style={{ textAlign: "center", margin: "auto", width: 315 }}
          onClick={() => {
            setDataReports(newDataReports);
            setReportsViewportVisible(false);
          }}
        >
          {translation.button.save}
        </Button>
      </div>
    </Viewport>
  );
};

export default ReportsViewport;

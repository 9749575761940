"use es6";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";

import { gapi } from "gapi-script";
import jwt_decode from "jwt-decode";
import Button from "../Buttons/Button";
import H1 from "../Text/H1";

import xmark from "../../graphics/icons/xmark.svg";
import H3 from "../Text/H3";
import H2 from "../Text/H2";
import P from "../Text/P";
import HR from "../Text/HR";

const BadgeViewport = ({
  type = null,
  credentials = null,
  setCredentials = {},
  updateCredentials = {},
  language = null,
  translation = null,
  setActivePage = {},
  setSettingsViewportVisible = {},
  badgeViewportVisible = false,
  setBadgeViewportVisible = {},
}) => {
  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  return (
    <div
      style={{
        position: "fixed",
        zIndex: 10,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: "rgba(0,0,0,.10)",
      }}
    >
      <div
        style={{
          position: "fixed",
          top: "50%",
          right: "50%",
          bottom: "50%",
          left: "50%",
          margin: "auto",
          backgroundColor: "#ffffff",
          borderRadius: 8,
          width: "90%",
          maxWidth: 400,
          display: "table",
          transform: "translate(-50%, 0)",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "rgba(16, 16, 16, 0.975)",
            borderTopRightRadius: 8,
            borderTopLeftRadius: 8,
            borderBottomStyle: "solid",
            borderBottomWidth: 1,
            borderBottomColor: "#101010",
          }}
        >
          <div
            onClick={() => {
              setBadgeViewportVisible(false);
            }}
          >
            <img
              src={xmark}
              style={{ width: 24, height: 24, margin: 10, marginTop: 12 }}
            />
          </div>
          <P
            style={{
              marginTop: 8,
              marginLeft: 6,
              color: "#ffffff",
              fontSize: 26,
              fontWeight: 700,
            }}
          >
            Badge
          </P>
          <div style={{ width: 24, height: 24, margin: 10, marginTop: 12 }} />
          Blah, blah
        </div>
      </div>
    </div>
  );
};

export default BadgeViewport;

"use es6";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import AWS from "aws-sdk";

import { gapi } from "gapi-script";
import jwt_decode from "jwt-decode";
import Button from "../Buttons/Button";
import H1 from "../Text/H1";

import chevron from "../../graphics/icons/chevron.svg";
import H3 from "../Text/H3";
import H2 from "../Text/H2";
import P from "../Text/P";
import HR from "../Text/HR";
import Viewport from "./Viewport";
import UploadViewport from "./UploadViewport";
import MapViewport from "./MapViewport";

const S3_BUCKET = "sfh-avatars";
const REGION = "us-east-1";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const EditViewport = ({
  isDesktop = false,
  type = null,
  credentials = null,
  setCredentials = {},
  updateCredentials = {},
  language = null,
  translation = null,
  activePage = {},
  setActivePage = {},
  setSettingsViewportVisible = {},
  editViewportVisible = false,
  setEditViewportVisible = {},
  uploadViewportVisible = false,
  setUploadViewportVisible = {},
  mapViewportVisible = false,
  setMapViewportVisible = {},
  setEditTimestamp = {},
}) => {
  const navigate = useNavigate();
  const oldusername = credentials.username;

  const [username, setUsername] = useState(credentials.username || "");
  const [usernameValid, setUsernameValid] = useState(true);
  const [name, setName] = useState(credentials.name || "");
  const [bio, setBio] = useState(credentials.bio || "");
  const [email, setEmail] = useState(credentials.email || "");
  const [emailValid, setEmailValid] = useState(true);
  const [publicaccount, setPublicaccount] = useState(credentials.publicaccount);
  const [image, setImage] = useState(credentials.image || "");
  const [hometown, setHometown] = useState(credentials.hometown || {});

  const [selectedFile, setSelectedFile] = useState(null);
  const [progress, setProgress] = useState(0);

  const uploadFile = (file, id) => {
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: `${id}.jpeg`,
      ContentEncoding: "base64",
    };

    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        setProgress(Math.round((evt.loaded / evt.total) * 100));
      })
      .send((err) => {
        // if (err) console.log(err);
      });
  };

  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  const checkUsername = (username) => {
    axios
      .get(
        `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${username}`,
        requestConfig
      )
      .then((res) => {
        if (!res.data || res.data.username === credentials.username) {
          setUsernameValid(true);
        } else {
          setUsernameValid(false);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const checkEmail = (email) => {
    axios
      .get(
        `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${email}`,
        requestConfig
      )
      .then((res) => {
        if (!res.data || res.data.email === credentials.email) {
          setEmailValid(true);
        } else {
          setEmailValid(false);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const editProfile = async () => {
    if (credentials.username !== username) {
      if (!window.confirm(translation.profile.username_confirm)) {
        return;
      }
    }

    if (credentials.email !== email) {
      if (!window.confirm(translation.profile.email_confirm)) {
        return;
      }
    }

    const id = Date.now();

    uploadFile(selectedFile, id);
    const requestBody = {
      username: username,
      name: name,
      email: email,
      bio: bio,
      image: !!selectedFile
        ? `https://sfh-avatars.s3.amazonaws.com/${id}.jpeg`
        : !!image
        ? image
        : null,
      hometown: hometown,
      publicaccount: publicaccount,
    };

    await axios
      .post(
        `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${credentials.id}`,
        requestBody,
        requestConfig
      )
      .then(async (res) => {
        updateCredentials(res.data.account);
        setEditTimestamp(Date.now());
      })
      .then(async () => {
        if (username !== oldusername) {
          await new Promise((resolve) => setTimeout(resolve, 500));
          navigate(`${username}`);
        }
        setEditViewportVisible(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  return (
    <Viewport
      isDesktop={isDesktop}
      heading={translation.profile.edit_profile}
      viewportVisible={editViewportVisible}
      setViewportVisible={setEditViewportVisible}
    >
      <div
        style={{
          margin: "auto",
          justifyContent: "center",
          textAlign: "center",
          maxHeight: isDesktop ? 550 : "100%",
          overflowY: "scroll",
        }}
      >
        <img
          src={
            image || "https://sfh-defaults.s3.amazonaws.com/defaultprofile.png"
          }
          alt={`${credentials.name}'s profile image.`}
          style={{
            marginTop: 20,
            width: 100,
            height: 100,
            borderRadius: 100,
            borderWidth: 1,
            borderColor: "#101010",
            borderStyle: "solid",
            objectFit: "cover",
          }}
        />
        <Button
          style={{ color: "rgba(100, 128, 77, 0.975)", margin: 10 }}
          onClick={() => {
            setUploadViewportVisible(true);
          }}
        >
          {translation.miscellaneous.change_image}
        </Button>
        <br />

        <div
          style={{
            display: "flex",
            margin: "auto",
            width: 350,
            paddingBottom: 10,
          }}
        >
          <div
            style={{
              textAlign: "left",
              justifyContent: "space-between",
              marginRight: 5,
            }}
          >
            <P style={{ color: "#000000", margin: 0, marginBottom: 10 }}>
              {translation.profile.username}:
            </P>
            <input
              className="textinput"
              style={{
                width: 210,
                padding: 10,
                borderStyle: "solid",
                borderRadius: 8,
                borderWidth: 0.5,
                borderColor: "#cccccc",
                backgroundColor: isDesktop ? "#eeeff0" : "#ffffff",
              }}
              type="text"
              value={username}
              placeholder={translation.profile.username}
              maxLength="24"
              onChange={(event) => {
                setUsername(event.target.value);
                checkUsername(event.target.value);
              }}
            />
            {!usernameValid ? (
              <div
                style={{
                  marginTop: 5,
                  color: "#eb5757",
                  fontSize: 12,
                  textAlign: "center",
                }}
              >
                {translation.profile.username_taken}
              </div>
            ) : null}
          </div>
          <div
            style={{
              textAlign: "left",
              justifyContent: "space-between",
              marginLeft: 15,
            }}
          >
            <P style={{ color: "#000000", margin: 0, marginBottom: 20 }}>
              {translation.profile.public_account}:
            </P>
            <input
              type="checkbox"
              checked={publicaccount}
              value={publicaccount}
              onChange={(event) => {
                setPublicaccount(!publicaccount);
              }}
            />
          </div>
        </div>

        <P
          style={{
            color: "#000000",
            textAlign: "left",
            margin: "auto",
            width: 350,
            padding: 5,
          }}
        >
          {translation.profile.name}:
        </P>
        <input
          className="textinput"
          style={{
            width: 330,
            padding: 10,
            marginBottom: 10,
            borderStyle: "solid",
            borderRadius: 8,
            borderWidth: 0.5,
            borderColor: "#cccccc",
            backgroundColor: isDesktop ? "#eeeff0" : "#ffffff",
          }}
          type="text"
          value={name}
          placeholder={translation.profile.name}
          maxLength="100"
          onChange={(event) => setName(event.target.value)}
        />

        <P
          style={{
            color: "#000000",
            textAlign: "left",
            margin: "auto",
            width: 350,
            padding: 5,
          }}
        >
          {translation.profile.email}:
        </P>

        <input
          className="textinput"
          style={{
            width: 330,
            padding: 10,
            marginBottom: 10,
            borderStyle: "solid",
            borderRadius: 8,
            borderWidth: 0.5,
            borderColor: "#cccccc",
            backgroundColor: isDesktop ? "#eeeff0" : "#ffffff",
          }}
          type="text"
          value={email}
          placeholder={translation.profile.email}
          maxLength="100"
          onChange={(event) => {
            setEmail(event.target.value);
            checkEmail(event.target.value);
          }}
        />
        {!emailValid ? (
          <div
            style={{
              color: "#eb5757",
              fontSize: 12,
            }}
          >
            {translation.profile.email_taken}
          </div>
        ) : null}

        <P
          style={{
            color: "#000000",
            textAlign: "left",
            margin: "auto",
            width: 350,
            padding: 5,
          }}
        >
          {translation.profile.bio}:
        </P>
        <textarea
          style={{
            flex: 1,
            padding: 10,
            borderRadius: 8,
            backgroundColor: isDesktop ? "#eeeff0" : "#ffffff",
            borderStyle: "solid",
            borderWidth: 0.5,
            borderColor: "#ccc",
            width: 330,
            height: 100,
            resize: "none",
          }}
          value={bio}
          placeholder={translation.profile.bio}
          onChange={(event) => {
            setBio(event.target.value.substring(0, 300));
          }}
        />

        <P
          style={{
            textAlign: "left",
            width: 350,
            margin: "auto",
            paddingTop: 10,
          }}
        >
          {translation.profile.hometown}:
        </P>
        <div
          style={{
            display: "flex",
            margin: "auto",
            width: 350,
            alignContent: "center",
            justifyContent: "space-between",
            paddingTop: 5,
          }}
        >
          <P
            style={{
              fontSize: 15,
              marginTop: 5,
              color: !!hometown.name ? "#000000" : "#888888",
            }}
          >
            {hometown.name || translation.profile.set_hometown}
          </P>
          <Button
            type="solid"
            style={{
              fontSize: 14,
              paddingTop: 5,
              paddingRight: 10,
              paddingBottom: 5,
              paddingLeft: 10,
            }}
            onClick={() => {
              setMapViewportVisible(true);
            }}
          >
            {translation.button.change}
          </Button>
        </div>
        <br />

        <Button
          type="solid"
          style={{ textAlign: "center", margin: "auto", width: 315 }}
          onClick={() => {
            editProfile();
          }}
        >
          {translation.button.save}
        </Button>
        {!isDesktop ? <div style={{ height: 80 }} /> : null}
      </div>
      {!!uploadViewportVisible && (
        <UploadViewport
          isDesktop={isDesktop}
          credentials={credentials}
          setCredentials={setCredentials}
          updateCredentials={updateCredentials}
          language={language}
          translation={translation}
          activePage={activePage}
          setActivePage={setActivePage}
          setSettingsViewportVisible={setSettingsViewportVisible}
          uploadViewportVisible={uploadViewportVisible}
          setUploadViewportVisible={setUploadViewportVisible}
          image={image}
          setImage={setImage}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
        />
      )}
      {!!mapViewportVisible && (
        <MapViewport
          isDesktop={isDesktop}
          credentials={credentials}
          setCredentials={setCredentials}
          updateCredentials={updateCredentials}
          language={language}
          translation={translation}
          activePage={activePage}
          setActivePage={setActivePage}
          setSettingsViewportVisible={setSettingsViewportVisible}
          mapViewportVisible={mapViewportVisible}
          setMapViewportVisible={setMapViewportVisible}
          city={hometown}
          setCity={setHometown}
        />
      )}
    </Viewport>
  );
};

export default EditViewport;

"use es6";

import { useState, useEffect, useReducer } from "react";
import axios from "axios";
import { useLocation, useSearchParams } from "react-router-dom";

import { countryCodeEmoji } from "country-code-emoji";

import P from "../components/Text/P";
import H1 from "../components/Text/H1";
import H3 from "../components/Text/H3";
import H2 from "../components/Text/H2";

import verified from "../graphics/icons/verified.svg";
import Button from "../components/Buttons/Button";
import HR from "../components/Text/HR";
import Loading from "../components/Loading/Loading";
import UnknownPage from "./UnknownPage";
import Company from "../components/Company/Company";
import H0 from "../components/Text/H0";

const ProfilePage = ({
  isDesktop = null,
  credentials = null,
  setCredentials = {},
  updateCredentials = {},
  dimensions = {},
  translation = null,
  setEditViewportVisible = {},
  setJoinViewportVisible = {},
  setPasswordViewportVisible = {},
  setAboutViewportVisible = {},
  setNoticeViewportVisible = {},
  setBadgeViewportVisible = {},
  setBadgesViewportVisible = {},
  setBookmarksViewportVisible = {},
  setCompanyViewportVisible = {},
  editTimestamp = null,
  setActivePage = {},
  currentBadgess = {},
  setCurrentBadgess = {},
  currentBookmarks = {},
  setCurrentBookmarks = {},
  currentReservations = {},
  setCurrentReservations = {},
}) => {
  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  const location = useLocation();
  const username = useLocation().pathname.split("/")[1];
  const [profile, setProfile] = useState(null);
  const isOwner = !!profile && profile.id === credentials.id;
  const [userNotFound, setUserNotFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadedBookmarks, setLoadedBookmarks] = useState({});
  const [removedBookmarks, setRemovedBookmarks] = useState(0);
  const [loadedReservations, setLoadedReservations] = useState(0);
  const [removedReservations, setRemovedReservations] = useState(0);

  const [renderedBookmarks, setRenderedBookmarks] = useState(0);
  const numLoadedBookmarks = Object.keys(loadedBookmarks).filter((key) => {
    return loadedBookmarks[key] === true;
  }).length;

  const getCredentials = async () => {
    axios
      .get(
        `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${credentials.id}`,
        requestConfig
      )
      .then((res) => {
        if (res.data.id === credentials.id) {
          updateCredentials(res.data);
        }
      })
      .catch((error) => {});
  };

  const getProfile = async () => {
    setLoadedReservations(0);
    setLoadedBookmarks(0);
    setCurrentReservations({});
    setCurrentBookmarks({});
    setProfile(null);
    axios
      .get(
        `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${username}`,
        requestConfig
      )
      .then((res) => {
        if (!!res.data) {
          if (res.data.id === credentials.id) {
            updateCredentials(res.data);
          }
        } else {
          setUserNotFound(true);
        }
        setProfile(res.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getProfile();
  }, [editTimestamp, username]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getCredentials();
    setLoading(false);
  }, [location]);

  useEffect(() => {
    if (!!credentials && !!credentials.bookmarks && !!profile) {
      setCurrentBookmarks(profile.bookmarks);
    }
    if (!!credentials && !!credentials.reservations && !!profile) {
      setCurrentReservations(profile.reservations);
    }
  }, [!!credentials, !!profile]);

  useEffect(() => {
    setLoading(false);
  }, [loading]);

  return (
    <div
      style={{
        flex: 1,
        paddingBottom: 10,
        width: isDesktop && dimensions.width - 290,
      }}
    >
      {!!translation && !!credentials && !!profile ? (
        <div
          style={{
            marginTop: 15,
            marginRight: 10,
            marginLeft: 10,
          }}
        >
          <div style={{ display: "flex", width: "100%", marginBottom: 0 }}>
            <div
              style={{
                display: "flex",
                width: isDesktop ? 450 : "100%",
                marginBottom: 10,
                marginTop: isDesktop ? 0 : 20,
                marginRight: isDesktop ? 10 : 0,
                padding: 15,
                backgroundColor: "rgba(255, 255, 255, 0.33)",
                borderRadius: 8,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "#101010",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
              }}
            >
              <img
                style={{
                  width: 84,
                  height: 84,
                  borderRadius: 84,
                  aspectRatio: 1,
                  marginRight: 20,
                  objectFit: "cover",
                  borderWidth: 2,
                  borderColor: "#101010",
                  borderStyle: "solid",
                }}
                src={
                  profile.image ||
                  "https://sfh-defaults.s3.amazonaws.com/defaultprofile.png"
                }
              />
              <div style={{ width: "100%" }}>
                <div style={{ display: "flex" }}>
                  <H2 style={{ marginBottom: 10 }}>@{profile.username}</H2>
                  {profile.admin === "valid" ? (
                    <img
                      src={verified}
                      style={{ marginLeft: 4, marginBottom: 8 }}
                    />
                  ) : null}
                </div>
                {!!profile.bio ? (
                  <P style={{ marginBottom: 15 }}>{profile.bio}</P>
                ) : null}
                {!!profile.hometown &&
                !!profile.hometown.name &&
                profile.hometown.name.length > 0 ? (
                  <div style={{ marginRight: 20, marginBottom: 10 }}>
                    <P style={{ color: "#555555" }}>
                      {translation.profile.hometown}:
                    </P>

                    <div
                      style={{
                        display: "inline-block",
                        marginTop: -2,
                      }}
                    >
                      <span
                        style={{
                          fontSize: 14,
                          color: "#000000",
                        }}
                      >
                        {profile.hometown.name.substring(
                          0,
                          profile.hometown.name.length - 5
                        )}
                      </span>
                      <span
                        style={{
                          position: "relative",
                          fontSize: 18,
                          top: 2,
                          marginLeft: 5,
                          color: "#555555",
                        }}
                      >
                        {countryCodeEmoji(profile.hometown.name.slice(-2))}
                      </span>
                    </div>
                  </div>
                ) : null}

                {isOwner ? (
                  <Button
                    style={{
                      color: "rgba(100, 128, 77, 0.975)",
                    }}
                    onClick={() => {
                      setEditViewportVisible(true);
                    }}
                  >
                    <P style={{ fontWeight: 600 }}>
                      {translation.profile.edit_profile}
                    </P>
                  </Button>
                ) : null}
              </div>
            </div>

            {isDesktop && isOwner ? (
              <div
                style={{
                  margin: "auto",
                  position: "relative",
                  marginTop: 0,
                  marginBottom: 10,
                  flex: 1,
                  backgroundColor: "rgba(16, 16, 16, 0.975)",
                  borderRadius: 8,
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#101010",
                  boxShadow:
                    "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
                }}
              >
                <Button
                  style={{ padding: 20 }}
                  onClick={() => {
                    setAboutViewportVisible(true);
                  }}
                >
                  <H3 style={{ color: "#ffffff", fontWeight: 500 }}>
                    {translation.navigation.about_us}
                  </H3>
                </Button>
                <HR style={{ opacity: 0.5 }} />

                <Button
                  style={{ padding: 20 }}
                  onClick={() => {
                    setJoinViewportVisible(true);
                  }}
                >
                  <H3 style={{ color: "#ffffff", fontWeight: 500 }}>
                    {translation.navigation.join_the_team}
                  </H3>
                </Button>
                <HR style={{ opacity: 0.5 }} />

                <Button
                  style={{ padding: 20 }}
                  onClick={() => {
                    setPasswordViewportVisible(true);
                  }}
                >
                  <H3 style={{ color: "#ffffff", fontWeight: 500 }}>
                    {translation.authentication.change_password}
                  </H3>
                </Button>
                <HR style={{ opacity: 0.5 }} />

                <Button
                  style={{ padding: 20 }}
                  onClick={() => {
                    setNoticeViewportVisible("deleteaccount");
                  }}
                >
                  <H3 style={{ fontWeight: 500, color: "#eb5757" }}>
                    {translation.authentication.delete_account}
                  </H3>
                </Button>
              </div>
            ) : null}
          </div>

          {isOwner ? (
            <div
              style={{
                flex: 1,
                backgroundColor: "rgba(16, 16, 16, 0.975)",
                padding: 10,
                borderRadius: 8,
                marginBottom: 10,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "#101010",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginRight: 10,
                  marginTop: 10,
                }}
              >
                <H3
                  style={{
                    color: "#ffffff",
                    margin: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  }}
                >
                  {translation.profile.reservations}
                </H3>
              </div>
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  minHeight: 80,
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  opacity:
                    loadedReservations !==
                      Object.keys(currentReservations).length &&
                    Object.keys(currentReservations).length !== 0 &&
                    loadedReservations <= 2 &&
                    0,
                }}
              >
                {Object.keys(currentReservations).length > 0 ? (
                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    {Object.entries(currentReservations).map(
                      (company, index) => (
                        <Company
                          type="reserve"
                          key={index}
                          credentials={credentials}
                          updateCredentials={updateCredentials}
                          id={company[0]}
                          translation={translation}
                          setActivePage={setActivePage}
                          setCurrentReservations={setCurrentReservations}
                          setLoadedReservations={setLoadedReservations}
                          setRemovedReservations={setRemovedReservations}
                          setCompanyViewportVisible={setCompanyViewportVisible}
                        />
                      )
                    )}
                  </div>
                ) : (
                  <P
                    style={{
                      margin: "auto",
                      marginTop: 10,
                      padding: 10,
                      color: "#888888",
                    }}
                  >
                    {translation.miscellaneous.nothing_here}
                  </P>
                )}
              </div>
              {loadedReservations !== Object.keys(currentReservations).length &&
              Object.keys(currentReservations).length !== 0 &&
              loadedReservations <= 2 ? (
                <Loading
                  style={{
                    position: "relative",
                    marginTop: -40,
                    paddingBottom: 20,
                    marginLeft: 40,
                    transform: "translate(-50%, -50%)",
                  }}
                />
              ) : null}
            </div>
          ) : null}

          {isOwner || profile.publicaccount ? (
            <div
              style={{
                flex: 1,
                backgroundColor: "rgba(16, 16, 16, 0.975)",
                padding: 10,
                borderRadius: 8,
                marginBottom: 20,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "#101010",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginRight: 10,
                  marginTop: 10,
                }}
              >
                <H3
                  style={{
                    color: "#ffffff",
                    margin: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  }}
                >
                  {translation.profile.bookmarks}
                </H3>
              </div>
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  minHeight: 80,
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  opacity:
                    loadedBookmarks !== Object.keys(currentBookmarks).length &&
                    loadedBookmarks <= 10 &&
                    0,
                }}
              >
                {Object.keys(currentBookmarks).length > 0 ? (
                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    {Object.entries(currentBookmarks)
                      .sort((a, b) => {
                        if (a[1] < b[1]) {
                          return 1;
                        }
                        if (a[1] > b[1]) {
                          return -1;
                        }
                      })
                      .map((company, index) => (
                        <Company
                          key={index}
                          type="bookmark"
                          credentials={credentials}
                          updateCredentials={updateCredentials}
                          id={company[0]}
                          translation={translation}
                          setActivePage={setActivePage}
                          setCurrentBookmarks={setCurrentBookmarks}
                          loadedBookmarks={loadedBookmarks}
                          setLoadedBookmarks={setLoadedBookmarks}
                          setRemovedBookmarks={setRemovedBookmarks}
                          bookmarkId={company[1]}
                          setLoading={setLoading}
                        />
                      ))}
                  </div>
                ) : (
                  <P
                    style={{
                      margin: "auto",
                      marginTop: 10,
                      padding: 10,
                      color: "#888888",
                    }}
                  >
                    {translation.miscellaneous.nothing_here}
                  </P>
                )}
              </div>
              {(loadedBookmarks !== Object.keys(currentBookmarks).length &&
                loadedBookmarks <= 10) ||
              loading ? (
                <Loading
                  style={{
                    position: "relative",
                    marginTop: -40,
                    paddingBottom: 20,
                    marginLeft: 40,
                    transform: "translate(-50%, -50%)",
                  }}
                />
              ) : null}
            </div>
          ) : null}
          <br />
          <br />
        </div>
      ) : (
        <div>
          {!userNotFound ? (
            <div
              style={{
                position: "fixed",
                zIndex: 60,
                top: 0,
                right: 0,
                bottom: 0,
                left: isDesktop ? 200 : 0,
              }}
            >
              <Loading
                style={{
                  margin: "auto",
                }}
              />
            </div>
          ) : (
            <UnknownPage />
          )}
        </div>
      )}
    </div>
  );
};

export default ProfilePage;

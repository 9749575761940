"use es6";

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { useWindowDimensions } from "../../utils/CustomHooks";

import Button from "../Buttons/Button";
import H3 from "../Text/H3";
import P from "../Text/P";
import Bold from "../Text/Bold";

import sfh_wordmark from "../../graphics/branding/sfh_wordmark.svg";
import bookmark from "../../graphics/icons/bookmark.svg";
import bookmarked from "../../graphics/icons/bookmarked.svg";
import reserve from "../../graphics/icons/reserve.svg";
import reserved from "../../graphics/icons/reserved.svg";

const Company = ({
  credentials = {},
  updateCredentials = {},
  type = "bookmark",
  id = null,
  translation = {},
  setActivePage = {},
  setCurrentBookmarks = {},
  loadedBookmarks = {},
  setLoadedBookmarks = {},
  setRemovedBookmarks = {},
  setCurrentReservations = {},
  setLoadedReservations = {},
  setRemovedReservations = {},
  setCompanyViewportVisible = {},
  bookmarkId = null,
  setLoading = {},
}) => {
  const navigate = useNavigate();
  const dimensions = useWindowDimensions();

  const [company, setCompany] = useState(null);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isReserved, setIsReserved] = useState(false);

  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  const getCompany = async () => {
    axios
      .get(
        `${process.env.REACT_APP_COMPANYS_API_URL}/companys/${id}`,
        requestConfig
      )
      .then((res) => {
        if (res.status === 200) {
          setCompany(res.data);
          if (type === "bookmark") {
            setLoadedBookmarks((prevLoadedBookmarks) => ({
              ...prevLoadedBookmarks,
              ...{ [bookmarkId]: true },
            }));
            setIsBookmarked(
              Object.keys(res.data.bookmarks).indexOf(res.data.id) !== -1
            );
          } else {
            setLoadedReservations(
              (prevLoadedReservations) => prevLoadedReservations + 1
            );
            setIsReserved(
              Object.keys(res.data.reservations).indexOf(res.data.id) !== -1
            );
          }
        } else {
          getCompany();
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const bookmarkCompany = async (active, id) => {
    const bookmarksentry = { [id]: Date.now() };
    console.log("JHSK");
    let bookmarks = credentials.bookmarks;
    if (active) {
      bookmarks = { ...credentials.bookmarks, ...bookmarksentry };
      if (Object.keys(bookmarks).length >= 11) {
        return alert(translation.company.boomark_limit_reached);
      }
      setIsBookmarked(active);
    } else {
      if (window.confirm(translation.company.confirm_remove_bookmark)) {
        setIsBookmarked(active);
        delete bookmarks[id];
        setRemovedBookmarks((prevRemovedBookmarks) => prevRemovedBookmarks + 1);
      }
    }
    setCurrentBookmarks(bookmarks);
    console.log("ff");
    await axios
      .post(
        `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${credentials.id}`,
        { bookmarks: bookmarks },
        requestConfig
      )
      .then(async (res) => {
        updateCredentials(res.data.account);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const reserveCompany = async (active, id) => {
    const reservationsentry = { [id]: Date.now() };
    let reservations = credentials.reservations;
    if (active) {
      reservations = { ...credentials.reservations, ...reservationsentry };
      if (Object.keys(reservations).length >= 11) {
        return alert(translation.tasks.reservations_limit_reached);
      }
      setIsReserved(active);
    } else {
      if (window.confirm(translation.tasks.confirm_remove_reservation)) {
        setIsReserved(active);
        delete reservations[id];
        setRemovedReservations(
          (prevRemovedReservations) => prevRemovedReservations + 1
        );
      }
    }
    setCurrentReservations(reservations);
    await axios
      .post(
        `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${credentials.id}`,
        { reservations: reservations },
        requestConfig
      )
      .then(async (res) => {
        updateCredentials(res.data.account);
        await axios
          .post(
            `${process.env.REACT_APP_COMPANYS_API_URL}/companys/${id}`,
            { reserved: active ? String(credentials.id) : "none" },
            requestConfig
          )
          .catch((error) => {
            // console.log(error);
          });
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  useEffect(() => {
    if (!!!loadedBookmarks[bookmarkId]) {
      getCompany();
    }
  }, []);

  useEffect(() => {
    if (!!credentials && !!credentials.bookmarks && !!company) {
      setIsBookmarked(!!credentials.bookmarks[company.id]);
    }
    if (!!credentials && !!credentials.reservations && !!company) {
      setIsReserved(!!credentials.reservations?.[company.id]);
    }
  }, [!!credentials, company]);

  useEffect(() => {
    if (type === "bookmark") {
      setLoading(true);
      getCompany();
    }
  }, [bookmarkId]);

  return !!company ? (
    <div
      style={{
        margin: 10,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: "#101010",
        borderStyle: "solid",
      }}
      onMouseOver={({ currentTarget }) => {
        currentTarget.style.opacity = "50%";
      }}
      onMouseOut={({ currentTarget }) => {
        currentTarget.style.opacity = "100%";
      }}
      onClick={() => {
        if (company.reserved === "reviewing") {
          alert("This company currently under review.");
        }
      }}
    >
      <div
        style={{
          cursor: "pointer",
          textAlign: "right",
          backgroundColor: "#ffffff",
          borderRadius: 0,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          height: 20,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          onClick={() => {
            if (type === "bookmark") {
              if (!!credentials) {
                bookmarkCompany(!isBookmarked, company.id);
              } else {
                alert(
                  "To bookmark this company page you need to be signed in first!"
                );
              }
            } else {
              if (!!credentials) {
                reserveCompany(!isReserved, company.id);
              } else {
                alert(
                  "To reserve this company for updates/maintenance you need to be signed in first!"
                );
              }
            }
          }}
        >
          {type === "bookmark" ? (
            <img
              src={!!credentials && isBookmarked ? bookmarked : bookmark}
              style={{
                margin: 5,
                width: 20,
                height: 20,
              }}
            />
          ) : company.reserved !== "reviewing" ? (
            <img
              src={!!credentials && isReserved ? reserved : reserve}
              style={{
                margin: 5,
                width: 20,
                height: 20,
              }}
            />
          ) : (
            <div style={{ height: 34 }} />
          )}
        </Button>
        {!isNaN(credentials.reservations?.[company.id]) ? (
          <div
            style={{
              position: "relative",
              padding: 10,
              backgroundColor: "rgba(0,0,0,.85)",
            }}
          >
            <Button
              type="solid"
              style={{
                backgroundColor: "#64804d",
                fontSize: 14,
                paddingTop: 5,
                paddingRight: 10,
                paddingBottom: 5,
                paddingLeft: 10,
                marginBottom: 10,
              }}
              onClick={() => {
                setCompanyViewportVisible({
                  type:
                    company.classification === ""
                      ? "identify"
                      : company.classification === "identified"
                      ? "tabulate"
                      : company.classification === "tabulated"
                      ? "confirm"
                      : "edit",
                  company: company,
                });
              }}
            >
              {translation.button.manage}
            </Button>

            <Button
              type="solid"
              style={{
                fontSize: 14,
                paddingTop: 5,
                paddingRight: 10,
                paddingBottom: 5,
                paddingLeft: 10,
                backgroundColor: "#eb5757",
              }}
              onClick={() => {
                reserveCompany(false, company.id);
              }}
            >
              {translation.button.remove}
            </Button>
          </div>
        ) : credentials.reservations?.[company.id] === "reviewing" ? (
          <div
            style={{
              position: "relative",
              padding: 10,
              backgroundColor: "rgba(0,0,0,.85)",
              height: 64,
            }}
          >
            <H3
              style={{
                marginTop: 20,
                color: "#ffffff",
                textAlign: "center",
                fontStyle: "italic",
              }}
            >
              {translation.company.under_review}
            </H3>
          </div>
        ) : null}
      </div>
      <div
        style={{
          cursor: "pointer",
          display: "flex",
          backgroundColor: "#ffffff",
          borderRadius: 0,
          paddingBottom: 20,
          height: 100,
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => {
          setActivePage("explore");
          navigate(`/explore/${company.slug}`);
        }}
      >
        <img
          style={{
            margin: "auto",
            maxWidth: 130,
            maxHeight: 80,
            padding: 10,
            objectFit: "cover",
          }}
          src={company.image || sfh_wordmark}
        />
      </div>
      <div
        style={{
          cursor: "pointer",
          borderRadius: 0,
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
          backgroundColor: "#64804d",
          width: 150,
          height: 75,
          textAlign: "left",
          borderTopColor: "101010",
          borderTopWidth: 1,
          borderTopStyle: "solid",
        }}
        onClick={() => {
          navigate(`/explore/${company.slug}`);
        }}
      >
        <div style={{ padding: 10, color: "#ffffff" }}>
          <Bold style={{ marginBottom: 10, fontSize: 15 }}>
            {company.name.length > 30
              ? `${company.name.substring(0, 27)}...`
              : company.name}
          </Bold>
          <P
            style={{
              opacity: "50%",
              fontStyle: "italic",
              fontSize: 11,
              marginTop: -5,
            }}
          >
            {company.ticker}
          </P>
        </div>
      </div>
    </div>
  ) : null;
};

export default Company;

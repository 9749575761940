"use es6";

import { useState, useRef, useEffect } from "react";
import question from "../../graphics/icons/question.svg";

const Hint = ({
  style = {},
  size = 24,
  iconColor = "black",
  textboxColor = "black",
  children = "[insert text]",
  offset = 1,
  width,
  tableHint = false,
  ...buttonProps
}) => {
  const [textVisible, setTextVisible] = useState(false);

  const hintRef = useRef(null);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleOutsideClicks = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setTextVisible(false);
        }
      };
      document.addEventListener("mousedown", handleOutsideClicks);
      return () => {
        document.removeEventListener("mousedown", handleOutsideClicks);
      };
    }, [ref]);
  };

  useOutsideAlerter(hintRef);

  return (
    <div {...buttonProps} style={{ position: "relative", ...style }}>
      {textVisible ? (
        <div
          ref={hintRef}
          style={{
            padding: 10,
            backgroundColor:
              textboxColor === "black"
                ? "rgba(0, 0, 0, 0.90)"
                : "rgba(255,255,255, 0.975)",
            borderRadius: 4,
            position: "absolute",
            top: tableHint ? 26 : 36,
            left: -11.5 * offset,
            fontSize: 13.5,
            maxWidth: 350,
            width: !!width ? width : children.toString().length * 6,
            textAlign: "left",
            color: textboxColor === "black" ? "#ffffff" : "#000000",
          }}
        >
          {children}
        </div>
      ) : null}
      <img
        style={{
          margin: "auto",
          width: size,
          height: size,
          filter: `brightness(${
            iconColor === "black" ? "0" : iconColor === "white" ? "1000" : "100"
          })`,
        }}
        src={question}
        onClick={() => {
          setTextVisible(true);
        }}
        onMouseOver={({ currentTarget }) => {
          currentTarget.style.opacity = "50%";
        }}
        onMouseOut={({ currentTarget }) => {
          currentTarget.style.opacity = "100%";
        }}
      />
      {textVisible ? (
        <div
          style={{
            width: 0,
            height: 0,
            borderLeft: "6px solid transparent",
            borderRight: "6px solid transparent",
            borderBottom:
              textboxColor === "black"
                ? "8px solid rgba(0, 0, 0, 0.90)"
                : "8px solid rgba(255,255,255, 0.975)",
            margin: "auto",
            marginBottom: -8,
          }}
        ></div>
      ) : null}
    </div>
  );
};

export default Hint;

"use es6";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";

import { gapi } from "gapi-script";
import jwt_decode from "jwt-decode";
import Button from "../Buttons/Button";
import H1 from "../Text/H1";

import chevron from "../../graphics/icons/chevron.svg";
import H3 from "../Text/H3";
import H2 from "../Text/H2";
import P from "../Text/P";
import HR from "../Text/HR";
import Viewport from "./Viewport";
import Loading from "../Loading/Loading";

const PasswordViewport = ({
  isDesktop = null,
  children = "[insert text]",
  credentials = null,
  setCredentials = {},
  updateCredentials = {},
  language = null,
  translation = null,
  passwordViewportVisible = false,
  setPasswordViewportVisible = {},
}) => {
  const navigate = useNavigate();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordAgain, setNewPasswordAgain] = useState("");

  const [isChanging, setIsChanging] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (
      (credentials.password !== null && currentPassword.trim() === "") ||
      newPassword.trim() === "" ||
      newPasswordAgain.trim() === ""
    ) {
      setErrorMessage("All fields are required.");
      return;
    }
    if (newPassword.trim() !== newPasswordAgain.trim()) {
      setErrorMessage("Your new password fields must match.");
      return;
    }
    setErrorMessage(null);
    const requestConfig = {
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    const requestBody = {
      oldpassword: currentPassword,
      password: newPassword,
    };

    setIsChanging(true);

    axios
      .post(
        `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${credentials.id}`,
        requestBody,
        requestConfig
      )
      .then((res) => {
        updateCredentials(res.data.account);
        setPasswordViewportVisible(false);
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
      });
  };

  return (
    <Viewport
      isDesktop={isDesktop}
      heading={translation.authentication.change_password}
      viewportVisible={passwordViewportVisible}
      setViewportVisible={setPasswordViewportVisible}
    >
      <div style={{ height: 10 }} />
      <form
        onSubmit={submitHandler}
        style={{
          textAlign: "center",
          justifyContent: "center",
          margin: "auto",
          maxHeight: 550,
          overflowY: "scroll",
        }}
      >
        {!!credentials.password ? (
          <div>
            <input
              className="textinput"
              style={{
                width: 330,
                margin: 10,
                padding: 10,
                borderStyle: "solid",
                borderRadius: 8,
                borderWidth: 0.5,
                borderColor: "#cccccc",
                backgroundColor: isDesktop ? "#eeeff0" : "#ffffff",
              }}
              type="password"
              value={currentPassword}
              placeholder={translation.authentication.current_password}
              maxLength="24"
              onChange={(event) => setCurrentPassword(event.target.value)}
            />
            <br />
          </div>
        ) : null}
        <input
          className="textinput"
          style={{
            width: 330,
            margin: 10,
            padding: 10,
            borderStyle: "solid",
            borderRadius: 8,
            borderWidth: 0.5,
            borderColor: "#cccccc",
            backgroundColor: isDesktop ? "#eeeff0" : "#ffffff",
          }}
          type="password"
          value={newPassword}
          placeholder={translation.authentication.new_password}
          maxLength="24"
          onChange={(event) => setNewPassword(event.target.value)}
        />
        <br />
        <input
          className="textinput"
          style={{
            width: 330,
            margin: 10,
            padding: 10,
            borderStyle: "solid",
            borderRadius: 8,
            borderWidth: 0.5,
            borderColor: "#cccccc",
            backgroundColor: isDesktop ? "#eeeff0" : "#ffffff",
          }}
          type="password"
          value={newPasswordAgain}
          placeholder={translation.authentication.new_password_again}
          maxLength="24"
          onChange={(event) => setNewPasswordAgain(event.target.value)}
        />
        <div
          style={{
            display: "flex",
            margin: "auto",
            width: 355,
          }}
        >
          <input
            className="textinput"
            type="submit"
            value={translation.authentication.change_password}
            style={{
              cursor: "pointer",
              marginTop: 8,
              width: "100%",
              padding: 8,
              fontWeight: 600,
              fontSize: 14,
              borderRadius: 8,
              backgroundColor: "#000000",
              borderColor: "#000000",
              borderStyle: "solid",
              color: "#ffffff",
              fontFamily: "serif",
            }}
          />
        </div>

        {errorMessage && !isChanging ? (
          <P
            style={{
              color: "#eb5757",
              backgroundColor: "rgba(255,255,255,.75)",
              fontWeight: 600,
              padding: 4,
              marginTop: 20,
              borderRadius: 8,
              width: 350,
              textAlign: "center",
              margin: "auto",
            }}
          >
            {errorMessage}
          </P>
        ) : null}
        {isChanging && !errorMessage ? (
          <Loading
            style={{
              width: 20,
              height: 20,
              zIndex: 20,
              marginTop: 20,
              marginBottom: 10,
            }}
          />
        ) : null}
      </form>
    </Viewport>
  );
};

export default PasswordViewport;

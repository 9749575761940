"use es6";

import { useNavigate } from "react-router-dom";

import P from "../Text/P";

import profile from "../../graphics/icons/profile.svg";
import home from "../../graphics/icons/home.svg";
import tasks from "../../graphics/icons/tasks.svg";
import signin from "../../graphics/icons/signin.svg";
import explore from "../../graphics/icons/explore.svg";

const Footer = ({
  credentials = null,
  isDesktop = false,
  translation = null,
  activePage = null,
  setActivePage = {},
  setAuthenticationViewportVisible = {},
  setNotificationsViewportVisible = {},
  ...buttonProps
}) => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: isDesktop
          ? "rgba(16, 16, 16, 0.975)"
          : "rgba(100, 128, 77, 0.975)",
        position: "fixed",
        bottom: 0,
        borderTopWidth: 1,
        borderTopStyle: "solid",
        borderTopColor: "#101010",
        alignItems: "center",
        padding: 5,
        display: "flex",
        zIndex: 5,
      }}
    >
      <div
        unselectable="on"
        style={{
          cursor: "pointer",
          margin: "auto",
          textAlign: "center",
          justifyContent: "center",
          opacity: activePage === "" ? 1 : 0.5,
        }}
        onClick={() => {
          navigate("/");
          setActivePage("");
        }}
      >
        <img
          src={home}
          style={{
            width: 20,
            height: 20,
          }}
        />

        <P style={{ color: "#ffffff", fontSize: 14 }}>
          {translation.navigation.home}
        </P>
      </div>

      <div
        unselectable="on"
        style={{
          cursor: "pointer",
          margin: "auto",
          textAlign: "center",
          justifyContent: "center",
          opacity: activePage === "explore" ? 1 : 0.5,
        }}
        onClick={() => {
          navigate("/explore");
          setActivePage("explore");
        }}
      >
        <img src={explore} style={{ width: 20, height: 20 }} />

        <P style={{ color: "#ffffff", fontSize: 14 }}>
          {translation.navigation.explore}
        </P>
      </div>

      {!!credentials ? (
        <div
          unselectable="on"
          style={{
            cursor: "pointer",
            margin: "auto",
            textAlign: "center",
            justifyContent: "center",
            opacity: activePage === "tasks" ? 1 : 0.5,
          }}
          onClick={() => {
            navigate(`/tasks`);
            setActivePage("tasks");
          }}
        >
          <img src={tasks} style={{ width: 20, height: 20 }} />

          <P style={{ color: "#ffffff", fontSize: 14 }}>
            {translation.navigation.tasks}
          </P>
        </div>
      ) : null}

      {!credentials && (
        <div
          unselectable="on"
          style={{
            cursor: "pointer",
            margin: "auto",
            textAlign: "center",
            justifyContent: "center",
            opacity: activePage === "signin" ? 1 : 0.5,
          }}
          onClick={() => {
            setAuthenticationViewportVisible(true);
          }}
        >
          <img src={signin} style={{ width: 20, height: 20 }} />

          <P style={{ color: "#ffffff", fontSize: 14 }}>
            {translation.authentication.sign_in}
          </P>
        </div>
      )}
      {!!credentials ? (
        <div
          unselectable="on"
          style={{
            cursor: "pointer",
            margin: "auto",
            textAlign: "center",
            justifyContent: "center",
            opacity: activePage === "profile" ? 1 : 0.5,
          }}
          onClick={() => {
            navigate(`/${credentials.username}`);
            setActivePage("profile");
          }}
        >
          <img src={profile} style={{ width: 20, height: 20 }} />

          <P style={{ color: "#ffffff", fontSize: 14 }}>
            {translation.navigation.profile}
          </P>
        </div>
      ) : null}
    </div>
  );
};

export default Footer;

"use es6";

import loading from "../../graphics/branding/loading.gif";

const Loading = ({ style = {}, ...buttonProps }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        textAlign: "center",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <img
        style={{
          margin: "auto",
          width: 48,
          height: 48,
          ...style,
        }}
        {...buttonProps}
        src={loading}
      />
    </div>
  );
};

export default Loading;

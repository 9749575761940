"use es6";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";

import Markdown from "react-markdown";
import H2 from "../components/Text/H2";
import P from "../components/Text/P";

import { useWindowDimensions } from "../utils/CustomHooks";
import H1 from "../components/Text/H1";
import Button from "../components/Buttons/Button";
import H3 from "../components/Text/H3";
import FinancesChart from "../components/Charts/FinancesChart";
import Loading from "../components/Loading/Loading";
import bookmark from "../graphics/icons/bookmark.svg";
import bookmarked from "../graphics/icons/bookmarked.svg";
import download from "../graphics/icons/download.svg";
import edit from "../graphics/icons/edit.svg";
import Account from "../components/Accounts/Account";
import DropdownButton from "../components/Buttons/DropdownButton";
import CompanyDataCell from "../components/Company/CompanyDataCell";
import CompanyNews from "../components/Company/CompanyNews";
import CompanyOverview from "../components/Company/CompanyOverview";

const CompanyPage = ({
  credentials = {},
  setCredentials = {},
  updateCredentials = {},
  isDesktop = {},
  translation,
  activePage = null,
  setActivePage = {},
  companyViewportVisible = null,
  setCompanyViewportVisible = {},
  visualizationViewportVisible = null,
  setVisualizationViewportVisible = {},
}) => {
  const apikey = process.env.REACT_APP_SIMPLY_WALLST_API_KEY;
  const location = useLocation();
  const navigate = useNavigate();

  const slug = location.pathname.substring(1).split("explore/")[1];

  const language = navigator.language;

  const [company, setCompany] = useState(null);
  const [financials, setFinancials] = useState(null);
  const [articles, setArticles] = useState(null);
  const [isBookmarked, setIsBookmarked] = useState(false);

  const [bloombergAnnualDataVisible, setBloombergAnnualDataVisible] =
    useState(true);
  const [
    bloombergSustainabilityDataVisible,
    setBloombergSustainabilityDataVisible,
  ] = useState(true);

  const [activeAnnualData, setActiveAnnualData] = useState({});
  const [activeSustainabilityData, setActiveSustainabilityData] = useState({});

  const dimensions = useWindowDimensions();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const requestConfig1 = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  const requestConfig2 = {
    headers: {
      Authorization: `Bearer ${apikey}`,
      "Content-Type": "application/json",
    },
  };

  const exchange =
    slug.split("_")[1] === "us"
      ? "NasdaqGS"
      : slug.split("_")[1] === "ca"
      ? "TSX"
      : slug.split("_")[1] === "gb"
      ? "LSE"
      : slug.split("_")[1] === "fr"
      ? "ENXTPA"
      : slug.split("_")[1] === "it"
      ? "BIT"
      : slug.split("_")[1] === "de"
      ? "XTRA"
      : slug.split("_")[1] === "pt"
      ? "ENXTLS"
      : slug.split("_")[1] === "nl"
      ? "ENXTAM"
      : slug.split("_")[1] === "es"
      ? "BME"
      : slug.split("_")[1] === "ch"
      ? "SWX"
      : slug.split("_")[1] === "se"
      ? "OM"
      : slug.split("_")[1] === "dk"
      ? "CPSE"
      : slug.split("_")[1] === "jp"
      ? "TSE"
      : slug.split("_")[1] === "cn"
      ? "SEHK"
      : slug.split("_")[1] === "in"
      ? "NSEI"
      : slug.split("_")[1] === "mx"
      ? "BMV"
      : slug.split("_")[1] === "br"
      ? "BOVESPA"
      : slug.split("_")[1] === "au"
      ? "ASX"
      : slug.split("_")[1] === "hk"
      ? "SEHK"
      : slug.split("_")[1] === "kr"
      ? "KOSE"
      : slug.split("_")[1] === "ng"
      ? "NGSE"
      : slug.split("_")[1] === "za"
      ? "JSE"
      : "NYSE";
  const symbol = slug.split("_")[0].toUpperCase();

  const currencycode =
    slug.split("_")[1] === "us"
      ? "USD"
      : slug.split("_")[1] === "ca"
      ? "CAD"
      : slug.split("_")[1] === "gb"
      ? "GBP"
      : slug.split("_")[1] === "fr"
      ? "EUR"
      : slug.split("_")[1] === "it"
      ? "EUR"
      : slug.split("_")[1] === "de"
      ? "EUR"
      : slug.split("_")[1] === "pt"
      ? "EUR"
      : slug.split("_")[1] === "nl"
      ? "EUR"
      : slug.split("_")[1] === "es"
      ? "EUR"
      : slug.split("_")[1] === "ch"
      ? "CHF"
      : slug.split("_")[1] === "se"
      ? "SEK"
      : slug.split("_")[1] === "dk"
      ? "DKK"
      : slug.split("_")[1] === "jp"
      ? "JPY"
      : slug.split("_")[1] === "cn"
      ? "CNY"
      : slug.split("_")[1] === "in"
      ? "INR"
      : slug.split("_")[1] === "mx"
      ? "MXN"
      : slug.split("_")[1] === "br"
      ? "BRL"
      : slug.split("_")[1] === "au"
      ? "AUD"
      : slug.split("_")[1] === "hk"
      ? "HKD"
      : slug.split("_")[1] === "kr"
      ? "KRW"
      : slug.split("_")[1] === "ng"
      ? "NGN"
      : slug.split("_")[1] === "za"
      ? "ZAR"
      : "USD";

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  const getCompany = async () => {
    axios
      .get(
        `${process.env.REACT_APP_COMPANYS_API_URL}/companys/${slug}`,
        requestConfig1
      )
      .then((res) => {
        setCompany(res.data);
        getArticles(res.data.name);
        setIsBookmarked(
          Object.keys(credentials.bookmarks).indexOf(res.data.id) !== -1
        );
      })
      .catch((error) => {});
  };

  const getFinancials = async () => {
    axios
      .post(
        "https://api.simplywall.st/graphql",
        {
          query:
            "query companyByExchangeAndTickerSymbol($exchange: String!,$symbol:String!) {\n  companyByExchangeAndTickerSymbol(exchange: $exchange,tickerSymbol:$symbol) {id,name,exchangeSymbol,tickerSymbol,marketCapUSD,closingPrices,primaryIndustry{name},owners{name,sharesHeld,percentOfSharesOutstanding}}\n}\n",
          variables: {
            exchange: exchange,
            symbol: symbol,
          },
        },
        requestConfig2
      )
      .then((res) => {
        setFinancials(res.data.data.companyByExchangeAndTickerSymbol);
      })
      .catch((error) => {
        if (slug.split("_")[1] === "us") {
          axios
            .post(
              "https://api.simplywall.st/graphql",
              {
                query:
                  "query companyByExchangeAndTickerSymbol($exchange: String!,$symbol:String!) {\n  companyByExchangeAndTickerSymbol(exchange: $exchange,tickerSymbol:$symbol) {id,name,exchangeSymbol,tickerSymbol,marketCapUSD,closingPrices,primaryIndustry{name},owners{name,sharesHeld,percentOfSharesOutstanding}}\n}\n",
                variables: {
                  exchange: "NYSE",
                  symbol: symbol,
                },
              },
              requestConfig2
            )
            .then((res) => {
              setFinancials(
                !!res.data.data
                  ? res.data.data.companyByExchangeAndTickerSymbol
                  : null
              );
            });
        }
      });
  };

  const getArticles = async (name) => {
    await axios
      .post(
        `${process.env.REACT_APP_NEWS_API_URL}/news`,
        { company: name },
        requestConfig1
      )
      .then((res) => {
        setArticles(res.data);
      })
      .catch((error) => {});
  };

  const bookmarkCompany = async (active, id) => {
    setIsBookmarked(active);
    const bookmarksentry = { [id]: Date.now() };
    let bookmarks = credentials.bookmarks;
    if (active) {
      bookmarks = { ...credentials.bookmarks, ...bookmarksentry };
    } else {
      delete bookmarks[id];
    }
    await axios
      .post(
        `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${credentials.id}`,
        { bookmarks: bookmarks },
        requestConfig1
      )
      .then(async (res) => {
        updateCredentials(res.data.account);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  useEffect(() => {
    getCompany();
    getFinancials();
  }, []);

  useEffect(() => {
    if (!!credentials && !!credentials.bookmarks && !!company) {
      setIsBookmarked(!!credentials.bookmarks[company.id]);
      setActiveSustainabilityData(company.bloombergreportdata);
      setActiveAnnualData(company.bloombergreportdata);
    }
  }, [!!credentials, company]);

  const rows1 = [
    "ghg_scope_1",
    "ghg_scope_2_location_based",
    "ghg_scope_3",
    "scope_3_purch_goods_srvcs",
    "scope_3_capital_goods",
    "scope_3_fuel_enrg_relatd_act",
    "scope_3_upstream_trans_dist",
    "scope_3_waste_genrtd_in_op",
    "scope_3_business_trvl_emissions",
    "scope_3_employee_commuting",
    "scope_3_upstream_leased_assets",
    "scope_3_dwnstrm_trans_dist",
    "scope_3_prcss_of_sold_prods",
    "scope_3_use_sold_products",
    "scope_3_eol_trtmnt_prods",
    "scope_3_dwnstrm_lease_assts",
    "scope_3_franchises",
    "scope_3_investments",
    "scope_3_emissions_other",
  ];

  const rows2 = [
    "enterprise_value",
    "is_comp_sales",
    "historical_market_cap",
    "is_avg_num_sh_for_eps",
    "px_last",
    "short_and_long_term_debt",
    "cash_and_marketable_securities",
    "bs_tot_asset",
  ];

  const downloadCSV = (jsonData, fileName = "data.csv") => {
    if (!jsonData || jsonData.length === 0) {
      console.error("No data to download");
      return;
    }

    console.log(jsonData);

    const years = Object.keys(jsonData);
    const headers = Object.keys(jsonData[years[0]]);
    const csvRows = [];

    // Add the headers row
    csvRows.push(["Year", ...headers].join(","));

    // Add rows for each year's data
    years.forEach((year) => {
      const row = [year];
      headers.forEach((header) => {
        const value =
          jsonData[year][header] !== null ? jsonData[year][header] : "";
        const escapedValue = `${value}`.replace(/"/g, '""');
        row.push(`"${escapedValue}"`);
      });
      csvRows.push(row.join(","));
    });

    // Combine rows into a single CSV string
    const csvContent = csvRows.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Create a temporary link and trigger download
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <div
      style={{
        margin: 20,
        marginTop: isDesktop ? 20 : 60,
        paddingBottom: isDesktop ? 20 : 60,
        width: isDesktop && dimensions.width - 290,
      }}
    >
      {!!company ? (
        <div>
          <div
            style={{
              display: isDesktop ? "flex" : "block",
              justifyContent: "space-between",
              backgroundColor: "rgba(16, 16, 16, 0.975)",
              color: "#ffffff",
            }}
          >
            <div
              style={{
                display: "flex",
                marginRight: 5,
              }}
            >
              <div
                style={{
                  display: "flex",
                  backgroundColor: "#ffffff",
                  borderRadius: 0,
                  borderRadius: 8,
                  paddingTop: 5,
                  paddingBottom: 5,
                  height: dimensions.width > 500 ? 80 : 60,
                  minWidth: dimensions.width > 500 ? 90 : 67.5,
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 10,
                }}
              >
                <img
                  style={{
                    margin: "auto",
                    maxWidth: dimensions.width > 500 ? 80 : 60,
                    maxHeight: dimensions.width > 500 ? 50 : 37.5,
                    padding: dimensions.width > 500 ? 5 : 3.75,
                    backgroundColor: "#ffffff",
                    objectFit: "cover",
                  }}
                  src={company.image}
                />
              </div>
              <div style={{ padding: 5 }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    onClick={() => {
                      if (!!company.website) {
                        openInNewTab(company.website);
                      } else {
                        alert(
                          "Sorry, but this company's website could not be found"
                        );
                      }
                    }}
                  >
                    <H1 style={{}}>{company.name}</H1>
                  </Button>
                  <div style={{ display: "flex" }}>
                    <Button
                      onClick={() => {
                        downloadCSV(company.bloombergreportdata, company.name);
                      }}
                    >
                      <img
                        src={download}
                        style={{
                          margin: 5,
                          width: 24,
                          height: 24,
                          filter: "brightness(1000)",
                        }}
                      />
                    </Button>
                    <Button
                      onClick={() => {
                        if (!!credentials) {
                          bookmarkCompany(!isBookmarked, company.id);
                        } else {
                          alert(
                            "To bookmark this company page you need to be signed in first!"
                          );
                        }
                      }}
                    >
                      <img
                        src={
                          !!credentials && isBookmarked ? bookmarked : bookmark
                        }
                        style={{
                          margin: 5,
                          width: 24,
                          height: 24,
                          filter: "brightness(1000)",
                        }}
                      />
                    </Button>
                    <div
                      style={{
                        backgroundColor:
                          company.classification === ""
                            ? "#ed6826"
                            : company.classification === "identified"
                            ? "#f2c94c"
                            : company.classification === "tabulated"
                            ? "#13ed46"
                            : "#90d1fc",
                        borderRadius: 20,
                        margin: 10,
                        width: 12,
                        height: 12,
                      }}
                    />
                    <span
                      style={{
                        marginTop: 6.5,
                        color:
                          company.classification === ""
                            ? "#ed6826"
                            : company.classification === "identified"
                            ? "#f2c94c"
                            : company.classification === "tabulated"
                            ? "#13ed46"
                            : "#90d1fc",
                      }}
                    >{`${
                      company.classification === ""
                        ? translation.tasks.contested
                        : company.classification === "identified"
                        ? translation.tasks.identified
                        : company.classification === "tabulated"
                        ? translation.tasks.tabulated
                        : translation.tasks.confirmed
                    }`}</span>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <H2 style={{}}>{company.ticker}</H2>

                  {!!financials ? (
                    <div style={{ display: "flex" }}>
                      <H3 style={{ marginLeft: 5 }}>
                        ({financials.exchangeSymbol})
                      </H3>
                      <H3 style={{ marginLeft: 5, fontStyle: "italic" }}>
                        {financials.primaryIndustry.name}
                      </H3>
                    </div>
                  ) : (
                    <div style={{ display: "flex" }}>
                      <H3 style={{ marginLeft: 5 }}>(Private Company)</H3>
                    </div>
                  )}
                </div>
                <div style={{}}>
                  <P style={{ marginTop: 5, display: "inline" }}>
                    {company.description + " "}
                  </P>
                  <div
                    style={{
                      cursor: "pointer",
                      display: "inline",
                      color: "#64804d",
                      textDecoration: "underline",
                    }}
                    onMouseOver={({ currentTarget }) => {
                      currentTarget.style.opacity = "50%";
                    }}
                    onMouseOut={({ currentTarget }) => {
                      currentTarget.style.opacity = "100%";
                    }}
                    onClick={() => {
                      if (!!company.wiki) {
                        openInNewTab(company.wiki);
                      } else {
                        alert(
                          "Sorry, but this company's wiki could not be found"
                        );
                      }
                    }}
                  >
                    Read more
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: 10,
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    type="solid"
                    style={{
                      fontSize: 14,
                      paddingTop: 5,
                      paddingRight: 10,
                      paddingBottom: 5,
                      paddingLeft: 10,
                      marginRight: 5,
                      flex: 1,
                    }}
                    onClick={() => {
                      setVisualizationViewportVisible({
                        type: "ghg_emissions",
                        company: company,
                        activedata: activeSustainabilityData,
                        isBloombergData: true,
                      });
                    }}
                  >
                    {translation.button.visualize_emissions}
                  </Button>
                  <Button
                    type="solid"
                    style={{
                      fontSize: 14,
                      paddingTop: 5,
                      paddingRight: 10,
                      paddingBottom: 5,
                      paddingLeft: 10,
                      marginLeft: 5,
                      flex: 1,
                    }}
                    onClick={() => {
                      setVisualizationViewportVisible({
                        type: "other_data",
                        company: company,
                        activedata: activeAnnualData,
                        isBloombergData: true,
                      });
                    }}
                  >
                    {translation.button.visualize_other}
                  </Button>
                </div>
              </div>
            </div>
            <div
              style={{
                padding: 10,
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  marginTop: isDesktop ? 0 : 10,
                  display: dimensions.width > 1200 ? "flex" : "block",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                <DropdownButton
                  type="solid"
                  style={{
                    backgroundColor: "#64804d",
                    margin: 5,
                    minWidth: 160,
                  }}
                  options={company.reports.annual}
                >
                  Annual Financials
                </DropdownButton>
                <DropdownButton
                  type="solid"
                  style={{
                    backgroundColor: "#64804d",
                    margin: 5,
                    minWidth: 160,
                  }}
                  options={company.reports.sustainability}
                >
                  Sustainability Reports
                </DropdownButton>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={{ textAlign: "center", margin: 10 }}>
                  <P style={{ fontSize: 11 }}>
                    {translation.company.sustainability_score}
                  </P>
                  <H1 style={{ fontSize: 32 }}>
                    {Math.floor(company.scores.sustainability) || "N/A"}
                  </H1>
                </div>
                <div style={{ textAlign: "center", margin: 10 }}>
                  <P style={{ fontSize: 11 }}>
                    {translation.company.transparency_score}
                  </P>
                  <H1 style={{ fontSize: 32 }}>
                    {Math.floor(company.scores.transparency) || "N/A"}
                  </H1>
                </div>
                <div style={{ textAlign: "center", margin: 10 }}>
                  <P style={{ fontSize: 11 }}>
                    {translation.company.decarbonization_score}
                  </P>
                  <H1 style={{ fontSize: 32 }}>
                    {Math.floor(company.scores.divestment) || "N/A"}
                  </H1>
                </div>
                <div style={{ textAlign: "center", margin: 10 }}>
                  <P style={{ fontSize: 11 }}>
                    {translation.company.completeness_score}
                  </P>
                  <H1 style={{ fontSize: 32 }}>
                    {Math.floor(company.scores.completeness) || "N/A"}
                  </H1>
                </div>
              </div>
              <div
                style={{
                  textAlign: "left",
                  marginLeft: 10,
                  marginRight: 10,
                  paddingBottom: 5,
                  borderBottomStyle: "solid",
                  borderBottomWidth: 1,
                  borderBottomColor: isDesktop ? "rgba(0,0,0,0)" : "#ffffff",
                }}
              >
                <div style={{ display: "flex" }}>
                  <P>
                    Contributors ({Object.keys(company.contributors).length}):
                  </P>
                  {Object.keys(company.contributors).map(
                    (contributor, index) => (
                      <Account
                        key={index}
                        id={contributor}
                        setActivePage={setActivePage}
                        isThumbnail={true}
                      />
                    )
                  )}
                </div>
                <div style={{ display: "flex" }}>
                  <P style={{ marginRight: 4 }}>Last edited by</P>
                  <Account
                    id={
                      Object.entries(
                        Object.fromEntries(
                          Object.entries(company.contributors).map(
                            ([key, val]) => [val, key]
                          )
                        )
                      ).sort((a, b) => {
                        if (a[0] < b[0]) {
                          return 1;
                        }
                        if (a[0] > b[0]) {
                          return -1;
                        }
                      })[0][1]
                    }
                    setActivePage={setActivePage}
                    isHandle={true}
                  />
                  <P style={{ marginLeft: 4 }}>
                    on{" "}
                    {new Date(
                      Number(
                        Object.entries(
                          Object.fromEntries(
                            Object.entries(company.contributors).map(
                              ([key, val]) => [val, key]
                            )
                          )
                        ).sort((a, b) => {
                          if (a[0] < b[0]) {
                            return 1;
                          }
                          if (a[0] > b[0]) {
                            return -1;
                          }
                        })[0][0]
                      )
                    ).toLocaleDateString("en-US", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}
                  </P>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              backgroundColor: "rgba(16, 16, 16, 0.975)",
              color: "#ffffff",
            }}
          >
            <CompanyOverview
              translation={translation}
              isDesktop={isDesktop}
              dimensions={dimensions}
              slug={slug}
              activeSustainabilityData={activeSustainabilityData}
              company={company}
              bloombergDataVisible={bloombergSustainabilityDataVisible}
              currencycode={currencycode}
              financials={financials}
            />
          </div>
          <CompanyNews
            translation={translation}
            isDesktop={isDesktop}
            dimensions={dimensions}
            articles={articles}
          />
        </div>
      ) : null}
    </div>
  );
};

export default CompanyPage;

"use es6";

import React, { useState, useEffect, useRef, useMemo } from "react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";

import { gapi } from "gapi-script";
import jwt_decode from "jwt-decode";
import Button from "../Buttons/Button";
import H2 from "../Text/H2";

import chevron from "../../graphics/icons/chevron.svg";
import P from "../Text/P";
import HR from "../Text/HR";
import Viewport from "./Viewport";
import Bold from "../Text/Bold";
import ReportDataCell from "../Company/ReportDataCell";
import Hint from "../Hint/Hint";
import A from "../Text/A";

const ReportDataViewport = ({
  isDesktop = null,
  children = "[insert text]",
  credentials = null,
  setCredentials = {},
  updateCredentials = {},
  language = null,
  translation = null,
  reportDataViewportVisible = false,
  setReportDataViewportVisible = {},
  reportData = {},
  setReportData = {},
  reportDataSources = {},
  setReportDataSources = {},
  reports = [],
  type = null,
  company = {},
}) => {
  const navigate = useNavigate();

  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  const [newReportData, setNewReportData] = useState(reportData);
  const [newReportDataSources, setNewReportDataSources] =
    useState(reportDataSources);
  const [morningstarUrl, setMorningstarUrl] = useState(
    `https://www.morningstar.com/stocks/${
      !!company.alternateechange
        ? company.alternateechange
        : company.slug.split("_")[1] === "us"
        ? "xnys"
        : company.slug.split("_")[1] === "ca"
        ? "xtse"
        : company.slug.split("_")[1] === "gb"
        ? "xlon"
        : company.slug.split("_")[1] === "fr"
        ? "xpar"
        : company.slug.split("_")[1] === "it"
        ? "xmil"
        : company.slug.split("_")[1] === "de"
        ? "xetr"
        : company.slug.split("_")[1] === "pt"
        ? "xlis"
        : company.slug.split("_")[1] === "nl"
        ? "xams"
        : company.slug.split("_")[1] === "es"
        ? "xmad"
        : company.slug.split("_")[1] === "ch"
        ? "xswx"
        : company.slug.split("_")[1] === "se"
        ? "xsto"
        : company.slug.split("_")[1] === "dk"
        ? "xcse"
        : company.slug.split("_")[1] === "jp"
        ? "xtks"
        : company.slug.split("_")[1] === "cn"
        ? "xhkg"
        : company.slug.split("_")[1] === "in"
        ? "xnse"
        : company.slug.split("_")[1] === "mx"
        ? "xmex"
        : company.slug.split("_")[1] === "br"
        ? "bvmf"
        : company.slug.split("_")[1] === "au"
        ? "xasx"
        : company.slug.split("_")[1] === "hk"
        ? "xhkg"
        : company.slug.split("_")[1] === "kr"
        ? "xkrx"
        : company.slug.split("_")[1] === "ng"
        ? "xnsa"
        : company.slug.split("_")[1] === "za"
        ? "xjse"
        : "xnas"
    }/${company.ticker}/financials`
  );
  const [enterprisevalue, setEnterprisevalue] = useState(0);
  const [historicalmarketcap, setHistoricalmarketcap] = useState(0);

  const rows1 = [
    "ghg_scope_1",
    "ghg_scope_2_location_based",
    "ghg_scope_3",
    "scope_3_purch_goods_srvcs",
    "scope_3_capital_goods",
    "scope_3_fuel_enrg_relatd_act",
    "scope_3_upstream_trans_dist",
    "scope_3_waste_genrtd_in_op",
    "scope_3_business_trvl_emissions",
    "scope_3_employee_commuting",
    "scope_3_upstream_leased_assets",
    "scope_3_dwnstrm_trans_dist",
    "scope_3_prcss_of_sold_prods",
    "scope_3_use_sold_products",
    "scope_3_eol_trtmnt_prods",
    "scope_3_dwnstrm_lease_assts",
    "scope_3_franchises",
    "scope_3_investments",
    "scope_3_emissions_other",
  ];

  const rows2 = [
    "enterprise_value",
    "is_comp_sales",
    "historical_market_cap",
    "is_avg_num_sh_for_eps",
    "px_last",
    "short_and_long_term_debt",
    "cash_and_marketable_securities",
    "bs_tot_asset",
  ];

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <Viewport
      isDesktop={isDesktop}
      heading={
        translation.company.update +
        translation.company.emissions_and_other_data
      }
      viewportVisible={reportDataViewportVisible}
      setViewportVisible={setReportDataViewportVisible}
    >
      <div
        style={{
          textAlign: "left",
          maxHeight: isDesktop ? 450 : null,
          overflowY: "scroll",
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        <br />
        <div style={{ display: "flex" }}>
          <H2>{translation.company.ghg_emissions}</H2>
          <Hint
            style={{
              margin: 3,
              marginLeft: 10,
              display: "bottom",
              fontWeight: "400",
              zIndex: 70,
            }}
            iconColor="black"
            textboxColor="black"
            size={24}
          >
            Use the hints for each column to help you fill out the table below!
          </Hint>
        </div>
        <div style={{ width: "100%", maxWidth: 1000, overflowX: "scroll" }}>
          <table id="companydata-dark" style={{ fontSize: 11 }}>
            <tr>
              <th style={{ width: "1%" }}>Year</th>
              <th style={{ width: "1%" }}>
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    margin: "auto",
                    justifyContent: "space-between",
                  }}
                >
                  {translation.company.reference_document}{" "}
                  <Hint
                    style={{
                      marginLeft: 6,
                      display: "bottom",
                      fontWeight: "400",
                    }}
                    iconColor="white"
                    textboxColor="black"
                    size={16}
                    tableHint={true}
                  >
                    The document found at this link will be where you can find
                    the data needed to fill out this table. If the link takes
                    you to an SEC Electronic Data Gathering, Analysis, and
                    Retrieval (EDGAR) report document, be sure to use the Fact
                    Tool to more easily find the desired numbers for the
                    following columns.
                  </Hint>
                </div>
              </th>
              <th style={{ minWidth: 100 }}>
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    margin: "auto",
                    justifyContent: "space-between",
                  }}
                >
                  {translation.company.ghg_scope_1}{" "}
                  <Hint
                    style={{
                      marginLeft: 6,
                      display: "bottom",
                      fontWeight: "400",
                    }}
                    iconColor="white"
                    textboxColor="black"
                    size={16}
                    tableHint={true}
                  >
                    These are direct emissions from owned or controlled sources.
                    To find this number try searching "scope 1" in the document
                    and finding the corresponding value.
                  </Hint>
                </div>
              </th>
              <th style={{ minWidth: 100 }}>
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    margin: "auto",
                    justifyContent: "space-between",
                  }}
                >
                  {translation.company.ghg_scope_2_location_based}{" "}
                  <Hint
                    style={{
                      marginLeft: 6,
                      display: "bottom",
                      fontWeight: "400",
                    }}
                    iconColor="white"
                    textboxColor="black"
                    size={16}
                    tableHint={true}
                  >
                    These are indirect emissions from purchased electricity,
                    steam, heating, and cooling. To find this number try
                    searching "scope 2" in the document and finding the
                    corresponding value. NOTE: Not all companies report scope 2,
                    so this column might end up blank.
                  </Hint>
                </div>
              </th>
              <th style={{ minWidth: 100 }}>
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    margin: "auto",
                    justifyContent: "space-between",
                  }}
                >
                  {translation.company.ghg_scope_3}{" "}
                  <Hint
                    style={{
                      marginLeft: 6,
                      display: "bottom",
                      fontWeight: "400",
                    }}
                    iconColor="white"
                    textboxColor="black"
                    size={16}
                    tableHint={true}
                  >
                    These are all other indirect emissions in the value chain.
                    To find this number try searching "scope 3" in the document
                    and finding the corresponding value. NOTE: Not all companies
                    have a comprehensive breakdown of scope 3, so this column
                    (as well as the ones to the right) might end up blank.
                  </Hint>
                </div>
              </th>
              <th style={{ minWidth: 100 }}>
                {translation.company.scope_3_purch_goods_srvcs}
              </th>
              <th style={{ minWidth: 100 }}>
                {translation.company.scope_3_capital_goods}
              </th>
              <th style={{ minWidth: 100 }}>
                {translation.company.scope_3_fuel_enrg_relatd_act}
              </th>
              <th style={{ minWidth: 100 }}>
                {translation.company.scope_3_upstream_trans_dist}
              </th>
              <th style={{ minWidth: 100 }}>
                {translation.company.scope_3_waste_genrtd_in_op}
              </th>
              <th style={{ minWidth: 100 }}>
                {translation.company.scope_3_business_trvl_emissions}
              </th>
              <th style={{ minWidth: 100 }}>
                {translation.company.scope_3_employee_commuting}
              </th>
              <th style={{ minWidth: 100 }}>
                {translation.company.scope_3_upstream_leased_assets}
              </th>
              <th style={{ minWidth: 100 }}>
                {translation.company.scope_3_dwnstrm_trans_dist}
              </th>
              <th style={{ minWidth: 100 }}>
                {translation.company.scope_3_prcss_of_sold_prods}
              </th>
              <th style={{ minWidth: 100 }}>
                {translation.company.scope_3_use_sold_products}
              </th>
              <th style={{ minWidth: 100 }}>
                {translation.company.scope_3_eol_trtmnt_prods}
              </th>
              <th style={{ minWidth: 100 }}>
                {translation.company.scope_3_dwnstrm_lease_assts}
              </th>
              <th style={{ minWidth: 100 }}>
                {translation.company.scope_3_franchises}
              </th>
              <th style={{ minWidth: 100 }}>
                {translation.company.scope_3_investments}
              </th>
              <th style={{ minWidth: 100 }}>
                {translation.company.scope_3_emissions_other}
              </th>
            </tr>
            {Object.keys(newReportData)
              .reverse()
              .map((year, index) => (
                <tr key={index}>
                  <td style={{ opacity: "50%" }}>{year}</td>
                  <td style={{ color: "#90d1fc" }}>
                    {" "}
                    {!!reports[1][year] ? (
                      <div
                        style={{
                          cursor: "pointer",
                          display: "inline",
                          color: "#64804d",
                          textDecoration: "underline",
                        }}
                        onMouseOver={({ currentTarget }) => {
                          currentTarget.style.opacity = "50%";
                        }}
                        onMouseOut={({ currentTarget }) => {
                          currentTarget.style.opacity = "100%";
                        }}
                        onClick={() => {
                          if (!!reports[1][year]) {
                            openInNewTab(reports[1][year]);
                          }
                        }}
                      >
                        Document Link
                      </div>
                    ) : null}
                  </td>
                  {rows1.map((variable, index) => (
                    <td key={index}>
                      <ReportDataCell
                        translation={translation}
                        reportData={reportData}
                        newReportData={newReportData}
                        setNewReportData={setNewReportData}
                        newReportDataSources={newReportDataSources}
                        setNewReportDataSources={setNewReportDataSources}
                        variable={variable}
                        year={year}
                        reports={reports[1]}
                        type={type}
                        enterprisevalue={enterprisevalue}
                        setEnterprisevalue={setEnterprisevalue}
                        historicalmarketcap={historicalmarketcap}
                        setHistoricalmarketcap={setHistoricalmarketcap}
                      />
                    </td>
                  ))}
                </tr>
              ))}
          </table>
        </div>
        <br />
        <br />
        <div style={{ display: "flex" }}>
          <H2>{translation.company.other_data}</H2>
          <Hint
            style={{
              margin: 3,
              marginLeft: 10,
              display: "bottom",
              fontWeight: "400",
              zIndex: 70,
            }}
            iconColor="black"
            textboxColor="black"
            size={24}
          >
            Use the hints for each column to help you fill out the table below!
          </Hint>
        </div>
        <div style={{ width: "100%", maxWidth: 1000, overflowX: "scroll" }}>
          <table id="companydata-dark" style={{ fontSize: 11 }}>
            <tr>
              <th style={{ width: "1%" }}>Year</th>
              <th style={{ width: "1%" }}>
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    margin: "auto",
                    justifyContent: "space-between",
                  }}
                >
                  {translation.company.reference_document}{" "}
                  <Hint
                    style={{
                      marginLeft: 6,
                      display: "bottom",
                      fontWeight: "400",
                    }}
                    iconColor="white"
                    textboxColor="black"
                    size={16}
                    tableHint={true}
                  >
                    The document found at this link will be where you can find
                    the data needed to fill out this table. If the link takes
                    you to an SEC Electronic Data Gathering, Analysis, and
                    Retrieval (EDGAR) report document, be sure to use the Fact
                    Tool to more easily find the desired numbers for the
                    following columns.
                  </Hint>
                </div>
              </th>
              <th style={{ minWidth: 100 }}>
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    margin: "auto",
                    justifyContent: "space-between",
                  }}
                >
                  {`${translation.company.enterprise_value} ($)`}{" "}
                  <Hint
                    style={{
                      marginLeft: 6,
                      display: "bottom",
                      fontWeight: "400",
                    }}
                    iconColor="white"
                    textboxColor="black"
                    size={16}
                    width={285}
                    tableHint={true}
                  >
                    Auto-generated:
                    <br />
                    <i>(Market Cap) + Short & Long Term Debt - Cash On-Hand</i>
                  </Hint>
                </div>
              </th>
              <th style={{ minWidth: 100 }}>
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    margin: "auto",
                    justifyContent: "space-between",
                  }}
                >
                  {`${translation.company.is_comp_sales} ($)`}{" "}
                  <Hint
                    style={{
                      marginLeft: 6,
                      display: "bottom",
                      fontWeight: "400",
                    }}
                    iconColor="white"
                    textboxColor="black"
                    size={16}
                    tableHint={true}
                  >
                    Enter "revenue" into the Fact Tool, typically this number
                    will be reported as "Revenues 12 months ending mm/dd/yyyy"
                    in the company's financial report on EDGAR.
                  </Hint>
                </div>
              </th>
              <th style={{ minWidth: 100 }}>
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    margin: "auto",
                    justifyContent: "space-between",
                  }}
                >
                  {`${translation.company.historical_market_cap} ($)`}{" "}
                  <Hint
                    style={{
                      marginLeft: 6,
                      display: "bottom",
                      fontWeight: "400",
                    }}
                    iconColor="white"
                    textboxColor="black"
                    size={16}
                    width={285}
                    tableHint={true}
                  >
                    Auto-generated:
                    <br />
                    <i>(Shares Outstanding × Annual Closing Share Price)</i>
                  </Hint>
                </div>
              </th>
              <th style={{ minWidth: 100 }}>
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    margin: "auto",
                    justifyContent: "space-between",
                  }}
                >
                  {`${translation.company.is_avg_num_sh_for_eps}`}{" "}
                  <Hint
                    style={{
                      marginLeft: 6,
                      display: "bottom",
                      fontWeight: "400",
                    }}
                    iconColor="white"
                    textboxColor="black"
                    size={16}
                    offset={10}
                    tableHint={true}
                  >
                    Enter "shares outstanding" into the Fact Tool, typically
                    this number will be reported as "Weighted Average Number Of
                    Common Shares Outstanding 12 months ending mm/dd/yyyy" in
                    the company's financial report on EDGAR.
                  </Hint>
                </div>
              </th>
              <th style={{ minWidth: 100 }}>
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    margin: "auto",
                    justifyContent: "space-between",
                  }}
                >
                  {`${translation.company.px_last} ($)`}{" "}
                  <Hint
                    style={{
                      marginLeft: 6,
                      display: "bottom",
                      fontWeight: "400",
                    }}
                    iconColor="white"
                    textboxColor="black"
                    size={16}
                    offset={15}
                    tableHint={true}
                  >
                    This number is usually not included in the annual report,
                    but you can find it{" "}
                    <A
                      href={`https://finance.yahoo.com/quote/${company.ticker.toUpperCase()}/history`}
                    >
                      here
                    </A>{" "}
                    by looking for the last non-adjusted closing price for that
                    financial year.
                  </Hint>
                </div>
              </th>
              <th style={{ minWidth: 100 }}>
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    margin: "auto",
                    justifyContent: "space-between",
                  }}
                >
                  {`${translation.company.short_and_long_term_debt} ($)`}{" "}
                  <Hint
                    style={{
                      marginLeft: 6,
                      display: "bottom",
                      fontWeight: "400",
                    }}
                    iconColor="white"
                    textboxColor="black"
                    size={16}
                    offset={20}
                    tableHint={true}
                  >
                    This consolidated number is usually not included in the
                    annual report, but you can find it{" "}
                    <A href={morningstarUrl}>here</A> listed as Total Debt
                    (Bil).
                  </Hint>
                </div>
              </th>
              <th style={{ minWidth: 100 }}>
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    margin: "auto",
                    justifyContent: "space-between",
                  }}
                >
                  {`${translation.company.cash_and_marketable_securities} ($)`}{" "}
                  <Hint
                    style={{
                      marginLeft: 6,
                      display: "bottom",
                      fontWeight: "400",
                    }}
                    iconColor="white"
                    textboxColor="black"
                    size={16}
                    offset={25}
                    tableHint={true}
                  >
                    Enter "cash" into the Fact Tool, typically this number will
                    be reported as "Cash and Cash Equivalents" in the company's
                    financial report on EDGAR.
                  </Hint>
                </div>
              </th>
              <th style={{ minWidth: 100 }}>
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    margin: "auto",
                    justifyContent: "space-between",
                  }}
                >
                  {`${translation.company.bs_tot_asset} ($)`}{" "}
                  <Hint
                    style={{
                      marginLeft: 6,
                      display: "bottom",
                      fontWeight: "400",
                    }}
                    iconColor="white"
                    textboxColor="black"
                    size={16}
                    offset={30}
                    tableHint={true}
                  >
                    Enter "total assets" into the Fact Tool, typically this
                    number will be reported as "Assets As of mm/dd/yyyy" in the
                    company's financial report on EDGAR.
                  </Hint>
                </div>
              </th>
            </tr>
            {Object.keys(newReportData)
              .reverse()
              .map((year, index) => (
                <tr key={index}>
                  <td style={{ opacity: "50%", width: 80 }}>{year}</td>
                  <td style={{ color: "#90d1fc", width: "400px" }}>
                    {!!reports[0][year] ? (
                      <div
                        style={{
                          cursor: "pointer",
                          display: "inline",
                          color: "#64804d",
                          textDecoration: "underline",
                        }}
                        onMouseOver={({ currentTarget }) => {
                          currentTarget.style.opacity = "50%";
                        }}
                        onMouseOut={({ currentTarget }) => {
                          currentTarget.style.opacity = "100%";
                        }}
                        onClick={() => {
                          if (!!reports[0][year]) {
                            openInNewTab(reports[0][year]);
                          }
                        }}
                      >
                        Document Link
                      </div>
                    ) : null}
                  </td>
                  {rows2.map((variable, index) => (
                    <td key={index}>
                      <ReportDataCell
                        translation={translation}
                        reportData={reportData}
                        newReportData={newReportData}
                        setNewReportData={setNewReportData}
                        newReportDataSources={newReportDataSources}
                        setNewReportDataSources={setNewReportDataSources}
                        variable={variable}
                        year={year}
                        reports={reports[0]}
                        type={type}
                        enterprisevalue={enterprisevalue}
                        setEnterprisevalue={setEnterprisevalue}
                        historicalmarketcap={historicalmarketcap}
                        setHistoricalmarketcap={setHistoricalmarketcap}
                      />
                    </td>
                  ))}
                </tr>
              ))}
          </table>
        </div>
        <br />
        <br />
        <Button
          type="solid"
          style={{ textAlign: "center", margin: "auto", width: 315 }}
          onClick={() => {
            setReportData(newReportData);
            setReportDataSources(newReportDataSources);
            setReportDataViewportVisible(false);
          }}
        >
          {translation.button.save}
        </Button>
      </div>
    </Viewport>
  );
};

export default ReportDataViewport;

"use es6";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

import xmark from "../../graphics/icons/xmark.svg";
import P from "../Text/P";
import Button from "../Buttons/Button";
import HR from "../Text/HR";

const Account = ({
  credentials = {},
  updateCredentials = {},
  translation = null,
  isThumbnail = false,
  isHandle = false,
  id = null,
  setActivePage = {},
  ...buttonProps
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [account, setAccount] = useState(null);

  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  const getAccount = async () => {
    axios
      .get(
        `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${id}`,
        requestConfig
      )
      .then((res) => {
        setAccount(res.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getAccount();
  }, [id]);

  return !!account ? (
    <div>
      {isThumbnail ? (
        <div>
          <img
            src={
              account.image ||
              "https://sfh-defaults.s3.amazonaws.com/defaultprofile.png"
            }
            style={{
              cursor: "pointer",
              width: 20,
              height: 20,
              objectFit: "cover",
              borderWidth: 1,
              borderColor: "#101010",
              borderStyle: "solid",
              borderRadius: 20,
              marginLeft: 5,
              marginTop: -1,
            }}
            onClick={() => {
              navigate(`/${account.username}`);
              setActivePage("explore");
            }}
            onMouseOver={({ currentTarget }) => {
              currentTarget.style.opacity = "50%";
            }}
            onMouseOut={({ currentTarget }) => {
              currentTarget.style.opacity = "100%";
            }}
          />
        </div>
      ) : isHandle ? (
        <P
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            navigate(`/${account.username}`);
            setActivePage("explore");
          }}
          onMouseOver={({ currentTarget }) => {
            currentTarget.style.opacity = "50%";
          }}
          onMouseOut={({ currentTarget }) => {
            currentTarget.style.opacity = "100%";
          }}
        >
          {account.username}
        </P>
      ) : (
        <div>
          <HR />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 5,
            }}
          >
            <div style={{ display: "flex", alignContent: "center" }}>
              <img
                src={
                  account.image ||
                  "https://sfh-defaults.s3.amazonaws.com/defaultprofile.png"
                }
                style={{
                  width: 48,
                  height: 48,
                  objectFit: "cover",
                  borderWidth: 1,
                  borderColor: "#101010",
                  borderStyle: "solid",
                  borderRadius: 48,
                  margin: 10,
                }}
                onClick={() => {
                  navigate(`/${account.username}`);
                  setActivePage("explore");
                }}
                onMouseOver={({ currentTarget }) => {
                  currentTarget.style.opacity = "50%";
                }}
                onMouseOut={({ currentTarget }) => {
                  currentTarget.style.opacity = "100%";
                }}
              />
              <P style={{ marginTop: 10 }}>@{account.username} </P>
            </div>
          </div>
        </div>
      )}
    </div>
  ) : null;
};

export default Account;

"use es6";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import AWS from "aws-sdk";

import { gapi } from "gapi-script";
import jwt_decode from "jwt-decode";
import Button from "../Buttons/Button";
import H1 from "../Text/H1";

import chevron from "../../graphics/icons/chevron.svg";
import H3 from "../Text/H3";
import H2 from "../Text/H2";
import P from "../Text/P";
import HR from "../Text/HR";
import Viewport from "./Viewport";
import UploadViewport from "./UploadViewport";
import ReportDataViewport from "./ReportDataViewport";
import ReportsViewport from "./ReportsViewport";
import Loading from "../Loading/Loading";

const S3_BUCKET = "sfh-avatars";
const REGION = "us-east-1";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const CompanyViewport = ({
  isDesktop = false,
  type = null,
  credentials = null,
  setCredentials = {},
  updateCredentials = {},
  language = null,
  translation = null,
  activePage = {},
  setActivePage = {},
  setSettingsViewportVisible = {},
  companyViewportVisible = null,
  setCompanyViewportVisible = {},
  uploadViewportVisible = false,
  setUploadViewportVisible = {},
  reportDataViewportVisible = false,
  setReportDataViewportVisible = {},
  reportsViewportVisible = false,
  setReportsViewportVisible = {},
  setCompanyTimestamp = {},
}) => {
  const navigate = useNavigate();

  const [slug, setSlug] = useState(companyViewportVisible.company.slug || "");
  const [name, setName] = useState(companyViewportVisible.company.name || "");
  const [ticker, setTicker] = useState(
    companyViewportVisible.company.ticker || ""
  );
  const [image, setImage] = useState(
    companyViewportVisible.company.image || ""
  );
  const [description, setDescription] = useState(
    companyViewportVisible.company.description || ""
  );
  const [website, setWebsite] = useState(
    companyViewportVisible.company.website || ""
  );
  const [wiki, setWiki] = useState(companyViewportVisible.company.wiki || "");
  const [contributors, setContributors] = useState(
    companyViewportVisible.company.contributors || {}
  );
  const [tabulated, setTabulated] = useState(
    companyViewportVisible.company.tabulated || false
  );
  const [sib, setSib] = useState(companyViewportVisible.company.sib || false);
  const [history, setHistory] = useState(
    companyViewportVisible.company.history || {}
  );
  const [reportData, setReportData] = useState(
    companyViewportVisible.company.reportdata || {}
  );
  const [reportDataSources, setReportDataSources] = useState(
    companyViewportVisible.company.reportdatasources || {}
  );
  const [annualReports, setAnnualReports] = useState(
    companyViewportVisible.company.reports.annual || {}
  );
  const [sustainabilityReports, setSustainabilityReports] = useState(
    companyViewportVisible.company.reports.sustainability || {}
  );

  const [selectedFile, setSelectedFile] = useState(null);
  const [progress, setProgress] = useState(0);

  const [isRelegating, setIsRelegating] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const requirementsMet =
    !!name &&
    !!slug &&
    !!ticker &&
    (!!image || !!selectedFile) &&
    !!description &&
    !!website &&
    !!reportData &&
    !!reportDataSources &&
    !!annualReports &&
    !!sustainabilityReports;

  const uploadFile = (file, id) => {
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: `${id}.jpeg`,
      ContentEncoding: "base64",
    };

    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        setProgress(Math.round((evt.loaded / evt.total) * 100));
      })
      .send((err) => {
        // if (err) console.log(err);
      });
  };

  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  const unreserveCompany = async (id, isAdmin) => {
    let reservations = credentials.reservations;

    if (isAdmin) {
      delete reservations[id];
      await axios
        .post(
          `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${credentials.id}`,
          { reservations: reservations },
          requestConfig
        )
        .then(async (res) => {
          updateCredentials(res.data.account);
          await axios
            .post(
              `${process.env.REACT_APP_COMPANYS_API_URL}/companys/${id}`,
              { reserved: "none" },
              requestConfig
            )
            .then(async () => {
              setCompanyViewportVisible(false);
              setIsSubmitting(false);
              setIsRelegating(false);
              window.location.reload();
            })
            .catch((error) => {
              // console.log(error);
            });
        })
        .catch((error) => {
          // console.log(error);
        });
    } else {
      reservations[id] = "reviewing";
      await axios
        .post(
          `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${credentials.id}`,
          { reservations: reservations },
          requestConfig
        )
        .then(async (res) => {
          updateCredentials(res.data.account);
          await axios
            .post(
              `${process.env.REACT_APP_COMPANYS_API_URL}/companys/${id}`,
              { reserved: "reviewing" },
              requestConfig
            )
            .then(async () => {
              alert(translation.company.submitted);
              setCompanyViewportVisible(false);
              setIsSubmitting(false);
              setIsRelegating(false);
              window.location.reload();
            })
            .catch((error) => {
              // console.log(error);
            });
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };

  const updateCompany = async () => {
    if (!window.confirm(translation.company.confirm_message)) {
      return;
    }
    setIsSubmitting(true);

    const timestamp = Date.now();

    const historyentry = { [timestamp]: credentials.id };
    const contributorentry = { [credentials.id]: timestamp };

    const id = Date.now();

    if (!!selectedFile) {
      uploadFile(selectedFile, id);
    }

    const data = {
      id: Number(companyViewportVisible.company.id) || timestamp,
      slug: slug,
      name: name,
      ticker: ticker,
      moniker: String(
        name + " " + slug + " " + slug.replace("_", " ")
      ).toUpperCase(),
      image: !!selectedFile
        ? `https://sfh-avatars.s3.amazonaws.com/${id}.jpeg`
        : !!image
        ? image
        : null,
      description: description,
      website: website,
      wiki: wiki,
      reportdata: reportData,
      reportdatasources: reportDataSources,
      reports: {
        annual: annualReports,
        sustainability: sustainabilityReports,
      },
      contributors: { ...contributors, ...contributorentry },
      history: { ...history, ...historyentry },
      classification:
        companyViewportVisible.type === "identify"
          ? "identified"
          : companyViewportVisible.type === "tabulate"
          ? "tabulated"
          : companyViewportVisible.type === "confirm"
          ? "confirmed"
          : "",
      reserved: "none",
      relegationpurpose: "",
    };

    if (credentials.admin !== "valid") {
      const requestBody = {
        action: {
          id: Date.now(),
          sender: credentials.id,
          type: "request",
          data: data,
        },
      };

      await axios
        .get(`${process.env.REACT_APP_ACCOUNTS_API_URL}/admins`, requestConfig)
        .then(async (res) => {
          res.data.map(async (admin) => {
            await axios.post(
              `${process.env.REACT_APP_NOTIFICATIONS_API_URL}/notifications/${admin.id}`,
              requestBody,
              requestConfig
            );

            await axios.post(
              `${process.env.REACT_APP_EMAILS_API_URL}/authenticate`,
              { message: "notification", to: admin.email },
              requestConfig
            );
          });
        })
        .then(async () => {
          unreserveCompany(companyViewportVisible.company.id, false);
        })
        .catch((error) => {
          setIsSubmitting(false);
          // console.log(error);
        });
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_COMPANYS_API_URL}/companys/${companyViewportVisible.company.id}`,
          data,
          requestConfig
        )
        .then(async () => {
          unreserveCompany(companyViewportVisible.company.id, true);
        })
        .catch((error) => {
          setIsSubmitting(false);
          // console.log(error);
        });
    }
  };

  const relegateCompany = async () => {
    if (!window.confirm(translation.company.confirm_relegation_message)) {
      return;
    }
    const reason = prompt(translation.company.relegation_prompt);
    setIsRelegating(true);

    const timestamp = Date.now();

    const id = Date.now();

    if (!!selectedFile) {
      uploadFile(selectedFile, id);
    }

    const data = {
      id: Number(companyViewportVisible.company.id) || timestamp,
      slug: slug,
      name: name,
      ticker: ticker,
      moniker: String(
        name + " " + slug + " " + slug.replace("_", " ")
      ).toUpperCase(),
      image: !!selectedFile
        ? `https://sfh-avatars.s3.amazonaws.com/${id}.jpeg`
        : !!image
        ? image
        : null,
      description: description,
      website: website,
      wiki: wiki,
      reportdata: reportData,
      reportdatasources: reportDataSources,
      reports: {
        annual: annualReports,
        sustainability: sustainabilityReports,
      },
      contributors: { ...contributors },
      history: { ...history },
      classification:
        companyViewportVisible.type === "identify"
          ? ""
          : companyViewportVisible.type === "tabulate"
          ? ""
          : companyViewportVisible.type === "confirm"
          ? "identified"
          : "",
      reserved: "none",
      relegationpurpose: reason,
    };

    if (credentials.admin !== "valid") {
      const requestBody = {
        action: {
          id: Date.now(),
          sender: credentials.id,
          type: "relegate",
          data: data,
        },
      };

      await axios
        .get(`${process.env.REACT_APP_ACCOUNTS_API_URL}/admins`, requestConfig)
        .then(async (res) => {
          res.data.map(async (admin) => {
            await axios.post(
              `${process.env.REACT_APP_NOTIFICATIONS_API_URL}/notifications/${admin.id}`,
              requestBody,
              requestConfig
            );
            await axios.post(
              `${process.env.REACT_APP_EMAILS_API_URL}/authenticate`,
              { message: "notification", to: admin.email },
              requestConfig
            );
          });
        })
        .then(async () => {
          unreserveCompany(companyViewportVisible.company.id, false);
        })
        .catch((error) => {
          setIsRelegating(false);
          // console.log(error);
        });
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_COMPANYS_API_URL}/companys/${companyViewportVisible.company.id}`,
          data,
          requestConfig
        )
        .then(async () => {
          unreserveCompany(companyViewportVisible.company.id, true);
        })
        .catch((error) => {
          setIsRelegating(false);
          // console.log(error);
        });
    }
  };

  return (
    <Viewport
      isDesktop={isDesktop}
      heading={translation.company[companyViewportVisible.type]}
      viewportVisible={companyViewportVisible}
      setViewportVisible={setCompanyViewportVisible}
    >
      <div
        style={{
          margin: "auto",
          justifyContent: "center",
          textAlign: "center",
          maxHeight: isDesktop ? 550 : "100%",
          overflowY: "scroll",
        }}
      >
        <br />
        {!!companyViewportVisible.company.relegationpurpose ? (
          <div>
            <div
              style={{
                borderWidth: 1,
                borderColor: "#101010",
                borderStyle: "solid",
                backgroundColor: "rgba(16, 16, 16, 0.975)",
                borderRadius: 8,
                maxWidth: 350,
                margin: "auto",
                padding: 10,
                marginBottom: 15,
              }}
            >
              <P style={{ color: "#eb5757", fontStyle: "italic" }}>
                This company was recently relegated for the following reason:
              </P>
              <P style={{ color: "#ffffff" }}>
                "{companyViewportVisible.company.relegationpurpose}"
              </P>
            </div>
          </div>
        ) : null}
        <img
          src={image || "https://sfh-defaults.s3.amazonaws.com/blankimage.png"}
          alt={`${companyViewportVisible.company.name}'s logo.`}
          style={{
            marginTop: 20,
            borderWidth: 1,
            borderColor: "#101010",
            borderStyle: "solid",
            margin: "auto",
            maxWidth: 160,
            maxHeight: 80,
            padding: 5,
            backgroundColor: "#ffffff",
            objectFit: "cover",
          }}
        />
        <Button
          style={{ color: "rgba(100, 128, 77, 0.975)", margin: 10 }}
          onClick={() => {
            setUploadViewportVisible(true);
          }}
        >
          {translation.miscellaneous.change_image}
        </Button>
        <br />
        <P
          style={{
            color: "#000000",
            textAlign: "left",
            margin: "auto",
            width: 350,
            padding: 5,
          }}
        >
          {translation.company.name}:
        </P>
        <input
          className="textinput"
          style={{
            width: 330,
            padding: 10,
            marginBottom: 10,
            borderStyle: "solid",
            borderRadius: 8,
            borderWidth: 0.5,
            borderColor: "#cccccc",
            backgroundColor: isDesktop ? "#eeeff0" : "#ffffff",
          }}
          type="text"
          value={name}
          placeholder={translation.profile.name}
          maxLength="100"
          onChange={(event) => setName(event.target.value)}
        />
        {credentials.admin === "valid" ? (
          <div
            style={{
              display: "flex",
              margin: "auto",
              width: 350,
              paddingBottom: 10,
            }}
          >
            <div
              style={{
                textAlign: "left",
                justifyContent: "space-between",
                marginRight: 7,
              }}
            >
              <P
                style={{
                  color: credentials.admin === "valid" ? "#000000" : "#444444",
                  margin: 0,
                  marginBottom: 10,
                }}
              >
                {translation.company.ticker}:
              </P>
              <input
                className="textinput"
                style={{
                  width: 80,
                  padding: 10,
                  borderStyle: "solid",
                  borderRadius: 8,
                  borderWidth: 0.5,
                  borderColor: "#cccccc",
                  backgroundColor: isDesktop ? "#eeeff0" : "#ffffff",
                  color: credentials.admin === "valid" ? "#000000" : "#444444",
                }}
                readOnly={companyViewportVisible.type !== "adminedit"}
                type="text"
                value={ticker}
                placeholder={translation.company.ticker}
                maxLength="24"
                onChange={(event) => {
                  setTicker(event.target.value.toUpperCase());
                }}
              />
            </div>
            <div
              style={{
                textAlign: "left",
                justifyContent: "space-between",
                marginLeft: 7,
              }}
            >
              <P
                style={{
                  color: credentials.admin === "valid" ? "#000000" : "#444444",
                  margin: 0,
                  marginBottom: 10,
                }}
              >
                {translation.company.slug}:
              </P>
              <input
                className="textinput"
                style={{
                  width: 80,
                  padding: 10,
                  borderStyle: "solid",
                  borderRadius: 8,
                  borderWidth: 0.5,
                  borderColor: "#cccccc",
                  backgroundColor: isDesktop ? "#eeeff0" : "#ffffff",
                  color: credentials.admin === "valid" ? "#000000" : "#444444",
                }}
                readOnly={companyViewportVisible.type !== "adminedit"}
                type="text"
                value={slug}
                placeholder={translation.company.slug}
                maxLength="24"
                onChange={(event) => {
                  setSlug(event.target.value.toLowerCase());
                }}
              />
            </div>
            {credentials.admin === true ? (
              <div
                style={{
                  textAlign: "left",
                  justifyContent: "space-between",
                  marginLeft: 15,
                }}
              >
                <P style={{ color: "#000000", margin: 0, marginBottom: 20 }}>
                  {translation.company.tabulated}:
                </P>
                <input
                  type="checkbox"
                  checked={tabulated}
                  value={tabulated}
                  onChange={(event) => {
                    setTabulated(!tabulated);
                  }}
                />
              </div>
            ) : null}
            {credentials.admin === true ? (
              <div
                style={{
                  textAlign: "left",
                  justifyContent: "space-between",
                  marginLeft: 15,
                }}
              >
                <P style={{ color: "#000000", margin: 0, marginBottom: 20 }}>
                  {translation.company.sib}:
                </P>
                <input
                  type="checkbox"
                  checked={sib}
                  value={sib}
                  onChange={(event) => {
                    setSib(!sib);
                  }}
                />
              </div>
            ) : null}
          </div>
        ) : null}
        <P
          style={{
            color: "#000000",
            textAlign: "left",
            margin: "auto",
            width: 350,
            padding: 5,
          }}
        >
          {translation.company.website}:
        </P>
        <input
          className="textinput"
          style={{
            width: 330,
            padding: 10,
            marginBottom: 10,
            borderStyle: "solid",
            borderRadius: 8,
            borderWidth: 0.5,
            borderColor: "#cccccc",
            backgroundColor: isDesktop ? "#eeeff0" : "#ffffff",
          }}
          type="text"
          value={website}
          placeholder={translation.company.website}
          maxLength="100"
          onChange={(event) => {
            setWebsite(event.target.value);
          }}
        />
        <P
          style={{
            color: "#000000",
            textAlign: "left",
            margin: "auto",
            width: 350,
            padding: 5,
          }}
        >
          {translation.company.wiki}:
        </P>
        <input
          className="textinput"
          style={{
            width: 330,
            padding: 10,
            marginBottom: 10,
            borderStyle: "solid",
            borderRadius: 8,
            borderWidth: 0.5,
            borderColor: "#cccccc",
            backgroundColor: isDesktop ? "#eeeff0" : "#ffffff",
          }}
          type="text"
          value={wiki}
          placeholder={translation.company.wiki}
          maxLength="100"
          onChange={(event) => {
            setWiki(event.target.value);
          }}
        />
        <P
          style={{
            color: "#000000",
            textAlign: "left",
            margin: "auto",
            width: 350,
            padding: 5,
          }}
        >
          {translation.company.description}:
        </P>
        <textarea
          style={{
            flex: 1,
            padding: 10,
            borderRadius: 8,
            backgroundColor: isDesktop ? "#eeeff0" : "#ffffff",
            borderStyle: "solid",
            borderWidth: 0.5,
            borderColor: "#ccc",
            width: 330,
            height: 150,
            resize: "none",
          }}
          value={description}
          placeholder={translation.company.description}
          onChange={(event) => {
            setDescription(event.target.value.substring(0, 1000));
          }}
        />
        <br />
        {companyViewportVisible.type === "identify" ||
        companyViewportVisible.type === "confirm" ? (
          <div>
            <br />
            <Button
              type="solid"
              style={{
                textAlign: "center",
                margin: "auto",
                width: 315,
                backgroundColor: "#64804d",
              }}
              onClick={() => {
                setReportsViewportVisible("annual");
              }}
            >
              {`${
                companyViewportVisible.type === "confirm"
                  ? translation.company.check
                  : translation.company.update
              }` + translation.company.annual_sources}
            </Button>
            <br />
            <Button
              type="solid"
              style={{
                textAlign: "center",
                margin: "auto",
                width: 315,
                backgroundColor: "#64804d",
              }}
              onClick={() => {
                setReportsViewportVisible("sustainability");
              }}
            >
              {`${
                companyViewportVisible.type === "confirm"
                  ? translation.company.check
                  : translation.company.update
              }` + translation.company.sustainability_sources}
            </Button>
          </div>
        ) : null}
        {companyViewportVisible.type === "tabulate" ||
        companyViewportVisible.type === "confirm" ? (
          <div>
            <br />
            <Button
              type="solid"
              style={{
                textAlign: "center",
                margin: "auto",
                width: 315,
                color: "#000000",
                backgroundColor: "#c3b091",
              }}
              onClick={() => {
                setReportDataViewportVisible(true);
              }}
            >
              {`${
                companyViewportVisible.type === "confirm"
                  ? translation.company.check
                  : translation.company.update
              }` + translation.company.emissions_and_other_data}
            </Button>
          </div>
        ) : null}
        <br />
        {isRelegating ? (
          <div style={{ marginBottom: 15 }}>
            <Loading />
          </div>
        ) : null}
        {companyViewportVisible.type !== "identify" ? (
          <div
            style={{
              width: 350,
              margin: "auto",
              marginTop: -10,
              marginBottom: 15,
            }}
          >
            <i style={{ fontSize: 13 }}>
              If the data supplied for this company from the prior stage appears
              to be inaccurate or incomplete, please do not hesitate to relegate
              the company back to the previous stage for proper assessment.
            </i>
          </div>
        ) : null}
        <div style={{ display: "flex", margin: "auto", width: 355 }}>
          {companyViewportVisible.type !== "identify" ? (
            <Button
              type="solid"
              style={{
                backgroundColor: "#eb5757",
                textAlign: "center",
                width: 155,
                marginRight: 7.5,
                opacity: !isRelegating ? "100%" : "50%",
              }}
              onClick={() => {
                if (!isRelegating) {
                  relegateCompany();
                }
              }}
            >
              {translation.button.relegate}
            </Button>
          ) : null}
          <Button
            type="solid"
            style={{
              textAlign: "center",
              width: companyViewportVisible.type === "identify" ? 355 : 155,
              marginLeft: companyViewportVisible.type === "identify" ? 0 : 7.5,
              opacity: !isSubmitting && requirementsMet ? "100%" : "50%",
            }}
            onClick={() => {
              if (!isSubmitting) {
                if (requirementsMet) {
                  updateCompany();
                } else {
                  alert(
                    "Make sure you supply all of the required data before submitting!"
                  );
                }
              }
            }}
          >
            {translation.button.submit}
          </Button>
        </div>

        {!isDesktop ? <div style={{ height: 80 }} /> : null}
      </div>
      {!!reportsViewportVisible && (
        <ReportsViewport
          isDesktop={isDesktop}
          credentials={credentials}
          setCredentials={setCredentials}
          updateCredentials={updateCredentials}
          language={language}
          translation={translation}
          activePage={activePage}
          setActivePage={setActivePage}
          setSettingsViewportVisible={setSettingsViewportVisible}
          reportsViewportVisible={reportsViewportVisible}
          setReportsViewportVisible={setReportsViewportVisible}
          reportType={reportsViewportVisible}
          dataReports={
            reportsViewportVisible === "annual"
              ? annualReports
              : sustainabilityReports
          }
          setDataReports={
            reportsViewportVisible === "annual"
              ? setAnnualReports
              : setSustainabilityReports
          }
          type={companyViewportVisible.type}
          company={companyViewportVisible.company}
        />
      )}
      {!!reportDataViewportVisible && (
        <ReportDataViewport
          isDesktop={isDesktop}
          credentials={credentials}
          setCredentials={setCredentials}
          updateCredentials={updateCredentials}
          language={language}
          translation={translation}
          activePage={activePage}
          setActivePage={setActivePage}
          setSettingsViewportVisible={setSettingsViewportVisible}
          reportDataViewportVisible={reportDataViewportVisible}
          setReportDataViewportVisible={setReportDataViewportVisible}
          reportData={reportData}
          setReportData={setReportData}
          reportDataSources={reportDataSources}
          setReportDataSources={setReportDataSources}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          reports={[annualReports, sustainabilityReports]}
          type={companyViewportVisible.type}
          company={companyViewportVisible.company}
        />
      )}
      {!!uploadViewportVisible && (
        <UploadViewport
          isDesktop={isDesktop}
          credentials={credentials}
          setCredentials={setCredentials}
          updateCredentials={updateCredentials}
          language={language}
          translation={translation}
          activePage={activePage}
          setActivePage={setActivePage}
          setSettingsViewportVisible={setSettingsViewportVisible}
          uploadViewportVisible={uploadViewportVisible}
          setUploadViewportVisible={setUploadViewportVisible}
          image={image}
          setImage={setImage}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
        />
      )}
    </Viewport>
  );
};

export default CompanyViewport;

"use es6";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import Button from "../Buttons/Button";
import Viewport from "./Viewport";
import Notification from "../Notifications/Notification";
import Loading from "../Loading/Loading";
import P from "../Text/P";

const NotificationsViewport = ({
  isDesktop = null,
  children = "[insert text]",
  credentials = null,
  setCredentials = {},
  updateCredentials = {},
  language = null,
  translation = null,
  notificationsViewportVisible = false,
  setNotificationsViewportVisible = {},
}) => {
  const navigate = useNavigate();

  const [notifications, setNotifications] = useState([]);

  const [loadedNotifications, setLoadedNotifications] = useState({});
  const [deletedNotifications, setDeletedNotifications] = useState(0);

  const [renderedNotifications, setRenderedNotifications] = useState(0);
  const numLoadedNotifications = Object.keys(loadedNotifications).filter(
    (key) => {
      return loadedNotifications[key] === true;
    }
  ).length;

  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  const getNotifications = async () => {
    axios
      .get(
        `${process.env.REACT_APP_NOTIFICATIONS_API_URL}/notifications/${credentials.id}`,
        requestConfig
      )
      .then((res) => {
        const notificationList = Object.keys(res.data.notifications)
          .map((key) => [Number(key), res.data.notifications[key]])
          .sort((a, b) => b[0] - a[0]);

        setNotifications(notificationList);
        setRenderedNotifications(Math.min(notificationList.length, 5));
        console.log(notificationList);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const deleteAllNotifications = async () => {
    const requestBody = {
      deleteAllNotifications: true,
    };
    axios
      .post(
        `${process.env.REACT_APP_NOTIFICATIONS_API_URL}/notifications/${credentials.id}`,
        requestBody,
        requestConfig
      )
      .then((res) => {
        // console.log(res);
      })
      .catch((error) => {});
  };

  const loadMoreNotifications = async () => {
    setRenderedNotifications(
      Math.min(notifications.length, renderedNotifications + 3)
    );
  };

  return (
    <Viewport
      isDesktop={isDesktop}
      heading={translation.navigation.notification}
      viewportVisible={notificationsViewportVisible}
      setViewportVisible={setNotificationsViewportVisible}
    >
      <div style={{ height: 10 }} />
      <div
        style={{
          margin: "auto",
          flexWrap: "wrap",
          justifyContent: "center",
          textAlign: "left",
          maxHeight: isDesktop ? 550 : "100%",
          overflowY: "scroll",
        }}
      >
        <Button
          style={{
            color: "rgba(100, 128, 77, 0.975)",
            marginLeft: 10,
          }}
          onClick={() => {
            deleteAllNotifications();
          }}
        >
          {translation.button.clear_all}
        </Button>
        <br />
        {!isDesktop ? (
          <P
            style={{
              textAlign: "center",
              fontStyle: "italic",
              color: "#555555",
            }}
          >
            {translation.miscellaneous.use_desktop}
          </P>
        ) : null}
        <div
          style={{
            height:
              numLoadedNotifications !== renderedNotifications &&
              notifications.length > 0
                ? 0
                : 550,
            opacity:
              numLoadedNotifications !== renderedNotifications &&
              notifications.length > 0 &&
              0,
          }}
        >
          {deletedNotifications !== renderedNotifications &&
          notifications.length > 0 ? (
            notifications
              .slice(0, renderedNotifications)
              .map((notification, index) => (
                <Notification
                  key={index}
                  isDesktop={isDesktop}
                  credentials={credentials}
                  updateCredentials={updateCredentials}
                  translation={translation}
                  setNotificationsViewportVisible={
                    setNotificationsViewportVisible
                  }
                  notification={notification}
                  loadedNotifications={loadedNotifications}
                  setLoadedNotifications={setLoadedNotifications}
                  setDeletedNotifications={setDeletedNotifications}
                  notificationId={index}
                />
              ))
          ) : (
            <div
              style={{
                margin: "auto",
                width: "100%",
                height: 550,
                textAlign: "center",
                marginTop: 200,
                color: "#888888",
              }}
            >
              {translation.miscellaneous.nothing_here}
            </div>
          )}
          {renderedNotifications !== notifications.length ? (
            <Button
              style={{
                margin: "auto",
                textAlign: "center",
                color: "#555555",
              }}
              onClick={() => {
                loadMoreNotifications();
              }}
            >
              {translation.button.load_more}
            </Button>
          ) : null}
        </div>
        {numLoadedNotifications !== renderedNotifications &&
        notifications.length > 0 ? (
          <div style={{ minHeight: 550 }}>
            <Loading
              style={{
                position: "fixed",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          </div>
        ) : null}
      </div>
    </Viewport>
  );
};

export default NotificationsViewport;

"use es6";

import { useState, useEffect } from "react";
import axios from "axios";
import { Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import { useWindowDimensions } from "./utils/CustomHooks";
import bcrypt from "bcryptjs";

import jwt_decode from "jwt-decode";

import HomePage from "./pages/HomePage";
import ProfilePage from "./pages/ProfilePage";
import ExplorePage from "./pages/ExplorePage";
import CompanyPage from "./pages/CompanyPage";
import TasksPage from "./pages/TasksPage";
import PolicyPage from "./pages/PolicyPage";
import UnknownPage from "./pages/UnknownPage";

import english from "./translations/english.json";
import spanish from "./translations/spanish.json";
import french from "./translations/french.json";
import chinese from "./translations/chinese.json";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import AuthenticationViewport from "./components/Viewports/AuthenticationViewport";
import SettingsViewport from "./components/Viewports/SettingsViewport";
import EditViewport from "./components/Viewports/EditViewport";
import JoinViewport from "./components/Viewports/JoinViewport";
import PasswordViewport from "./components/Viewports/PasswordViewport";
import AboutViewport from "./components/Viewports/AboutViewport";
import NoticeViewport from "./components/Viewports/NoticeViewport";
import BadgeViewport from "./components/Viewports/BadgeViewport";
import BadgesViewport from "./components/Viewports/BadgesViewport";
import CompanyViewport from "./components/Viewports/CompanyViewport";
import NotificationsViewport from "./components/Viewports/NotificationsViewport";
import VisualizationViewport from "./components/Viewports/VisualizationViewport";

import sfh_background from "./graphics/branding/sfh_background.png";

function App() {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [isConfirmed, setIsConfirmed] = useState(true);

  const [credentials, setCredentials] = useState(null);
  const [activePage, setActivePage] = useState(
    location.pathname.substring(1) === "" ||
      location.pathname.substring(1) === "policy"
      ? ""
      : location.pathname.indexOf("tasks") !== -1
      ? "tasks"
      : location.pathname.substring(1) === "explore" ||
        location.pathname.indexOf("explore/") !== -1
      ? "explore"
      : !!credentials && location.pathname.substring(1) === credentials.username
      ? "profile"
      : ""
  );
  const [authenticationViewportVisible, setAuthenticationViewportVisible] =
    useState(false);
  const [settingsViewportVisible, setSettingsViewportVisible] = useState(false);
  const [editViewportVisible, setEditViewportVisible] = useState(false);
  const [uploadViewportVisible, setUploadViewportVisible] = useState(false);
  const [mapViewportVisible, setMapViewportVisible] = useState(false);
  const [joinViewportVisible, setJoinViewportVisible] = useState(false);
  const [passwordViewportVisible, setPasswordViewportVisible] = useState(false);
  const [aboutViewportVisible, setAboutViewportVisible] = useState(false);
  const [noticeViewportVisible, setNoticeViewportVisible] = useState(false);
  const [badgeViewportVisible, setBadgeViewportVisible] = useState(false);
  const [badgesViewportVisible, setBadgesViewportVisible] = useState(false);
  const [companyViewportVisible, setCompanyViewportVisible] = useState(null);
  const [reportDataViewportVisible, setReportDataViewportVisible] =
    useState(null);
  const [reportsViewportVisible, setReportsViewportVisible] = useState(null);
  const [notificationsViewportVisible, setNotificationsViewportVisible] =
    useState(false);

  const [visualizationViewportVisible, setVisualizationViewportVisible] =
    useState(false);

  const [editTimestamp, setEditTimestamp] = useState(Date.now());
  const [currentBookmarks, setCurrentBookmarks] = useState({});
  const [currentReservations, setCurrentReservations] = useState({});

  const dimensions = useWindowDimensions();

  const isDesktop = dimensions.width > 980;

  const language = navigator.language;

  const translations = {
    en: english,
    es: spanish,
    fr: french,
    zh: chinese,
  };

  const translation = !!translations[language.substring(0, 2)].authentication
    ? translations[language.substring(0, 2)]
    : translations.en;

  const sitePassword =
    "$2a$10$.GfmYFi3CueBCAIG1GAPCO6VgST0YLx25ASFTV0Mfzh9umQVNuWCS";

  const getCredentials = async () => {
    const data = await JSON.parse(localStorage.getItem("sfh-credentials"));
    if (data) {
      setCredentials(data);
      setCurrentBookmarks(data.bookmarks);
    }
    refreshCredentials(credentials?.id);
  };

  const refreshCredentials = async (id) => {
    if (!!id) {
      const requestConfig = {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      };

      await axios
        .get(
          `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${id}`,
          requestConfig
        )
        .then(async (res) => {
          setCredentials(res.data);
          updateCredentials(res.data);
        });
    }
  };

  const updateCredentials = async (data) => {
    setCredentials(data);
    localStorage.setItem("sfh-credentials", JSON.stringify(data));
  };

  useEffect(() => {
    getCredentials();
    if (
      searchParams.get("action") === "signin" ||
      searchParams.get("action") === "signup"
    ) {
      setAuthenticationViewportVisible(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    setSettingsViewportVisible(false);
  }, [isDesktop]);

  return isConfirmed ? (
    <div
      style={{
        height: dimensions.height,
        margin: "auto",
        background: `url(${sfh_background})`,
        boxShadow: "inset 0 0 0 2000px rgba(238, 239, 240, 0.975)",
        backgroundRepeat: "repeat",
      }}
    >
      {!!authenticationViewportVisible && !credentials ? (
        <AuthenticationViewport
          credentials={credentials}
          setCredentials={setCredentials}
          updateCredentials={updateCredentials}
          language={language}
          translation={translation}
          activePage={activePage}
          setActivePage={setActivePage}
          authenticationViewportVisible={authenticationViewportVisible}
          setAuthenticationViewportVisible={setAuthenticationViewportVisible}
          setNoticeViewportVisible={setNoticeViewportVisible}
        />
      ) : null}
      {!!settingsViewportVisible ? (
        <SettingsViewport
          credentials={credentials}
          setCredentials={setCredentials}
          updateCredentials={updateCredentials}
          language={language}
          translation={translation}
          activePage={activePage}
          setActivePage={setActivePage}
          settingsViewportVisible={settingsViewportVisible}
          setSettingsViewportVisible={setSettingsViewportVisible}
          setJoinViewportVisible={setJoinViewportVisible}
          setPasswordViewportVisible={setPasswordViewportVisible}
          setAboutViewportVisible={setAboutViewportVisible}
          setNoticeViewportVisible={setNoticeViewportVisible}
        />
      ) : null}
      {!!editViewportVisible ? (
        <EditViewport
          isDesktop={isDesktop}
          credentials={credentials}
          setCredentials={setCredentials}
          updateCredentials={updateCredentials}
          language={language}
          translation={translation}
          activePage={activePage}
          setActivePage={setActivePage}
          setSettingsViewportVisible={setSettingsViewportVisible}
          editViewportVisible={editViewportVisible}
          setEditViewportVisible={setEditViewportVisible}
          uploadViewportVisible={uploadViewportVisible}
          setUploadViewportVisible={setUploadViewportVisible}
          mapViewportVisible={mapViewportVisible}
          setMapViewportVisible={setMapViewportVisible}
          setEditTimestamp={setEditTimestamp}
        />
      ) : null}
      {!!joinViewportVisible ? (
        <JoinViewport
          isDesktop={isDesktop}
          credentials={credentials}
          setCredentials={setCredentials}
          updateCredentials={updateCredentials}
          language={language}
          translation={translation}
          activePage={activePage}
          setActivePage={setActivePage}
          setSettingsViewportVisible={setSettingsViewportVisible}
          joinViewportVisible={joinViewportVisible}
          setJoinViewportVisible={setJoinViewportVisible}
        />
      ) : null}
      {!!passwordViewportVisible ? (
        <PasswordViewport
          isDesktop={isDesktop}
          credentials={credentials}
          setCredentials={setCredentials}
          updateCredentials={updateCredentials}
          language={language}
          translation={translation}
          activePage={activePage}
          setActivePage={setActivePage}
          setSettingsViewportVisible={setSettingsViewportVisible}
          passwordViewportVisible={passwordViewportVisible}
          setPasswordViewportVisible={setPasswordViewportVisible}
        />
      ) : null}
      {!!aboutViewportVisible ? (
        <AboutViewport
          isDesktop={isDesktop}
          credentials={credentials}
          setCredentials={setCredentials}
          updateCredentials={updateCredentials}
          language={language}
          translation={translation}
          activePage={activePage}
          setActivePage={setActivePage}
          setSettingsViewportVisible={setSettingsViewportVisible}
          aboutViewportVisible={aboutViewportVisible}
          setAboutViewportVisible={setAboutViewportVisible}
        />
      ) : null}
      {!!noticeViewportVisible ? (
        <NoticeViewport
          type={noticeViewportVisible}
          credentials={credentials}
          setCredentials={setCredentials}
          updateCredentials={updateCredentials}
          language={language}
          translation={translation}
          activePage={activePage}
          setActivePage={setActivePage}
          setSettingsViewportVisible={setSettingsViewportVisible}
          noticeViewportVisible={noticeViewportVisible}
          setNoticeViewportVisible={setNoticeViewportVisible}
        />
      ) : null}
      {!!badgeViewportVisible ? (
        <BadgeViewport
          isDesktop={isDesktop}
          credentials={credentials}
          setCredentials={setCredentials}
          updateCredentials={updateCredentials}
          language={language}
          translation={translation}
          activePage={activePage}
          setActivePage={setActivePage}
          setSettingsViewportVisible={setSettingsViewportVisible}
          badgeViewportVisible={badgeViewportVisible}
          setBadgeViewportVisible={setBadgeViewportVisible}
        />
      ) : null}
      {!!badgesViewportVisible ? (
        <BadgesViewport
          isDesktop={isDesktop}
          credentials={credentials}
          setCredentials={setCredentials}
          updateCredentials={updateCredentials}
          language={language}
          translation={translation}
          activePage={activePage}
          setActivePage={setActivePage}
          setSettingsViewportVisible={setSettingsViewportVisible}
          badgesViewportVisible={badgesViewportVisible}
          setBadgesViewportVisible={setBadgesViewportVisible}
        />
      ) : null}
      {!!companyViewportVisible ? (
        <CompanyViewport
          isDesktop={isDesktop}
          credentials={credentials}
          setCredentials={setCredentials}
          updateCredentials={updateCredentials}
          language={language}
          translation={translation}
          activePage={activePage}
          setActivePage={setActivePage}
          setSettingsViewportVisible={setSettingsViewportVisible}
          companyViewportVisible={companyViewportVisible}
          setCompanyViewportVisible={setCompanyViewportVisible}
          reportDataViewportVisible={reportDataViewportVisible}
          setReportDataViewportVisible={setReportDataViewportVisible}
          reportsViewportVisible={reportsViewportVisible}
          setReportsViewportVisible={setReportsViewportVisible}
          uploadViewportVisible={uploadViewportVisible}
          setUploadViewportVisible={setUploadViewportVisible}
        />
      ) : null}
      {!!notificationsViewportVisible ? (
        <NotificationsViewport
          isDesktop={isDesktop}
          credentials={credentials}
          setCredentials={setCredentials}
          updateCredentials={updateCredentials}
          language={language}
          translation={translation}
          activePage={activePage}
          setActivePage={setActivePage}
          notificationsViewportVisible={notificationsViewportVisible}
          setNotificationsViewportVisible={setNotificationsViewportVisible}
        />
      ) : null}
      {!!visualizationViewportVisible ? (
        <VisualizationViewport
          isDesktop={isDesktop}
          credentials={credentials}
          setCredentials={setCredentials}
          updateCredentials={updateCredentials}
          language={language}
          translation={translation}
          activePage={activePage}
          setActivePage={setActivePage}
          visualizationViewportVisible={visualizationViewportVisible}
          setVisualizationViewportVisible={setVisualizationViewportVisible}
        />
      ) : null}

      <div
        style={{
          display: isDesktop ? "flex" : "block",
          width:
            isDesktop && activePage !== "chatrooms"
              ? dimensions.width - 360
              : "100%",
        }}
      >
        <Header
          credentials={credentials}
          setCredentials={setCredentials}
          updateCredentials={updateCredentials}
          isDesktop={isDesktop}
          dimensions={dimensions}
          translation={translation}
          activePage={activePage}
          setActivePage={setActivePage}
          setAuthenticationViewportVisible={setAuthenticationViewportVisible}
          setSettingsViewportVisible={setSettingsViewportVisible}
          setJoinViewportVisible={setJoinViewportVisible}
          setPasswordViewportVisible={setPasswordViewportVisible}
          setAboutViewportVisible={setAboutViewportVisible}
          setNoticeViewportVisible={setNoticeViewportVisible}
          setNotificationsViewportVisible={setNotificationsViewportVisible}
        />
        <div
          style={{
            marginTop: isDesktop ? 0 : 48,
            marginLeft: isDesktop ? 248 : 0,
          }}
        >
          <Routes>
            <Route
              path="/"
              element={
                <HomePage
                  isDesktop={isDesktop}
                  credentials={credentials}
                  setCredentials={setCredentials}
                  updateCredentials={updateCredentials}
                  dimensions={dimensions}
                  translation={translation}
                  activePage={activePage}
                  setActivePage={setActivePage}
                />
              }
            />
            <Route
              path="/explore"
              element={
                <ExplorePage
                  isDesktop={isDesktop}
                  credentials={credentials}
                  setCredentials={setCredentials}
                  updateCredentials={updateCredentials}
                  dimensions={dimensions}
                  translation={translation}
                  activePage={activePage}
                  setActivePage={setActivePage}
                  currentBookmarks={currentBookmarks}
                  setCurrentBookmarks={setCurrentBookmarks}
                  setCompanyViewportVisible={setCompanyViewportVisible}
                />
              }
            />
            <Route path="/explore">
              <Route
                path=":company_slug"
                element={
                  <CompanyPage
                    isDesktop={isDesktop}
                    credentials={credentials}
                    setCredentials={setCredentials}
                    updateCredentials={updateCredentials}
                    dimensions={dimensions}
                    translation={translation}
                    activePage={activePage}
                    setActivePage={setActivePage}
                    setCompanyViewportVisible={setCompanyViewportVisible}
                    visualizationViewportVisible={visualizationViewportVisible}
                    setVisualizationViewportVisible={
                      setVisualizationViewportVisible
                    }
                  />
                }
              />
            </Route>
            <Route
              path="/policy"
              element={
                <PolicyPage
                  isDesktop={isDesktop}
                  credentials={credentials}
                  setCredentials={setCredentials}
                  updateCredentials={updateCredentials}
                  dimensions={dimensions}
                  translation={translation}
                  activePage={activePage}
                  setActivePage={setActivePage}
                  setCompanyViewportVisible={setCompanyViewportVisible}
                />
              }
            />
            <Route
              path=":username"
              element={
                !!credentials ? (
                  <ProfilePage
                    isDesktop={isDesktop}
                    credentials={credentials}
                    setCredentials={setCredentials}
                    updateCredentials={updateCredentials}
                    dimensions={dimensions}
                    translation={translation}
                    activePage={activePage}
                    setActivePage={setActivePage}
                    setEditViewportVisible={setEditViewportVisible}
                    setJoinViewportVisible={setJoinViewportVisible}
                    setPasswordViewportVisible={setPasswordViewportVisible}
                    setAboutViewportVisible={setAboutViewportVisible}
                    setNoticeViewportVisible={setNoticeViewportVisible}
                    setBadgeViewportVisible={setBadgeViewportVisible}
                    setBadgesViewportVisible={setBadgesViewportVisible}
                    setCompanyViewportVisible={setCompanyViewportVisible}
                    editTimestamp={editTimestamp}
                    currentBookmarks={currentBookmarks}
                    setCurrentBookmarks={setCurrentBookmarks}
                    currentReservations={currentReservations}
                    setCurrentReservations={setCurrentReservations}
                  />
                ) : (
                  <AuthenticationViewport
                    credentials={credentials}
                    setCredentials={setCredentials}
                    updateCredentials={updateCredentials}
                    language={language}
                    translation={translation}
                    activePage={activePage}
                    setActivePage={setActivePage}
                    authenticationViewportVisible={true}
                    setAuthenticationViewportVisible={
                      setAuthenticationViewportVisible
                    }
                    setNoticeViewportVisible={setNoticeViewportVisible}
                  />
                )
              }
            />
            <Route
              path="/tasks"
              element={
                <TasksPage
                  isDesktop={isDesktop}
                  credentials={credentials}
                  setCredentials={setCredentials}
                  updateCredentials={updateCredentials}
                  dimensions={dimensions}
                  translation={translation}
                  activePage={activePage}
                  setActivePage={setActivePage}
                  currentReservations={currentReservations}
                  setCurrentReservations={setCurrentReservations}
                  setCompanyViewportVisible={setCompanyViewportVisible}
                />
              }
            />
            <Route path="*" element={<UnknownPage />} />
          </Routes>
        </div>
      </div>

      {!isDesktop ? (
        <Footer
          credentials={credentials}
          dimensions={dimensions}
          translation={translation}
          activePage={activePage}
          setActivePage={setActivePage}
          setAuthenticationViewportVisible={setAuthenticationViewportVisible}
          setNotificationsViewportVisible={setNotificationsViewportVisible}
        />
      ) : null}
    </div>
  ) : (
    <input
      type="password"
      onChange={(event) => {
        if (bcrypt.compareSync(event.target.value, sitePassword)) {
          setIsConfirmed(true);
        }
      }}
    />
  );
}

export default App;

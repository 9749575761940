"use es6";

import SearchBar from "../SearchBar/SearchBar";

import P from "../../components/Text/P";

const SearchHub = ({
  isDesktop = null,
  translation = null,
  setActivePage = {},
}) => {
  return (
    <div style={{ textAlign: "center", paddingTop: 10, paddingBottom: 20 }}>
      <P style={{ fontSize: 48, margin: 10, fontWeight: 600 }}>
        Sustainable Finance Hub
      </P>
      <P style={{ fontSize: 16, margin: 10 }}>{translation.homepage.slogan}</P>
      <br />
      <div
        style={{
          display: "flex",
          margin: "auto",
          justifyContent: "center",
          height: 40,
        }}
      >
        <SearchBar
          isDesktop={isDesktop}
          translation={translation}
          browsingUsers={true}
          setActivePage={setActivePage}
        />
      </div>
    </div>
  );
};

export default SearchHub;

import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

const FinancesChart = ({ type = "", metrics = [], width, slug }) => {
  const [data, setData] = useState(metrics);
  const average = (arr) => arr.reduce((p, c) => p + c, 0) / arr.length;

  const currencyMap = {
    us: "USD",
    ca: "CAD",
    gb: "GBP",
    fr: "EUR",
    it: "EUR",
    de: "EUR",
    pt: "EUR",
    nl: "EUR",
    es: "EUR",
    ch: "CHF",
    se: "SEK",
    dk: "DKK",
    jp: "JPY",
    cn: "CNY",
    in: "INR",
    mx: "MXN",
    br: "BRL",
    au: "AUD",
    hk: "HKD",
    kr: "KRW",
    ng: "NGN",
    za: "ZAR",
  };

  const currencyCode = currencyMap[slug.split("_")[1]] || "USD";
  const PriceCurrency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode,
  });
  const Currency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const currencySign = String(Currency.format(0))
    .replace(/[0-9]/g, "")
    .replace(".", "");
  const svgRef = useRef();
  const tooltipRef = useRef();

  useEffect(() => {
    const w = width;
    const h = (width * 9) / 16;
    const svg = d3
      .select(svgRef.current)
      .attr("width", w)
      .attr("height", h)
      .style("overflow", "visible")
      .style("background", "#000000");

    const xScale = d3
      .scaleLinear()
      .domain([0, type === "price" ? data.length : data.length - 1])
      .range([0, w]);
    const yScale = d3
      .scaleLinear()
      .domain([
        Math.min(...data) - average(data) / 10,
        Math.max(...data) + average(data) / 10,
      ])
      .range([h, 0]);

    const generateScaledLine = d3
      .line()
      .x((_, i) => xScale(i))
      .y(yScale)
      .curve(d3.curveLinear);

    const xAxis = d3
      .axisBottom(xScale)
      .ticks(type === "price" ? 4 : metrics.length - 2, 2 + data.length)
      .tickFormat((_, i) =>
        type === "price"
          ? new Date(
              Date.now() - 2592000000 + 10 * i * 86400000
            ).toLocaleDateString("en-US", { day: "numeric", month: "short" })
          : i + 2018
      );

    const formatYAxis = (d) => {
      if (type === "price") return PriceCurrency.format(d);
      if (type === "is_avg_num_sh_for_eps") {
        const num = parseInt(Currency.format(d).replace(/[^0-9]/g, ""), 10);
        return num >= 1e9
          ? `${num / 1e9}B`
          : num >= 1e6
          ? `${num / 1e6}M`
          : num;
      }
      const num = parseInt(Currency.format(d).replace(/[^0-9]/g, ""), 10);
      return num >= 1e9
        ? `$${num / 1e9}B`
        : num >= 1e6
        ? `$${num / 1e6}M`
        : `$${num}`;
    };

    const yAxis = d3.axisLeft(yScale).ticks(7).tickFormat(formatYAxis);

    svg
      .append("g")
      .call(xAxis)
      .attr("transform", `translate(0,${h})`)
      .attr("font-family", "serif")
      .style("font-family", "serif");

    svg
      .append("g")
      .call(yAxis)
      .attr("font-family", "serif")
      .style("font-family", "serif");

    svg
      .selectAll(".line")
      .data([data])
      .join("path")
      .attr("d", generateScaledLine)
      .attr("fill", "none")
      .attr("stroke", "#ffffff");

    // Tooltip setup
    const tooltip = d3.select(tooltipRef.current);

    svg
      .selectAll(".point")
      .data(data)
      .join("circle")
      .attr("class", "point")
      .attr("cx", (_, i) => xScale(i))
      .attr("cy", yScale)
      .attr("r", 3) // Smaller radius for points
      .attr("fill", "#ffffff")
      .on("mouseover", (event, d) => {
        tooltip.style("visibility", "visible").text(PriceCurrency.format(d));
      })
      .on("mousemove", (event) => {
        tooltip
          .style("top", `${event.pageY - 10}px`)
          .style("left", `${event.pageX + 10}px`);
      })
      .on("mouseout", () => {
        tooltip.style("visibility", "hidden");
      });
  }, [data, type, width]);

  return (
    <div>
      <svg
        ref={svgRef}
        style={{
          marginLeft:
            type === "price"
              ? 45 +
                Math.floor(Math.max(...data)).toString().length * 5 +
                currencySign.length * 7.5
              : 30,
          marginRight: type === "price" ? 20 : 0,
          marginBottom: type !== "price" ? 40 : 0,
          display: "block",
          fontFamily: "serif",
          color: "#ffffff",
        }}
      ></svg>
      <div
        ref={tooltipRef}
        style={{
          position: "absolute",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          color: "#ffffff",
          padding: "5px",
          borderRadius: "3px",
          visibility: "hidden",
          pointerEvents: "none",
        }}
      ></div>
    </div>
  );
};

export default FinancesChart;

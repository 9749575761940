"use es6";

const A = ({
  children = "[insert text]",
  href = "",
  style = {},
  ...buttonProps
}) => {
  const openInNewTab = () => {
    window.open(href, "_blank", "noreferrer");
  };

  return (
    <p
      style={{
        cursor: "pointer",
        display: "inline",
        color: "#64804d",
        textDecoration: "underline",
        ...style,
      }}
      {...buttonProps}
      onMouseOver={({ currentTarget }) => {
        currentTarget.style.opacity = "50%";
      }}
      onMouseOut={({ currentTarget }) => {
        currentTarget.style.opacity = "100%";
      }}
      onClick={() => {
        openInNewTab();
      }}
    >
      {children}
    </p>
  );
};

export default A;

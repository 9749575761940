"use es6";

import React, { useState, useEffect, useRef, useMemo } from "react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  useMapEvents,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

import { gapi } from "gapi-script";
import jwt_decode from "jwt-decode";
import Button from "../Buttons/Button";
import H1 from "../Text/H1";

import chevron from "../../graphics/icons/chevron.svg";
import H3 from "../Text/H3";
import H2 from "../Text/H2";
import P from "../Text/P";
import HR from "../Text/HR";
import Viewport from "./Viewport";
import Bold from "../Text/Bold";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const MapViewport = ({
  isDesktop = null,
  children = "[insert text]",
  credentials = null,
  setCredentials = {},
  updateCredentials = {},
  language = null,
  translation = null,
  mapViewportVisible = false,
  setMapViewportVisible = {},
  city = {},
  setCity = {},
}) => {
  const navigate = useNavigate();

  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  const [map, setMap] = useState();
  const [position, setPosition] = useState(null);

  const mapRef = useRef(null);
  const markerRef = useRef(null);

  useEffect(() => {
    if (!navigator.geolocation) {
      alert("Geolocation is not supported by your browser");
      setPosition({ lat: 40.7128, lng: -74.006 });
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        setPosition({ lat: latitude, lng: longitude });
      });
    }
  }, []);

  const onClickShowMarker = () => {
    const map = mapRef.current;
    if (!map) {
      return;
    }

    const marker = markerRef.current;
    if (marker) {
    }
  };

  const DraggableMarker = () => {
    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current;

          marker.openPopup();
          // onClickShowMarker();
          if (marker != null) {
            setPosition(marker.getLatLng());
            axios
              .get(
                `https://nominatim.openstreetmap.org/reverse?format=geojson&lat=${
                  marker.getLatLng().lat
                }&lon=${marker.getLatLng().lng}`
              )
              .then((res) => {
                const city_name = !res.data.features
                  ? null
                  : res.data.features[0].properties.address.city;
                const town_name = !res.data.features
                  ? null
                  : res.data.features[0].properties.address.town;
                const village_name = !res.data.features
                  ? null
                  : res.data.features[0].properties.address.village;
                const county_name = !res.data.features
                  ? null
                  : res.data.features[0].properties.address.county;
                const hamlet_name = !res.data.features
                  ? null
                  : res.data.features[0].properties.address.hamlet;
                const state_name = !res.data.features
                  ? null
                  : res.data.features[0].properties.address.state;

                const country_code = !res.data.features
                  ? null
                  : res.data.features[0].properties.address.country_code.toUpperCase();
                setCity({
                  name: `${
                    village_name ||
                    town_name ||
                    city_name ||
                    county_name ||
                    hamlet_name ||
                    ""
                  }${country_code === "US" ? ", " + state_name + ", " : ", "}${
                    !!country_code ? " " + country_code : "Unknown"
                  }`,
                  lat: position.lat,
                  lng: position.lng,
                });
              });
          }
        },
      }),
      []
    );

    return (
      <Marker
        draggable={true}
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}
      >
        <Popup>{city.name}</Popup>
      </Marker>
    );
  };

  return (
    <Viewport
      isDesktop={isDesktop}
      heading={translation.profile.set_hometown}
      viewportVisible={mapViewportVisible}
      setViewportVisible={setMapViewportVisible}
    >
      <div
        style={{
          textAlign: "center",
          maxHeight: 550,
          overflowY: "scroll",
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        <div>
          <br />
          <div
            style={{
              borderColor: "#101010",
              borderWidth: 1,
              borderStyle: "solid",
              borderRadius: 8,
              padding: 5,
            }}
          >
            {!!position ? (
              <MapContainer
                whenCreated={(map) => {
                  mapRef.current = map;
                }}
                center={position}
                zoom={14}
                scrollWheelZoom={false}
                style={{
                  height: 400,
                  width: "100%",
                }}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <DraggableMarker map={map} />
              </MapContainer>
            ) : (
              <div
                style={{
                  height: 400,
                  width: "100%",
                  backgroundColor: "#f4f0e8",
                }}
              >
                <Bold
                  style={{ position: "relative", top: "45%", margin: "auto" }}
                >
                  {translation.navigation.loading}
                </Bold>

                <P>
                  {
                    translation.miscellaneous
                      .not_fully_supported_on_all_browsers
                  }
                </P>
              </div>
            )}
          </div>
          <br />
          <Bold style={{ color: !!city.name ? "#000000" : "#888888" }}>
            {city.name || translation.miscellaneous.drag_pin}
          </Bold>
          <br />
          <Button
            type="solid"
            style={{ width: 315, margin: "auto" }}
            onClick={() => {
              setMapViewportVisible(false);
            }}
          >
            {translation.button.ok}
          </Button>
        </div>
      </div>
    </Viewport>
  );
};

export default MapViewport;

"use es6";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";

import { gapi } from "gapi-script";
import jwt_decode from "jwt-decode";
import Button from "../Buttons/Button";
import H1 from "../Text/H1";

import swap from "../../graphics/icons/swap.svg";
import H3 from "../Text/H3";
import H2 from "../Text/H2";
import P from "../Text/P";
import HR from "../Text/HR";
import Viewport from "./Viewport";

import countries from "../../translations/countries.json";
import Bold from "../Text/Bold";
import ReportDataCell from "../Company/ReportDataCell";

const DifferencesViewport = ({
  isDesktop = null,
  credentials = null,
  updateCredentials = {},
  translation = null,
  differencesViewportVisible = false,
  setDifferencesViewportVisible = {},
  sendAcceptance = {},
  sendRejection = {},
  deleteNotification = {},
  notification = {},
  currentData = {},
  newData = {},
  loading = false,
  setLoading = {},
}) => {
  const navigate = useNavigate();

  const [newCountry, setNewCountry] = useState("");

  const [isSwapping, setIsSwapping] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  const deepEqual = (x, y) => {
    const ok = Object.keys,
      tx = typeof x,
      ty = typeof y;
    return x && y && tx === "object" && tx === ty
      ? ok(x).length === ok(y).length &&
          ok(x).every((key) => deepEqual(x[key], y[key]))
      : x === y;
  };

  const rows1 = [
    "ghg_scope_1",
    "ghg_scope_2_location_based",
    "ghg_scope_3",
    "scope_3_purch_goods_srvcs",
    "scope_3_capital_goods",
    "scope_3_fuel_enrg_relatd_act",
    "scope_3_upstream_trans_dist",
    "scope_3_waste_genrtd_in_op",
    "scope_3_business_trvl_emissions",
    "scope_3_employee_commuting",
    "scope_3_upstream_leased_assets",
    "scope_3_dwnstrm_trans_dist",
    "scope_3_prcss_of_sold_prods",
    "scope_3_use_sold_products",
    "scope_3_eol_trtmnt_prods",
    "scope_3_dwnstrm_lease_assts",
    "scope_3_franchises",
    "scope_3_investments",
    "scope_3_emissions_other",
  ];

  const rows2 = [
    "enterprise_value",
    "is_comp_sales",
    "historical_market_cap",
    "is_avg_num_sh_for_eps",
    "px_last",
    "short_and_long_term_debt",
    "cash_and_marketable_securities",
    "bs_tot_asset",
  ];

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <Viewport
      isDesktop={isDesktop}
      heading={
        !!currentData
          ? translation.company.proposed_edits + " (" + currentData.slug + ")"
          : translation.company.new_company + " (" + newData.slug + ")"
      }
      viewportVisible={differencesViewportVisible}
      setViewportVisible={setDifferencesViewportVisible}
    >
      <div style={{ height: 10 }} />
      <div
        style={{
          padding: 10,
          margin: "auto",
          justifyContent: "center",
          textAlign: "center",
          maxHeight: isDesktop ? 550 : "100%",
          overflowY: "scroll",
        }}
      >
        <div style={{ display: "flex", width: "100%" }}>
          {!!currentData && !!currentData.id ? (
            <div
              style={{
                width: "50%",
                textAlign: "left",
                maxHeight: 500,
                overflowY: "scroll",
                maxWidth: 500,
              }}
            >
              <H3
                style={{
                  fontWeight: 600,
                  fontStyle: "italic",
                  marginBottom: 10,
                }}
              >
                Current Data:
              </H3>
              <img
                src={currentData.image}
                alt={`${currentData.name}'s logo.`}
                style={{
                  marginTop: 20,
                  marginBottom: 10,
                  borderWidth: 1,
                  borderColor: "#101010",
                  borderStyle: "solid",
                  margin: "auto",
                  maxWidth: 160,
                  maxHeight: 80,
                  padding: 5,
                  backgroundColor: "#ffffff",
                  objectFit: "cover",
                }}
              />
              <HR />
              <div style={{ textAlign: "left", marginRight: 5 }}>
                <P>
                  <span style={{ fontWeight: 600 }}>Name:</span>&nbsp;
                  {currentData.name}
                </P>
                <HR />
                <P>
                  <span style={{ fontWeight: 600 }}>Ticker:</span>&nbsp;
                  {currentData.ticker}
                </P>
                <HR />
                <P>
                  <span style={{ fontWeight: 600 }}>Slug:</span>&nbsp;
                  {currentData.slug}
                </P>
                <HR />
                <P>
                  <span style={{ fontWeight: 600 }}>Website:</span>&nbsp;
                  {currentData.website}
                </P>
                <HR />
                <P>
                  <span style={{ fontWeight: 600 }}>Wiki:</span>&nbsp;
                  {currentData.wiki}
                </P>
                <HR />
                <P>
                  <span style={{ fontWeight: 600 }}>Description:</span>&nbsp;
                  {currentData.description}
                </P>
                <HR />
                <P style={{ fontWeight: 600, fontStyle: "italic" }}>Reports</P>
                <P style={{ marginLeft: 10 }}>
                  <span
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    Annual:
                  </span>
                  <div
                    style={{
                      width: "100%",
                      maxWidth: 1000,
                      overflowX: "scroll",
                    }}
                  >
                    <table id="companydata-dark" style={{ fontSize: 11 }}>
                      <tr>
                        <th style={{ width: "1%" }}>Year</th>
                        <th>Document Link</th>
                      </tr>
                      {Object.keys(currentData.reports.annual)
                        .reverse()
                        .map((year, index) => (
                          <tr key={index}>
                            <td style={{ opacity: "50%" }}>{year}</td>
                            <td>
                              <input
                                readonly
                                type="text"
                                value={currentData.reports.annual[year]}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  backgroundColor: "rgba(0,0,0,0)",
                                  borderWidth: 0,
                                  fontSize: 11,
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                    </table>
                  </div>
                </P>
                <P style={{ marginLeft: 10 }}>
                  <span
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    Sustainability:
                  </span>
                  <div
                    style={{
                      width: "100%",
                      maxWidth: 1000,
                      overflowX: "scroll",
                    }}
                  >
                    <table id="companydata-dark" style={{ fontSize: 11 }}>
                      <tr>
                        <th style={{ width: "1%" }}>Year</th>
                        <th>Document Link</th>
                      </tr>
                      {Object.keys(currentData.reports.sustainability)
                        .reverse()
                        .map((year, index) => (
                          <tr key={index}>
                            <td style={{ opacity: "50%" }}>{year}</td>
                            <td>
                              <input
                                readonly
                                type="text"
                                value={currentData.reports.sustainability[year]}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  backgroundColor: "rgba(0,0,0,0)",
                                  borderWidth: 0,
                                  fontSize: 11,
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                    </table>
                  </div>
                </P>
                <br />
              </div>
              <HR />
              <div style={{ margin: 5 }}>
                <P>
                  <span
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    GHG Emissions & Other Data:
                  </span>
                </P>
                <div
                  style={{
                    textAlign: "left",
                    maxHeight: isDesktop ? 450 : null,
                    overflowY: "scroll",
                    margin: 10,
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      maxWidth: 1000,
                      overflowX: "scroll",
                    }}
                  >
                    <table id="companydata-dark" style={{ fontSize: 11 }}>
                      <tr>
                        <th style={{ width: "1%" }}>Year</th>
                        <th>Reference Document</th>
                        <th>Scope 1</th>
                        <th>Scope 2 Location-Based</th>
                        <th>Scope 3 Total</th>
                        <th>
                          Scope 3 Category 1: purchased goods and services
                        </th>
                        <th>Scope 3 Category 2: capital goods</th>
                        <th>
                          Scope 3 Category 3: fuel- and energy-related
                          activities
                        </th>
                        <th>
                          Scope 3 Category 4: upstream transportation and
                          distribution
                        </th>
                        <th>
                          Scope 3 Category 5: waste generated in operations
                        </th>
                        <th>Scope 3 Category 6: business travel</th>
                        <th>Scope 3 Category 7: employee commuting</th>
                        <th>Scope 3 Category 8: upstream leased assets</th>
                        <th>
                          Scope 3 Category 9: downstream transportation and
                          distribution
                        </th>
                        <th>
                          Scope 3 Category 10: processing of sold products
                        </th>
                        <th>Scope 3 Category 11: use of sold products</th>
                        <th>
                          Scope 3 Category 12: end-of-life treatment of sold
                          products
                        </th>
                        <th>Scope 3 Category 13: downstream leased assets</th>
                        <th>Scope 3 Category 14: franchises</th>
                        <th>Scope 3 Category 15: investments</th>
                        <th>Scope 3 Other</th>
                      </tr>
                      {Object.keys(currentData.reportdata)
                        .reverse()
                        .map((year, index) => (
                          <tr key={index}>
                            <td style={{ opacity: "50%" }}>{year}</td>
                            <td style={{ color: "#90d1fc" }}>
                              {!!currentData.reports.sustainability[year] ? (
                                <div
                                  style={{
                                    cursor: "pointer",
                                    display: "inline",
                                    color: "#000000",
                                    textDecoration: "underline",
                                  }}
                                  onMouseOver={({ currentTarget }) => {
                                    currentTarget.style.opacity = "50%";
                                  }}
                                  onMouseOut={({ currentTarget }) => {
                                    currentTarget.style.opacity = "100%";
                                  }}
                                  onClick={() => {
                                    if (
                                      !!currentData.reports.sustainability[year]
                                    ) {
                                      openInNewTab(
                                        currentData.reports.sustainability[year]
                                      );
                                    }
                                  }}
                                >
                                  Document Link
                                </div>
                              ) : null}
                            </td>
                            {rows1.map((variable, index) => (
                              <td key={index}>
                                <ReportDataCell
                                  translation={translation}
                                  reportData={currentData.reportdata}
                                  newReportData={currentData.reportdata}
                                  setNewReportData={currentData.reportdata}
                                  newReportDataSources={
                                    currentData.reportdatasources
                                  }
                                  setNewReportDataSources={
                                    currentData.reportdatasources
                                  }
                                  variable={variable}
                                  year={year}
                                  reports={currentData.reports.sustainability}
                                  type={"confirm"}
                                />
                              </td>
                            ))}
                          </tr>
                        ))}
                    </table>
                  </div>
                  <br />
                  <div
                    style={{
                      width: "100%",
                      maxWidth: 1000,
                      overflowX: "scroll",
                    }}
                  >
                    <table id="companydata-dark" style={{ fontSize: 11 }}>
                      <tr>
                        <th style={{ width: "1%" }}>Year</th>
                        <th>Reference Document</th>
                        <th>Enterprise Value ($)</th>
                        <th>Fiscal Revenue ($)</th>
                        <th>Market Cap ($)</th>
                        <th>Shares Outstanding</th>
                        <th>Annual Closing Share Price ($)</th>
                        <th>Short & Long Term Debt ($)</th>
                        <th>Cash On-Hand ($)</th>
                        <th>Total Assets ($)</th>
                      </tr>
                      {Object.keys(currentData.reportdata)
                        .reverse()
                        .map((year, index) => (
                          <tr key={index}>
                            <td style={{ opacity: "50%", width: 80 }}>
                              {year}
                            </td>
                            <td style={{ color: "#90d1fc", width: "400px" }}>
                              {!!currentData.reports.annual[year] ? (
                                <div
                                  style={{
                                    cursor: "pointer",
                                    display: "inline",
                                    color: "#000000",
                                    textDecoration: "underline",
                                  }}
                                  onMouseOver={({ currentTarget }) => {
                                    currentTarget.style.opacity = "50%";
                                  }}
                                  onMouseOut={({ currentTarget }) => {
                                    currentTarget.style.opacity = "100%";
                                  }}
                                  onClick={() => {
                                    if (!!currentData.reports.annual[year]) {
                                      openInNewTab(
                                        currentData.reports.annual[year]
                                      );
                                    }
                                  }}
                                >
                                  Document Link
                                </div>
                              ) : null}
                            </td>
                            {rows2.map((variable, index) => (
                              <td key={index}>
                                <ReportDataCell
                                  translation={translation}
                                  reportData={currentData.reportdata}
                                  newReportData={currentData.reportdata}
                                  setNewReportData={currentData.reportdata}
                                  newReportDataSources={
                                    currentData.reportdatasources
                                  }
                                  setNewReportDataSources={
                                    currentData.reportdatasources
                                  }
                                  variable={variable}
                                  year={year}
                                  reports={currentData.reports.annual}
                                  type={"confirm"}
                                />
                              </td>
                            ))}
                          </tr>
                        ))}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div
            style={{
              width: "50%",
              textAlign: "left",
              maxHeight: 500,
              overflowY: "scroll",
              maxWidth: 500,
            }}
          >
            <div style={{ display: "flex" }}>
              <H3
                style={{
                  fontWeight: 600,
                  fontStyle: "italic",
                  marginBottom: 10,
                }}
              >
                Proposed Data:
              </H3>
              <span style={{ color: "#bca444", margin: 4 }}>*edited</span>
            </div>
            <img
              src={newData.image}
              alt={`${newData.name}'s logo.`}
              style={{
                marginTop: 20,
                marginBottom: 10,
                borderWidth: 1,
                borderColor:
                  !currentData || currentData.image !== newData.image
                    ? "#bca444"
                    : "#101010",
                borderStyle: "solid",
                margin: "auto",
                maxWidth: 160,
                maxHeight: 80,
                padding: 5,
                backgroundColor: "#ffffff",
                objectFit: "cover",
              }}
            />
            <HR />
            <div style={{ textAlign: "left", marginLeft: 5 }}>
              <P>
                <span
                  style={{
                    fontWeight: 600,
                    color:
                      !currentData || currentData.name !== newData.name
                        ? "#bca444"
                        : "#000000",
                  }}
                >
                  Name:
                </span>
                &nbsp;
                {newData.name}
              </P>
              <HR />
              <P>
                <span
                  style={{
                    fontWeight: 600,
                    color:
                      !currentData || currentData.ticker !== newData.ticker
                        ? "#bca444"
                        : "#000000",
                  }}
                >
                  Ticker:
                </span>
                &nbsp;
                {newData.ticker}
              </P>
              <HR />
              <P>
                <span
                  style={{
                    fontWeight: 600,
                    color:
                      !currentData || currentData.slug !== newData.slug
                        ? "#bca444"
                        : "#000000",
                  }}
                >
                  Slug:
                </span>
                &nbsp;
                {newData.slug}
              </P>
              <HR />
              <P>
                <span
                  style={{
                    fontWeight: 600,
                    color:
                      !currentData || currentData.website !== newData.website
                        ? "#bca444"
                        : "#000000",
                  }}
                >
                  Website:
                </span>
                &nbsp;
                {newData.website}
              </P>
              <HR />
              <P>
                <span
                  style={{
                    fontWeight: 600,
                    color:
                      !currentData || currentData.wiki !== newData.wiki
                        ? "#bca444"
                        : "#000000",
                  }}
                >
                  Wiki:
                </span>
                &nbsp;
                {newData.wiki}
              </P>
              <HR />
              <P>
                <span
                  style={{
                    fontWeight: 600,
                    color:
                      !currentData ||
                      currentData.description !== newData.description
                        ? "#bca444"
                        : "#000000",
                  }}
                >
                  Description:
                </span>
                &nbsp;
                {newData.description}
              </P>
              <HR />
              <P style={{ fontWeight: 600, fontStyle: "italic" }}>Reports</P>
              <P style={{ marginLeft: 10 }}>
                <span
                  style={{
                    fontWeight: 600,
                    color:
                      !currentData ||
                      !deepEqual(
                        currentData.reports.annual,
                        newData.reports.annual
                      )
                        ? "#bca444"
                        : "#000000",
                  }}
                >
                  Annual:
                </span>
                <div
                  style={{ width: "100%", maxWidth: 1000, overflowX: "scroll" }}
                >
                  <table id="companydata-dark" style={{ fontSize: 11 }}>
                    <tr>
                      <th style={{ width: "1%" }}>Year</th>
                      <th>Document Link</th>
                    </tr>
                    {Object.keys(newData.reports.annual)
                      .reverse()
                      .map((year, index) => (
                        <tr key={index}>
                          <td style={{ opacity: "50%" }}>{year}</td>
                          <td>
                            <input
                              readonly
                              type="text"
                              value={newData.reports.annual[year]}
                              style={{
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(0,0,0,0)",
                                borderWidth: 0,
                                fontSize: 11,
                                color:
                                  !currentData.reports.annual ||
                                  !currentData.reports.annual[year] ||
                                  currentData.reports.annual[year] !==
                                    newData.reports.annual[year]
                                    ? "#bca444"
                                    : "#000000",
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                  </table>
                </div>
              </P>
              <P style={{ marginLeft: 10 }}>
                <span
                  style={{
                    fontWeight: 600,
                    color:
                      !currentData ||
                      !deepEqual(
                        currentData.reports.sustainability,
                        newData.reports.sustainability
                      )
                        ? "#bca444"
                        : "#000000",
                  }}
                >
                  Sustainability:
                </span>
                <div
                  style={{ width: "100%", maxWidth: 1000, overflowX: "scroll" }}
                >
                  <table id="companydata-dark" style={{ fontSize: 11 }}>
                    <tr>
                      <th style={{ width: "1%" }}>Year</th>
                      <th>Document Link</th>
                    </tr>
                    {Object.keys(newData.reports.sustainability)
                      .reverse()
                      .map((year, index) => (
                        <tr key={index}>
                          <td style={{ opacity: "50%" }}>{year}</td>
                          <td>
                            <input
                              readonly
                              type="text"
                              value={newData.reports.sustainability[year]}
                              style={{
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(0,0,0,0)",
                                borderWidth: 0,
                                fontSize: 11,
                                color:
                                  !currentData.reports.sustainability ||
                                  !currentData.reports.sustainability[year] ||
                                  currentData.reports.sustainability[year] !==
                                    newData.reports.sustainability[year]
                                    ? "#bca444"
                                    : "#000000",
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                  </table>
                </div>
              </P>
              <br />
            </div>
            <HR />
            <div style={{ margin: 5 }}>
              <P>
                <span
                  style={{
                    fontWeight: 600,
                    color:
                      !currentData ||
                      !deepEqual(currentData.reportdata, newData.reportdata)
                        ? "#bca444"
                        : "#000000",
                  }}
                >
                  GHG Emissions & Other Data:
                </span>
              </P>
              <div
                style={{
                  textAlign: "left",
                  maxHeight: isDesktop ? 450 : null,
                  overflowY: "scroll",
                  margin: 10,
                }}
              >
                <div
                  style={{ width: "100%", maxWidth: 1000, overflowX: "scroll" }}
                >
                  <table id="companydata-dark" style={{ fontSize: 11 }}>
                    <tr>
                      <th style={{ width: "1%" }}>Year</th>
                      <th>Reference Document</th>
                      <th>Scope 1</th>
                      <th>Scope 2 Location-Based</th>
                      <th>Scope 3 Total</th>
                      <th>Scope 3 Category 1: purchased goods and services</th>
                      <th>Scope 3 Category 2: capital goods</th>
                      <th>
                        Scope 3 Category 3: fuel- and energy-related activities
                      </th>
                      <th>
                        Scope 3 Category 4: upstream transportation and
                        distribution
                      </th>
                      <th>Scope 3 Category 5: waste generated in operations</th>
                      <th>Scope 3 Category 6: business travel</th>
                      <th>Scope 3 Category 7: employee commuting</th>
                      <th>Scope 3 Category 8: upstream leased assets</th>
                      <th>
                        Scope 3 Category 9: downstream transportation and
                        distribution
                      </th>
                      <th>Scope 3 Category 10: processing of sold products</th>
                      <th>Scope 3 Category 11: use of sold products</th>
                      <th>
                        Scope 3 Category 12: end-of-life treatment of sold
                        products
                      </th>
                      <th>Scope 3 Category 13: downstream leased assets</th>
                      <th>Scope 3 Category 14: franchises</th>
                      <th>Scope 3 Category 15: investments</th>
                      <th>Scope 3 Other</th>
                    </tr>
                    {Object.keys(newData.reportdata)
                      .reverse()
                      .map((year, index) => (
                        <tr key={index}>
                          <td style={{ opacity: "50%" }}>{year}</td>
                          <td style={{ color: "#90d1fc" }}>
                            {!!newData.reports.sustainability &&
                            !!newData.reports.sustainability[year] ? (
                              <div
                                style={{
                                  cursor: "pointer",
                                  display: "inline",
                                  color:
                                    currentData.reports.sustainability[year] !==
                                    newData.reports.sustainability[year]
                                      ? "#bca444"
                                      : "#000000",
                                  textDecoration: "underline",
                                }}
                                onMouseOver={({ currentTarget }) => {
                                  currentTarget.style.opacity = "50%";
                                }}
                                onMouseOut={({ currentTarget }) => {
                                  currentTarget.style.opacity = "100%";
                                }}
                                onClick={() => {
                                  if (!!newData.reports.sustainability[year]) {
                                    openInNewTab(
                                      newData.reports.sustainability[year]
                                    );
                                  }
                                }}
                              >
                                Document Link
                              </div>
                            ) : null}
                          </td>
                          {rows1.map((variable, index) => (
                            <td
                              key={index}
                              style={{
                                color:
                                  !currentData.reportdata.sustainability ||
                                  !currentData.reportdata.sustainability[
                                    year
                                  ] ||
                                  currentData.reportdata.sustainability[year][
                                    variable
                                  ] !==
                                    newData.reportdata.sustainability[year][
                                      variable
                                    ]
                                    ? "#bca444"
                                    : "#000000",
                              }}
                            >
                              <ReportDataCell
                                translation={translation}
                                reportData={newData.reportdata}
                                newReportData={newData.reportdata}
                                setNewReportData={newData.reportdata}
                                newReportDataSources={newData.reportdatasources}
                                setNewReportDataSources={
                                  newData.reportdatasources
                                }
                                variable={variable}
                                year={year}
                                reports={newData.reports.sustainability}
                                type={"confirm"}
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                  </table>
                </div>
                <br />
                <div
                  style={{ width: "100%", maxWidth: 1000, overflowX: "scroll" }}
                >
                  <table id="companydata-dark" style={{ fontSize: 11 }}>
                    <tr>
                      <th style={{ width: "1%" }}>Year</th>
                      <th>Reference Document</th>
                      <th>Enterprise Value ($)</th>
                      <th>Fiscal Revenue ($)</th>
                      <th>Market Cap ($)</th>
                      <th>Shares Outstanding</th>
                      <th>Annual Closing Share Price ($)</th>
                      <th>Short & Long Term Debt ($)</th>
                      <th>Cash On-Hand ($)</th>
                      <th>Total Assets ($)</th>
                    </tr>
                    {Object.keys(newData.reportdata)
                      .reverse()
                      .map((year, index) => (
                        <tr key={index}>
                          <td style={{ opacity: "50%", width: 80 }}>{year}</td>
                          <td style={{ color: "#90d1fc", width: "400px" }}>
                            {!!newData.reports.annual &&
                            !!newData.reports.annual[year] ? (
                              <div
                                style={{
                                  cursor: "pointer",
                                  display: "inline",
                                  color:
                                    currentData.reports.sustainability[year] !==
                                    newData.reports.sustainability[year]
                                      ? "#bca444"
                                      : "#000000",
                                  textDecoration: "underline",
                                }}
                                onMouseOver={({ currentTarget }) => {
                                  currentTarget.style.opacity = "50%";
                                }}
                                onMouseOut={({ currentTarget }) => {
                                  currentTarget.style.opacity = "100%";
                                }}
                                onClick={() => {
                                  if (!!newData.reports.annual[year]) {
                                    openInNewTab(newData.reports.annual[year]);
                                  }
                                }}
                              >
                                Document Link
                              </div>
                            ) : null}
                          </td>
                          {rows2.map((variable, index) => (
                            <td
                              key={index}
                              style={{
                                color:
                                  !currentData.reportdata.annual ||
                                  !currentData.reportdata.annual[year] ||
                                  currentData.reportdata.annual[year][
                                    variable
                                  ] !==
                                    newData.reportdata.annual[year][variable]
                                    ? "#bca444"
                                    : "#000000",
                              }}
                            >
                              <ReportDataCell
                                translation={translation}
                                reportData={newData.reportdata}
                                newReportData={newData.reportdata}
                                setNewReportData={newData.reportdata}
                                newReportDataSources={newData.reportdatasources}
                                setNewReportDataSources={
                                  newData.reportdatasources
                                }
                                variable={variable}
                                year={year}
                                reports={newData.reports.annual}
                                type={"confirm"}
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            backgroundColor: "rgba(16, 16, 16, 0.975)",
            borderRadius: 8,
            marginTop: 10,
          }}
        >
          <Button
            type="solid"
            loading={loading}
            style={{
              width: 200,
              margin: 10,
              fontSize: 14,
              paddingTop: 5,
              paddingRight: 10,
              paddingBottom: 5,
              paddingLeft: 10,
              backgroundColor: "#64804d",
              opacity: loading ? 0.5 : 1,
            }}
            onClick={() => {
              if (!loading) {
                setLoading(true);
                sendAcceptance(notification[1].sender);
                setDifferencesViewportVisible(false);
              }
            }}
          >
            {translation.notification.accept}
          </Button>
          <Button
            type="solid"
            loading={loading}
            style={{
              width: 200,
              margin: 10,
              fontSize: 14,
              paddingTop: 5,
              paddingRight: 10,
              paddingBottom: 5,
              paddingLeft: 10,
              backgroundColor: "#eb5757",
              opacity: loading ? 0.5 : 1,
            }}
            onClick={() => {
              if (!loading) {
                setLoading(true);
                sendRejection(notification[1].sender);
                setDifferencesViewportVisible(false);
              }
            }}
          >
            {translation.notification.reject}
          </Button>
        </div>
      </div>
    </Viewport>
  );
};

export default DifferencesViewport;

"use es6";

import { useState } from "react";
import Loading from "../Loading/Loading";
import Button from "../Buttons/Button";
import P from "../Text/P";
import H3 from "../Text/H3";

const CompanyNews = ({
  translation = null,
  isDesktop,
  dimensions,
  articles,
}) => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "#64804d",
        paddingTop: 10,
        color: "#ffffff",
      }}
    >
      <div
        style={{
          width: "100%",
        }}
      >
        <H3 style={{ marginLeft: 20, marginBottom: 0, padding: 0 }}>
          Related News
        </H3>
        {!!articles && articles.length > 0 ? (
          <div
            style={{
              overflowX: "scroll",
              overflowY: "hidden",
              marginTop: 5,
              marginLeft: 15,
              marginRight: 15,
              marginBottom: 15,
            }}
          >
            <div
              style={{
                display: "flex",
                height: 130,
                width: isDesktop && dimensions.width - 320,
                marginBottom: 5,
              }}
            >
              {articles.map((article, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    backgroundColor: "#ffffff",
                    borderRadius: 8,
                    margin: 5,
                  }}
                >
                  <div
                    style={{
                      display: "block",
                      width: 250,
                      margin: 5,
                    }}
                  >
                    <Button
                      onClick={() => {
                        openInNewTab(article.sourcelink);
                      }}
                    >
                      {!!article.sourceimage ? (
                        <img
                          style={{ height: 14, margin: 4 }}
                          src={article.sourceimage}
                        />
                      ) : (
                        <P
                          style={{
                            color: "#000000",
                            marginLeft: 5,
                            fontStyle: "italic",
                            marginBottom: 14,
                          }}
                        >
                          {article.sourcename}
                        </P>
                      )}
                    </Button>
                    <Button
                      onClick={() => {
                        openInNewTab(article.link);
                      }}
                    >
                      <P
                        style={{
                          float: "left",
                          clear: "left",
                          color: "#000000",
                          textDecoration: "none",
                          fontSize: 16,
                          margin: 4,
                        }}
                      >
                        {article.articletitle.length < 95
                          ? article.articletitle
                          : `${article.articletitle.substring(0, 92)}...`}
                      </P>
                      <P
                        style={{
                          fontSize: 10,
                          margin: 4,
                          color: "#606060",
                          fontStyle: "italic",
                        }}
                      >
                        {new Date(article.published).toLocaleDateString(
                          "en-US",
                          {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                          }
                        )}
                      </P>
                    </Button>
                  </div>
                  <img
                    style={{
                      height: 100,
                      borderRadius: 8,
                      margin: 10,
                      marginLeft: 0,
                    }}
                    src={
                      article.articleimage ||
                      "https://sfh-defaults.s3.amazonaws.com/blankimage.png"
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <Loading style={{ margin: 20, marginBottom: 60, float: "left" }} />
        )}
      </div>
    </div>
  );
};

export default CompanyNews;

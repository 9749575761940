"use es6";

import { useState } from "react";

const CompanyDataCell = ({
  translation = null,
  activedata,
  bloombergDataVisible,
  company,
  variable = null,
  year = null,
}) => {
  return (
    <td
      title={
        bloombergDataVisible
          ? `Sourced from historical Bloomberg data.`
          : !!company.reportdatasources[year][variable] &&
            !isNaN(company.reportdatasources[year][variable])
          ? `Sourced from page ${company.reportdatasources[year][variable]} of the ${year} ${company.name} annual financials document.`
          : !!company.reportdatasources[year][variable] &&
            isNaN(company.reportdatasources[year][variable])
          ? ""
          : "Source of datapoint not yet available."
      }
    >
      {activedata[year][variable] !== null &&
      activedata[year][variable] !== undefined ? (
        isNaN(company.reportdatasources[year][variable]) &&
        String(activedata[year][variable]).length > 5 ? (
          <div style={{ display: "flex" }}>
            {activedata[year][variable].toLocaleString()}
            <a
              style={{
                cursor: "pointer",
                display: "inline",
                color: "#64804d",
                textDecoration: "underline",
                marginLeft: 5,
              }}
              onMouseOver={({ currentTarget }) => {
                currentTarget.style.opacity = "50%";
              }}
              onMouseOut={({ currentTarget }) => {
                currentTarget.style.opacity = "100%";
              }}
              target="_blank"
              href={company.reportdatasources[year][variable]}
            >
              source
            </a>
          </div>
        ) : !!activedata[year][variable] ? (
          activedata[year][variable].toLocaleString()
        ) : (
          ""
        )
      ) : (
        ""
      )}
    </td>
  );
};

export default CompanyDataCell;

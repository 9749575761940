"use es6";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";

import { gapi } from "gapi-script";
import jwt_decode from "jwt-decode";
import Button from "../Buttons/Button";
import H1 from "../Text/H1";

import swap from "../../graphics/icons/swap.svg";
import H3 from "../Text/H3";
import P from "../Text/P";
import HR from "../Text/HR";
import Viewport from "./Viewport";

import countries from "../../translations/countries.json";
import EmissionsChart from "../Charts/EmissionsChart";
import FinancesChart from "../Charts/FinancesChart";

const VisualizationViewport = ({
  isDesktop = null,
  children = "[insert text]",
  credentials = null,
  setCredentials = {},
  updateCredentials = {},
  language = null,
  translation = null,
  visualizationViewportVisible = false,
  setVisualizationViewportVisible = {},
}) => {
  const navigate = useNavigate();

  const [newCountry, setNewCountry] = useState("");

  const [isSwapping, setIsSwapping] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  const handleSubmit = async () => {
    setIsSwapping(true);
    if (newCountry === "") {
      setIsSwapping(false);
      setErrorMessage("Please select a new country before continuing.");
      return;
    }

    setErrorMessage(null);

    const requestBody = {
      country: newCountry,
    };

    await axios
      .post(
        `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${credentials.id}`,
        requestBody,
        requestConfig
      )
      .then((res) => {
        updateCredentials(res.data.account);
        setVisualizationViewportVisible(false);
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
      });
  };

  const emissions = Object.keys(visualizationViewportVisible.activedata)
    .map((key) => {
      return {
        year: key,
        ...visualizationViewportVisible.activedata[key],
      };
    })
    .map(
      ({
        year,
        enterprise_value,
        is_comp_sales,
        historical_market_cap,
        is_avg_num_sh_for_eps,
        px_last,
        short_and_long_term_debt,
        cash_and_marketable_securities,
        bs_tot_asset,
        scope_3_purch_goods_srvcs,
        scope_3_capital_goods,
        scope_3_fuel_enrg_relatd_act,
        scope_3_upstream_trans_dist,
        scope_3_waste_genrtd_in_op,
        scope_3_business_trvl_emissions,
        scope_3_employee_commuting,
        scope_3_upstream_leased_assets,
        scope_3_dwnstrm_trans_dist,
        scope_3_prcss_of_sold_prods,
        scope_3_use_sold_products,
        scope_3_eol_trtmnt_prods,
        scope_3_dwnstrm_lease_assts,
        scope_3_franchises,
        scope_3_investments,
        scope_3_emissions_other,
        ...keepAttrs
      }) => keepAttrs
    );

  const enterprisevalue = Object.values(visualizationViewportVisible.activedata)
    .map((year) => year.enterprise_value)
    .reverse();

  const revenue = Object.values(visualizationViewportVisible.activedata)
    .map((year) => year.is_comp_sales)
    .reverse();

  const marketcap = Object.values(visualizationViewportVisible.activedata)
    .map((year) => year.historical_market_cap)
    .reverse();

  const sharesoutstanding = Object.values(
    visualizationViewportVisible.activedata
  )
    .map((year) => year.is_avg_num_sh_for_eps)
    .reverse();

  const closingprice = Object.values(visualizationViewportVisible.activedata)
    .map((year) => year.px_last)
    .reverse();

  const debt = Object.values(visualizationViewportVisible.activedata)
    .map((year) => year.short_and_long_term_debt)
    .reverse();

  const cash = Object.values(visualizationViewportVisible.activedata)
    .map((year) => year.cash_and_marketable_securities)
    .reverse();

  const totalassets = Object.values(visualizationViewportVisible.activedata)
    .map((year) => year.bs_tot_asset)
    .reverse();

  const charts = {
    enterprise_value: enterprisevalue,
    is_comp_sales: revenue,
    historical_market_cap: marketcap,
    is_avg_num_sh_for_eps: sharesoutstanding,
    px_last: closingprice,
    short_and_long_term_debt: debt,
    cash_and_marketable_securities: cash,
    bs_tot_asset: totalassets,
  };

  return (
    <Viewport
      isDesktop={isDesktop}
      heading={String(
        visualizationViewportVisible.company.ticker +
          " " +
          translation.company[visualizationViewportVisible.type]
      )}
      viewportVisible={visualizationViewportVisible}
      setViewportVisible={setVisualizationViewportVisible}
    >
      <div style={{ height: 10 }} />
      <div
        style={{
          margin: "auto",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          textAlign: "center",
          maxHeight: isDesktop ? 550 : "100%",
          overflowY: "scroll",
        }}
      >
        <div
          style={{
            margin: 15,
            borderRadius: 8,
            overflowY: "scroll",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            backgroundColor: "rgba(16, 16, 16, 0.975)",
            width: "100%",
            marginBottom: isDesktop ? 0 : 80,
          }}
        >
          {visualizationViewportVisible.type === "ghg_emissions" ? (
            <div style={{ color: "#ffffff" }}>
              <br />
              <P
                style={{
                  padding: 0,
                  marginLeft: 10,
                  marginBottom: -10,
                  fontSize: 20,
                  fontStyle: "italic",
                }}
              >
                GHG Emissions
              </P>

              <EmissionsChart
                metrics={emissions}
                width={isDesktop ? 450 : 250}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <P style={{ marginLeft: 15, marginRight: 2 }}>Scope 1</P>
                <div
                  style={{
                    backgroundColor: "#777777",
                    width: 10,
                    height: 10,
                    margin: 3,
                  }}
                />
                <P style={{ marginLeft: 15, marginRight: 2 }}>Scope 2</P>
                <div
                  style={{
                    backgroundColor: "#c3b091",
                    width: 10,
                    height: 10,
                    margin: 3,
                  }}
                />
                <P style={{ marginLeft: 15, marginRight: 2 }}>Scope 3</P>
                <div
                  style={{
                    backgroundColor: "#64804d",
                    width: 10,
                    height: 10,
                    margin: 3,
                  }}
                />
              </div>
            </div>
          ) : (
            <div
              style={{
                color: "#ffffff",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              {Object.keys(charts).map((chartkey, index) => (
                <div key={index} style={{ margin: 25 }}>
                  <P
                    style={{
                      padding: 0,
                      marginLeft: 20,
                      fontSize: isDesktop ? 20 : 15,
                      fontStyle: "italic",
                    }}
                  >
                    {translation.company[chartkey]}
                  </P>
                  <FinancesChart
                    type={chartkey}
                    metrics={charts[chartkey].reverse()}
                    width={isDesktop ? 360 : 150}
                    slug={visualizationViewportVisible.company.slug}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        <P
          style={{
            marginTop: 0,
            color: visualizationViewportVisible.isBloombergData
              ? "#2800d7"
              : "#64804d",
            fontWeight: "bold",
            fontSize: 18,
            fontStyle: "italic",
          }}
        >
          {visualizationViewportVisible.isBloombergData
            ? translation.company.bloomberg_historical
            : translation.company.community_powered}{" "}
          Data
        </P>
      </div>
    </Viewport>
  );
};

export default VisualizationViewport;

"use es6";

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import P from "../components/Text/P";
import H1 from "../components/Text/H1";
import SearchHub from "../components/SearchHub/SearchHub";
import Loading from "../components/Loading/Loading";
import Button from "../components/Buttons/Button";

import sfh_wordmark2 from "../graphics/branding/sfh_wordmark2.svg";
import H3 from "../components/Text/H3";

const HomePage = ({
  isDesktop = null,
  translation = null,
  activePage = null,
  setActivePage = {},
  dimensions = null,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div style={{ flex: 1 }}>
      {!!translation && dimensions ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            justifyContent: "center",
            width: isDesktop && dimensions.width - 290,
          }}
        >
          <div style={{ marginBottom: 20 }}>
            <SearchHub
              isDesktop={isDesktop}
              translation={translation}
              setActivePage={setActivePage}
            />
          </div>

          <div
            style={{
              textAlign: "left",
              margin: "auto",
              maxWidth: isDesktop ? 600 : dimensions.width - 20,
              marginBottom: 60,
            }}
          >
            <img
              src={sfh_wordmark2}
              style={{
                width: "100%",
                paddingBottom: isDesktop && 5,
              }}
              onClick={() => {
                navigate("/");
                setActivePage("");
              }}
            />
            <br />
            <br />
            <P style={{ marginBottom: 10 }}>
              {translation.homepage.description}
            </P>
            <H1
              style={{
                textAlign: "center",
                fontStyle: "italic",
                marginBottom: 20,
              }}
            >
              Join the cause today!
            </H1>
            <div
              style={{
                padding: 10,
                backgroundColor: "rgba(0,0,0,.85)",
                borderRadius: 8,
                color: "#ffffff",
                marginBottom: 20,
              }}
            >
              <H3 style={{ fontStyle: "italic", marginBottom: 10 }}>
                EXPLORE PAGE FYI
              </H3>
              <div style={{ marginBottom: 10 }}>
                <div style={{ display: "inline" }}>
                  <P>
                    On each company card there are colored indicators meant to
                    signify how comprehensive its respective crowdsoureced data
                    is for the most recent financial year:
                    <div
                      style={{
                        display: "inline",
                        position: "relative",
                        color: "#ed6826",
                        backgroundColor: "#ed6826",
                        borderRadius: 20,
                        top: -3,
                        left: 5,
                        padding: 5,
                        fontSize: 0,
                      }}
                    />
                    <div
                      style={{
                        display: "inline",
                        position: "relative",
                        color: "#f2c94c",
                        backgroundColor: "#f2c94c",
                        borderRadius: 20,
                        top: -3,
                        left: 10,
                        padding: 5,
                        fontSize: 0,
                      }}
                    />
                    <div
                      style={{
                        display: "inline",
                        position: "relative",
                        color: "#13ed46",
                        backgroundColor: "#13ed46",
                        borderRadius: 20,
                        top: -3,
                        left: 15,
                        padding: 5,
                        fontSize: 0,
                      }}
                    />
                    <div
                      style={{
                        display: "inline",
                        position: "relative",
                        color: "#90d1fc",
                        backgroundColor: "#90d1fc",
                        borderRadius: 20,
                        top: -3,
                        left: 20,
                        padding: 5,
                        fontSize: 0,
                      }}
                    />
                  </P>
                </div>
              </div>
              <div
                style={{
                  display: isDesktop ? "flex" : "block",
                  fontSize: 13,
                  marginBottom: 10,
                }}
              >
                <div
                  style={{
                    flex: 1,
                    padding: 15,
                    backgroundColor: "rgba(0,0,0,.85)",
                    borderRadius: 8,
                    marginRight: isDesktop && 10,
                    marginBottom: !isDesktop && 10,
                  }}
                >
                  <span style={{ color: "#ed6826", fontWeight: "600" }}>
                    Orange
                  </span>{" "}
                  indicates that the most recent financial and sustainability
                  data for a company is CONTESTED, meaning that no first-hand
                  documentation has been provided yet.
                </div>
                <div
                  style={{
                    flex: 1,
                    padding: 15,
                    backgroundColor: "rgba(0,0,0,.85)",
                    borderRadius: 8,
                    marginRight: isDesktop && 10,
                    marginBottom: !isDesktop && 10,
                  }}
                >
                  <span style={{ color: "#f2c94c", fontWeight: "600" }}>
                    Yellow
                  </span>{" "}
                  indicates that the most recent financial and sustainability
                  data for a company is IDENTIFIED, meaning that first-hand
                  documentation has been provided.
                </div>
                <div
                  style={{
                    flex: 1,
                    padding: 15,
                    backgroundColor: "rgba(0,0,0,.85)",
                    borderRadius: 8,
                    marginRight: isDesktop && 10,
                    marginBottom: !isDesktop && 10,
                  }}
                >
                  <span style={{ color: "#13ed46", fontWeight: "600" }}>
                    Green
                  </span>{" "}
                  indicates that the most recent financial and sustainability
                  data for a company is TABULATED, meaning that contextually
                  relevent numbers from the provided first-hand documentation
                  have been recorded in a table for easy access.
                </div>
                <div
                  style={{
                    flex: 1,
                    padding: 15,
                    backgroundColor: "rgba(0,0,0,.85)",
                    borderRadius: 8,
                  }}
                >
                  <span style={{ color: "#90d1fc", fontWeight: "600" }}>
                    Blue
                  </span>{" "}
                  indicates that the most recent financial and sustainability
                  data for a company is CONFIRMED, meaning that all the prior
                  information has been triple-checked for accuracy and
                  completeness.
                </div>
              </div>
              <P style={{ fontStyle: "italic" }}>
                To help keep track of your firms of interest, you can also
                bookmark up to 10 different companies. These bookmarks are then
                accessible from your profile page.
              </P>
            </div>

            <div
              style={{
                padding: 10,
                backgroundColor: "rgba(0,0,0,.85)",
                borderRadius: 8,
                color: "#ffffff",
                marginBottom: 20,
              }}
            >
              <H3 style={{ fontStyle: "italic", marginBottom: 10 }}>
                TASKS PAGE FYI
              </H3>
              <div style={{ marginBottom: 10 }}>
                <div style={{ display: "inline" }}>
                  <P>
                    The "Tasks" page serves as the crowdsourcing nexus of the
                    Sustainable Finance Hub. Here you can reserve a company for
                    one of the three stages of company data aggregation and
                    validation, contributing to the platform's mission.
                  </P>
                </div>
              </div>
              <div
                style={{
                  display: isDesktop ? "flex" : "block",
                  fontSize: 13,
                  marginRight: isDesktop && 10,
                  marginBottom: !isDesktop && 10,
                }}
              >
                <div
                  style={{
                    flex: 1,
                    padding: 15,
                    backgroundColor: "rgba(0,0,0,.85)",
                    borderRadius: 8,
                    marginRight: isDesktop && 10,
                    marginBottom: !isDesktop && 10,
                  }}
                >
                  <span style={{ color: "#f2c94c", fontWeight: "600" }}>
                    Identification
                  </span>{" "}
                  is the first stage of the crowdsourcing workflow. Here
                  contributors provide links to the first-hand documentation for
                  the company they reserved to work on.
                </div>
                <div
                  style={{
                    flex: 1,
                    padding: 15,
                    backgroundColor: "rgba(0,0,0,.85)",
                    borderRadius: 8,
                    marginRight: isDesktop && 10,
                    marginBottom: !isDesktop && 10,
                  }}
                >
                  <span style={{ color: "#13ed46", fontWeight: "600" }}>
                    Tabulation
                  </span>{" "}
                  is the second stage of the crowdsourcing workflow. Here
                  contributors use the provided first-hand documentation to
                  record contextually relevant numbers in a table for the
                  company they reserved to work on.
                </div>
                <div
                  style={{
                    flex: 1,
                    padding: 15,
                    backgroundColor: "rgba(0,0,0,.85)",
                    borderRadius: 8,
                  }}
                >
                  <span style={{ color: "#90d1fc", fontWeight: "600" }}>
                    Confirmation
                  </span>{" "}
                  is the third stage of the crowdsourcing workflow. Here
                  contributors perform a final review on accuracy and
                  completeness for the company they reserved to work on.
                </div>
              </div>
              <P style={{ fontStyle: "italic", marginTop: 10 }}>
                You can reserve up to two companies to work on at a time. If the
                data supplied for a Stage 2 or Stage 3 company appears to be
                inaccurate or incomplete, you have the ability to relegate said
                company to the previous stage for proper assessment.
              </P>
            </div>

            <br />
            <Button
              type="solid"
              style={{ textAlign: "center", marginTop: 10, marginBottom: 20 }}
              onClick={() => {
                navigate("/policy");
                setActivePage("");
              }}
            >
              {translation.navigation.privacy_policy}
            </Button>
          </div>
        </div>
      ) : (
        <Loading
          style={{
            position: "fixed",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      )}
    </div>
  );
};

export default HomePage;

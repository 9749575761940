"use es6";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

import P from "../components/Text/P";
import H2 from "../components/Text/H2";
import H3 from "../components/Text/H3";
import SearchHub from "../components/SearchHub/SearchHub";
import Loading from "../components/Loading/Loading";
import Button from "../components/Buttons/Button";
import SearchBar from "../components/SearchBar/SearchBar";
import Bold from "../components/Text/Bold";

import sfh_wordmark from "../graphics/branding/sfh_wordmark.svg";
import reserve from "../graphics/icons/reserve.svg";
import reserved from "../graphics/icons/reserved.svg";
import Hint from "../components/Hint/Hint";

const TasksPage = ({
  credentials = { id: 0, reservations: {} },
  setCredentials = {},
  updateCredentials = {},
  isDesktop = null,
  translation = null,
  activePage = null,
  setActivePage = {},
  dimensions = null,
  currentReservations = {},
  setCurrentReservations = {},
  setCompanyViewportVisible = {},
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [companies, setCompanies] = useState(null);
  const [allCompanies, setAllCompanies] = useState([]);
  const [taskType, setTaskType] = useState("");
  const [filterby, setFilterby] = useState(null);
  const [sortby, setSortby] = useState(null);
  const [scrollDistance, setScrollDistance] = useState(0);
  const [identifyProgress, setIdentifyProgress] = useState(0);
  const [tabulateProgress, setTabulateProgress] = useState(0);
  const [confirmProgress, setConfirmProgress] = useState(0);

  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  const getCredentials = async () => {
    axios
      .get(
        `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${credentials.id}`,
        requestConfig
      )
      .then((res) => {
        if (res.data.id === credentials.id) {
          updateCredentials(res.data);
        }
      })
      .catch((error) => {});
  };

  const getCompanies = async () => {
    await axios
      .get(`${process.env.REACT_APP_COMPANYS_API_URL}/companys`, requestConfig)
      .then((res) => {
        setCompanies(
          res.data.Items.sort((a, b) => {
            if (a.name.toUpperCase() < b.name.toUpperCase()) {
              return -1;
            }
            if (a.name.toUpperCase() > b.name.toUpperCase()) {
              return 1;
            }
          }).filter((company) => !!!company.classification)
        );
        setAllCompanies(
          res.data.Items.sort((a, b) => {
            if (a.name.toUpperCase() < b.name.toUpperCase()) {
              return -1;
            }
            if (a.name.toUpperCase() > b.name.toUpperCase()) {
              return 1;
            }
          })
        );
      })
      .catch((error) => {});
  };

  const reserveCompany = async (active, id, contributors) => {
    if (
      Object.keys(contributors).indexOf(String(credentials.id)) !== -1 &&
      credentials.id !== 1710392373694
    ) {
      alert(translation.tasks.already_contributed);
      return;
    }

    if (!!!taskType) {
      alert(translation.tasks.null_tasktype_message);
      return;
    }

    const reservationsentry = { [id]: Date.now() };
    let reservations = credentials.reservations;
    if (active) {
      if (
        window.confirm(
          translation.tasks.confirm_reserve_company_1 +
            translation.tasks[taskType].toLowerCase().split(" ")[0] +
            translation.tasks.confirm_reserve_company_2
        )
      ) {
        reservations = { ...credentials.reservations, ...reservationsentry };
        if (Object.keys(reservations).length >= 3) {
          return alert(translation.tasks.reservations_limit_reached);
        }
        await axios
          .post(
            `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${credentials.id}`,
            { reservations: reservations },
            requestConfig
          )
          .then(async (res) => {
            updateCredentials(res.data.account);
            await axios
              .post(
                `${process.env.REACT_APP_COMPANYS_API_URL}/companys/${id}`,
                { reserved: active ? String(credentials.id) : "none" },
                requestConfig
              )
              .catch((error) => {
                // console.log(error);
              });
          })
          .catch((error) => {
            // console.log(error);
          });
        setCurrentReservations(reservations);
      }
    } else {
      if (window.confirm(translation.tasks.confirm_remove_reservation)) {
        delete reservations[id];
        await axios
          .post(
            `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${credentials.id}`,
            { reservations: reservations },
            requestConfig
          )
          .then(async (res) => {
            updateCredentials(res.data.account);
            await axios
              .post(
                `${process.env.REACT_APP_COMPANYS_API_URL}/companys/${id}`,
                { reserved: active ? String(credentials.id) : "none" },
                requestConfig
              )
              .catch((error) => {
                // console.log(error);
              });
          })
          .catch((error) => {
            // console.log(error);
          });
        setCurrentReservations(reservations);
      }
    }
  };

  const openCompanyViewport = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_COMPANYS_API_URL}/companys/${id}`,
        requestConfig
      )
      .then((res) => {
        setCompanyViewportVisible({
          type:
            res.data.classification === ""
              ? "identify"
              : res.data.classification === "identified"
              ? "tabulate"
              : res.data.classification === "tabulated"
              ? "confirm"
              : "edit",
          company: res.data,
        });
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    getCompanies();
    setTaskType("identify");
  }, []);

  useEffect(() => {
    if (!!credentials && !!credentials.reservations) {
      setCurrentReservations(credentials.reservations);
    }
  }, [!!credentials]);

  useEffect(() => {
    if (!!credentials) {
      getCredentials();
    }
  }, [taskType]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, []);

  const filter = [
    "global",
    "united_states",
    "canada",
    "united_kingdom",
    "europe",
    "latin_america",
    "asia",
    "africa",
    "oceania",
  ];

  const sort = [
    "alphabetical_a",
    "alphabetical_z",
    "sustainability_score_high",
    "sustainability_score_low",
    "transparency_score_high",
    "transparency_score_low",
    "decarbonization_score_high",
    "decarbonization_score_low",
    "completeness_score_high",
    "completeness_score_low",
  ];

  const type = ["identify", "tabulate", "confirm"];

  return (
    <div style={{ flex: 1, overflowY: "hide" }}>
      {!!translation && dimensions ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            justifyContent: "center",
            width: isDesktop && dimensions.width - 290,
          }}
        >
          <div
            style={{
              textAlign: "center",
              paddingTop: 10,
              margin: "auto",
              maxWidth: isDesktop ? 600 : dimensions.width - 20,
              overflowY: "hide",
            }}
          >
            <div>
              <P style={{ fontSize: 48, margin: 10, fontWeight: 600 }}>
                {translation.navigation.tasks}
              </P>
              <div style={{ display: "flex" }}>
                <P style={{ fontSize: 16, margin: 10 }}>
                  {translation.tasks.main_cta}
                </P>
                <Hint offset={30} style={{ marginTop: 12, zIndex: 15 }}>
                  This page serves as the crowdsourcing nexus of the Sustainable
                  Finance Hub. Here you can reserve a company for one of the
                  three stages of company data aggregation and validation,
                  contributing to the platform's mission.
                  <br />
                  <br />
                  <img
                    src={reserve}
                    style={{
                      marginRight: 5,
                      marginBottom: -2,
                      marginLeft: -2,
                      width: 12,
                      height: 12,
                      filter: "brightness(1000)",
                    }}
                  />
                  You can reserve up to two companies to work on at a time. If
                  the data supplied for a Stage 2 or Stage 3 company appears to
                  be inaccurate or incomplete, you have the ability to relegate
                  said company to the previous stage for proper assessment.
                </Hint>
              </div>
              <H2
                style={{
                  textAlign: "center",
                  fontStyle: "italic",
                  marginBottom: 10,
                }}
              >
                Join the cause today!
              </H2>
            </div>
            <div
              style={{
                display: "flex",
                margin: "auto",
                width: 395,
              }}
            >
              <select
                style={{
                  padding: 10,
                  color: !!taskType ? "#ffffff" : "#000000",
                  backgroundColor: !!taskType
                    ? "rgba(16, 16, 16, 0.975)"
                    : isDesktop
                    ? "#eeeff0"
                    : "#ffffff",
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor: !!taskType ? "#101010" : "#ccc",
                  borderRadius: 8,
                  fontSize: 14,
                  height: 40,
                  marginTop: 10,
                  minWidth: 395,
                }}
                value={!taskType ? "" : taskType}
                onChange={(event) => {
                  setTaskType(
                    event.target.value !== "clear" ? event.target.value : null
                  );
                  setCompanies(() => {
                    if (event.target.value === "all") {
                      return allCompanies;
                    } else if (event.target.value === "identify") {
                      return allCompanies.filter(
                        (company) => !!!company.classification
                      );
                    } else if (event.target.value === "tabulate") {
                      return allCompanies.filter(
                        (company) => company.classification === "identified"
                      );
                    } else if (event.target.value === "confirm") {
                      return allCompanies.filter(
                        (company) => company.classification === "tabulated"
                      );
                    }
                  });
                }}
              >
                {type.map((ordering, index) => (
                  <option key={index} value={ordering}>
                    {translation.tasks[ordering]}
                  </option>
                ))}
              </select>
              {taskType === "identify" ? (
                <Hint
                  iconColor="white"
                  size={16}
                  offset={7.3}
                  tableHint={true}
                  style={{
                    marginTop: 24,
                    marginLeft: -250,
                    zIndex: 10,
                  }}
                >
                  <span style={{ color: "#f2c94c" }}>Identification</span>&nbsp;
                  is the first stage of the crowdsourcing workflow. Here
                  contributors provide links to the first-hand documentation for
                  the company they reserved to work on.
                </Hint>
              ) : taskType === "tabulate" ? (
                <Hint
                  iconColor="white"
                  size={16}
                  offset={7.3}
                  tableHint={true}
                  style={{
                    marginTop: 24,
                    marginLeft: -265,
                    zIndex: 10,
                  }}
                >
                  <span style={{ color: "#13ed46" }}>Tabulation</span>&nbsp; is
                  the second stage of the crowdsourcing workflow. Here
                  contributors use the provided first-hand documentation to
                  record contextually relevant numbers in a table for the
                  company they reserved to work on.
                </Hint>
              ) : (
                <Hint
                  iconColor="white"
                  size={16}
                  offset={7.3}
                  tableHint={true}
                  style={{
                    marginTop: 24,
                    marginLeft: -250,
                    zIndex: 10,
                  }}
                >
                  <span style={{ color: "#90d1fc" }}>Confirmation</span>&nbsp;
                  is the third stage of the crowdsourcing workflow. Here
                  contributors perform a final review on accuracy and
                  completeness for the company they reserved to work on.
                </Hint>
              )}
            </div>
          </div>
          <div
            style={{
              height: isDesktop
                ? dimensions.height - 290
                : dimensions.height - 390,
              margin: 10,
              marginTop: 12,
              overflowY: "scroll",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
              backgroundColor: "rgba(16, 16, 16, 0.975)",
              borderRadius: 8,
            }}
          >
            {!!companies ? (
              <div
                style={{
                  paddingTop: 10,
                  display: "flex",
                  flexWrap: "wrap",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                {companies.map((company, index) => (
                  <div
                    key={index}
                    style={{
                      margin: 10,
                      borderRadius: 8,
                      borderWidth: 1,
                      borderColor: "#101010",
                      borderStyle: "solid",
                      opacity:
                        ((!!company.reserved &&
                          company.reserved !== "none" &&
                          Number(company.reserved) !== Number(credentials.id) &&
                          company.reserved !== "reviewing") ||
                          (Object.keys(company.contributors).indexOf(
                            String(credentials.id)
                          ) !== -1 &&
                            credentials.id !== 1710392373694)) &&
                        "50%",
                    }}
                    onMouseOver={({ currentTarget }) => {
                      if (!!!company.reserved || company.reserved === "none") {
                        currentTarget.style.opacity = "50%";
                      }
                    }}
                    onMouseOut={({ currentTarget }) => {
                      if (
                        (!!!company.reserved || company.reserved === "none") &&
                        !(
                          Object.keys(company.contributors).indexOf(
                            String(credentials.id)
                          ) !== -1 && credentials.id !== 1710392373694
                        )
                      ) {
                        currentTarget.style.opacity = "100%";
                      }
                    }}
                    onClick={() => {
                      if (!!credentials) {
                        if (
                          !!!company.reserved ||
                          company.reserved === "none"
                        ) {
                          if (
                            !(
                              (!!currentReservations &&
                                !!currentReservations[company.id]) ||
                              Number(company.reserved) ===
                                Number(credentials.id)
                            )
                          ) {
                            reserveCompany(
                              !currentReservations[company.id],
                              company.id,
                              company.contributors
                            );
                          }
                        } else {
                          if (company.reserved === "reviewing") {
                            alert("This company currently under review.");
                          } else if (
                            Number(company.reserved) !== Number(credentials.id)
                          ) {
                            alert(
                              "This company has already been reserved for this task by someone else!"
                            );
                          }
                        }
                      } else {
                        alert(
                          "To reserve this company for updates/maintenance you need to be signed in first!"
                        );
                      }
                    }}
                  >
                    <div
                      style={{
                        cursor: "pointer",
                        textAlign: "right",
                        backgroundColor: "#ffffff",
                        borderColor: "#ffffff",
                        borderRadius: 0,
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        height: 20,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {(!!!company.reserved ||
                        company.reserved === "none" ||
                        Number(company.reserved) === Number(credentials.id)) &&
                      !(
                        Object.keys(company.contributors).indexOf(
                          String(credentials.id)
                        ) !== -1 && credentials.id !== 1710392373694
                      ) ? (
                        <img
                          src={
                            !!credentials &&
                            !!currentReservations &&
                            !!currentReservations[company.id]
                              ? reserved
                              : reserve
                          }
                          style={{
                            margin: 5,
                            width: 20,
                            height: 20,
                          }}
                        />
                      ) : (
                        <div style={{ height: 30 }} />
                      )}

                      {!!credentials &&
                      !!currentReservations &&
                      !isNaN(currentReservations[company.id]) ? (
                        <div
                          style={{
                            position: "relative",
                            padding: 10,
                            backgroundColor: "rgba(0,0,0,.85)",
                          }}
                        >
                          <Button
                            type="solid"
                            style={{
                              backgroundColor: "#64804d",
                              fontSize: 14,
                              paddingTop: 5,
                              paddingRight: 10,
                              paddingBottom: 5,
                              paddingLeft: 10,
                              marginBottom: 10,
                            }}
                            onClick={() => {
                              openCompanyViewport(company.id);
                            }}
                          >
                            {translation.button.manage}
                          </Button>

                          <Button
                            type="solid"
                            style={{
                              fontSize: 14,
                              paddingTop: 5,
                              paddingRight: 10,
                              paddingBottom: 5,
                              paddingLeft: 10,
                            }}
                            onClick={() => {
                              reserveCompany(
                                false,
                                company.id,
                                company.contributors
                              );
                            }}
                          >
                            {translation.button.remove}
                          </Button>
                        </div>
                      ) : !!credentials &&
                        !!currentReservations &&
                        currentReservations[company.id] === "reviewing" ? (
                        <div
                          style={{
                            position: "relative",
                            top: 4,
                            padding: 10,
                            backgroundColor: "rgba(0,0,0,.85)",
                            height: 64,
                          }}
                        >
                          <H3
                            style={{
                              marginTop: 20,
                              color: "#ffffff",
                              textAlign: "center",
                              fontStyle: "italic",
                            }}
                          >
                            {translation.company.under_review}
                          </H3>
                        </div>
                      ) : null}
                    </div>
                    <div
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        backgroundColor: "#ffffff",
                        borderRadius: 0,
                        paddingBottom: 20,
                        height: 100,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        style={{
                          margin: "auto",
                          maxWidth: 130,
                          maxHeight: 80,
                          padding: 10,
                          objectFit: "cover",
                        }}
                        src={company.image || sfh_wordmark}
                      />
                    </div>
                    <div
                      style={{
                        cursor: "pointer",
                        borderRadius: 0,
                        borderBottomLeftRadius: 8,
                        borderBottomRightRadius: 8,
                        backgroundColor: "#64804d",
                        width: 150,
                        height: 75,
                        textAlign: "left",
                        borderTopColor: "101010",
                        borderTopWidth: 1,
                        borderTopStyle: "solid",
                      }}
                    >
                      <div style={{ padding: 10, color: "#ffffff" }}>
                        <Bold style={{ marginBottom: 10, fontSize: 15 }}>
                          {company.name.length > 30
                            ? `${company.name.substring(0, 27)}...`
                            : company.name}
                        </Bold>
                        <P
                          style={{
                            opacity: "50%",
                            fontStyle: "italic",
                            fontSize: 11,
                            marginTop: -5,
                          }}
                        >
                          {company.ticker}
                        </P>
                      </div>
                    </div>
                  </div>
                ))}
                <br />
              </div>
            ) : (
              <Loading
                style={{
                  position: "fixed",
                  top: "52.5%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            )}
          </div>
        </div>
      ) : (
        <Loading
          style={{
            position: "fixed",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      )}
    </div>
  );
};

export default TasksPage;

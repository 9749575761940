import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

const EmissionsChart = ({ metrics = [], width, slug }) => {
  const [data, setData] = useState(metrics);
  const colors = ["#c3b091", "#64804d", "#777777"];

  let max = 0;

  for (let i = 0; i < metrics.length; i++) {
    if (
      max <
      data[i].ghg_scope_1 +
        data[i].ghg_scope_2_location_based +
        data[i].ghg_scope_3
    ) {
      max =
        data[i].ghg_scope_1 +
        data[i].ghg_scope_2_location_based +
        data[i].ghg_scope_3;
    }
  }

  const svgRef = useRef();
  const tooltipRef = useRef();

  useEffect(() => {
    const w = width;
    const h = (width * 9) / 16;
    const svg = d3
      .select(svgRef.current)
      .attr("width", w)
      .attr("height", h)
      .style("overflow", "visible");

    const tooltip = d3
      .select(tooltipRef.current)
      .style("position", "absolute")
      .style("padding", "8px")
      .style("background", "lightgrey")
      .style("border-radius", "4px")
      .style("opacity", 0)
      .style("pointer-events", "none");

    const xScale = d3
      .scaleBand()
      .domain(data.map((d, i) => i))
      .range([0, w]);

    const yScale = d3
      .scaleLinear()
      .domain([0, max * 1.1])
      .range([h, 0]);

    const z = d3.scaleOrdinal().range(colors);

    const xAxis = d3
      .axisBottom(xScale)
      .ticks(metrics.length - 2, 2 + data.length)
      .tickFormat((d, i) => i + 2018);

    const yAxis = d3
      .axisLeft(yScale)
      .ticks(7)
      .tickFormat((d) => d / 1000 + " mtCO2e");

    svg.selectAll("*").remove(); // Clear previous content

    svg
      .append("g")
      .selectAll("g")
      .data(d3.stack().keys(Object.keys(data[0]))(data))
      .enter()
      .append("g")
      .attr("fill", (d) => z(d.key))
      .selectAll("rect")
      .data((d) => d)
      .enter()
      .append("rect")
      .attr("x", (d, i) => i * (width / data.length) + 10)
      .attr("y", (d) => yScale(d[1]))
      .attr("height", (d) => yScale(d[0]) - yScale(d[1]))
      .attr("width", width / data.length - 20)
      .on("mouseover", function (event, d) {
        tooltip
          .style("opacity", 1)
          .html(`Value: ${(d[1] - d[0]).toFixed(2)} mtCO2e`)
          .style("left", `${event.pageX + 10}px`)
          .style("top", `${event.pageY - 20}px`);
      })
      .on("mousemove", (event) => {
        tooltip
          .style("left", `${event.pageX + 10}px`)
          .style("top", `${event.pageY - 20}px`);
      })
      .on("mouseleave", () => {
        tooltip.style("opacity", 0);
      });

    svg
      .append("g")
      .call(xAxis)
      .attr("transform", `translate(0,${h})`)
      .attr("font-family", "serif");

    svg.append("g").call(yAxis).attr("font-family", "serif");
  }, [data]);

  return (
    <div style={{ position: "relative" }}>
      <svg
        ref={svgRef}
        style={{
          margin: 30,
          marginTop: 0,
          display: "block",
        }}
      ></svg>
      <div ref={tooltipRef}></div>
    </div>
  );
};

export default EmissionsChart;

"use es6";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

import P from "../components/Text/P";
import H2 from "../components/Text/H2";
import SearchHub from "../components/SearchHub/SearchHub";
import Loading from "../components/Loading/Loading";
import Button from "../components/Buttons/Button";
import SearchBar from "../components/SearchBar/SearchBar";
import Bold from "../components/Text/Bold";

import sfh_wordmark from "../graphics/branding/sfh_wordmark.svg";
import bookmark from "../graphics/icons/bookmark.svg";
import bookmarked from "../graphics/icons/bookmarked.svg";
import H3 from "../components/Text/H3";
import Hint from "../components/Hint/Hint";

const ExplorePage = ({
  credentials = { bookmarks: {} },
  setCredentials = {},
  updateCredentials = {},
  isDesktop = null,
  translation = null,
  activePage = null,
  setActivePage = {},
  dimensions = null,
  currentBookmarks = {},
  setCurrentBookmarks = {},
  setCompanyViewportVisible = {},
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [companies, setCompanies] = useState(null);
  const [allCompanies, setAllCompanies] = useState([]);
  const [filterby, setFilterby] = useState("");
  const [sortby, setSortby] = useState("");
  const [scrollDistance, setScrollDistance] = useState(0);

  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  const getCompanies = async () => {
    const cache = await JSON.parse(localStorage.getItem("sfh-companies"));
    if (
      !!cache &&
      !isNaN(cache.timestamp) &&
      Date.now() - cache.timestamp < 300000
    ) {
      setCompanies(cache.data);
      setAllCompanies(
        cache.data.sort((a, b) => {
          if (a.name.toUpperCase() < b.name.toUpperCase()) {
            return -1;
          }
          if (a.name.toUpperCase() > b.name.toUpperCase()) {
            return 1;
          }
        })
      );
    } else {
      await axios
        .get(
          `${process.env.REACT_APP_COMPANYS_API_URL}/companys`,
          requestConfig
        )
        .then((res) => {
          setCompanies(res.data.Items);
          setAllCompanies(
            res.data.Items.sort((a, b) => {
              if (a.name.toUpperCase() < b.name.toUpperCase()) {
                return -1;
              }
              if (a.name.toUpperCase() > b.name.toUpperCase()) {
                return 1;
              }
            })
          );
          localStorage.setItem(
            "sfh-companies",
            JSON.stringify({ timestamp: Date.now(), data: res.data.Items })
          );
        })
        .catch((error) => {});
    }
  };

  const bookmarkCompany = async (active, id) => {
    const bookmarksentry = { [id]: Date.now() };
    let bookmarks = credentials.bookmarks;
    if (active) {
      bookmarks = { ...credentials.bookmarks, ...bookmarksentry };
      if (Object.keys(bookmarks).length >= 11) {
        return alert(translation.company.boomark_limit_reached);
      }
      setCurrentBookmarks(bookmarks);
    } else {
      if (window.confirm(translation.company.confirm_remove_bookmark)) {
        delete bookmarks[id];
        setCurrentBookmarks(bookmarks);
      }
    }

    await axios
      .post(
        `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${credentials.id}`,
        { bookmarks: bookmarks },
        requestConfig
      )
      .then(async (res) => {
        updateCredentials(res.data.account);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    if (!!credentials && !!credentials.bookmarks) {
      setCurrentBookmarks(credentials.bookmarks);
    }
  }, [!!credentials]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, []);

  const filter = [
    "global",
    "united_states",
    "canada",
    "united_kingdom",
    "europe",
    "latin_america",
    "asia",
    "africa",
    "oceania",
  ];

  const sort = [
    "alphabetical_a",
    "alphabetical_z",
    "sustainability_score_high",
    "sustainability_score_low",
    "transparency_score_high",
    "transparency_score_low",
    "decarbonization_score_high",
    "decarbonization_score_low",
    "completeness_score_high",
    "completeness_score_low",
  ];

  return (
    <div style={{ flex: 1, overflowY: "hide" }}>
      {!!translation && dimensions ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            justifyContent: "center",
            width: isDesktop && dimensions.width - 290,
          }}
        >
          <div
            style={{
              textAlign: "center",
              paddingTop: 10,
              margin: "auto",
              maxWidth: isDesktop ? 600 : dimensions.width - 20,
              overflowY: "hide",
            }}
          >
            <div>
              <P style={{ fontSize: 48, margin: 10, fontWeight: 600 }}>
                {translation.navigation.explore}
              </P>
              <div style={{ display: "flex" }}>
                <P style={{ fontSize: 16, margin: 10 }}>
                  {translation.explore.main_cta}
                </P>
                <Hint offset={30} style={{ marginTop: 12, zIndex: 10 }}>
                  On each company card there are colored indicators meant to
                  signify how comprehensive its respective crowdsoureced data is
                  for the most recent financial year.
                  <br />
                  <br />
                  <img
                    src={bookmark}
                    style={{
                      marginRight: 5,
                      marginBottom: -2,
                      marginLeft: -2,
                      width: 12,
                      height: 12,
                      filter: "brightness(1000)",
                    }}
                  />
                  Additionly to more easily keep track of your comapnies of
                  interest, you can bookmark up to 10 different companies. These
                  bookmarks are also then accessible from your profile page.
                </Hint>
              </div>
              <div
                style={{
                  display: "flex",
                  borderRadius: 8,
                  padding: 5,
                  paddingLeft: 10,
                  paddingRight: 10,
                  backgroundColor: "#000000",
                  justifyContent: "space-between",
                  width: 374,
                  margin: "auto",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <P style={{ color: "#ed6826", fontSize: 16, margin: 1 }}>
                    <span style={{ fontSize: 13 }}>Orange Dots</span>
                  </P>
                  <Hint
                    iconColor="white"
                    size={16}
                    offset={7.3}
                    tableHint={true}
                    style={{ marginTop: 4, marginLeft: 6 }}
                  >
                    These indicate that the most recent financial and
                    sustainability data for a company is CONTESTED, meaning that
                    no first-hand documentation has been provided yet.
                  </Hint>
                </div>

                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <P style={{ color: "#f2c94c", fontSize: 16, margin: 1 }}>
                    <span style={{ fontSize: 13 }}>Yellow Dots</span>
                  </P>
                  <Hint
                    iconColor="white"
                    size={16}
                    offset={16.1}
                    tableHint={true}
                    style={{ marginTop: 4, marginLeft: 6 }}
                  >
                    These indicate that the most recent financial and
                    sustainability data for a company is IDENTIFIED, meaning
                    that first-hand documentation has been provided.
                  </Hint>
                </div>

                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <P style={{ color: "#13ed46", fontSize: 16, margin: 1 }}>
                    <span style={{ fontSize: 13 }}>Green Dots</span>
                  </P>
                  <Hint
                    iconColor="white"
                    size={16}
                    offset={22.3}
                    tableHint={true}
                    style={{ marginTop: 4, marginLeft: 6 }}
                  >
                    These indicate that the most recent financial and
                    sustainability data for a company is TABULATED, meaning that
                    contextually relevent numbers from the provided first-hand
                    documentation have been recorded in a table for easy access.
                  </Hint>
                </div>

                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <P style={{ color: "#90d1fc", fontSize: 16, margin: 1 }}>
                    <span style={{ fontSize: 13 }}>Blue Dots</span>
                  </P>
                  <Hint
                    iconColor="white"
                    size={16}
                    offset={29.9}
                    tableHint={true}
                    style={{ marginTop: 4, marginLeft: 6 }}
                  >
                    These indicate that the most recent financial and
                    sustainability data for a company is CONFIRMED, meaning that
                    all the prior information has been triple-checked for
                    accuracy and completeness.
                  </Hint>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: 40,
              }}
            >
              <SearchBar
                isDesktop={isDesktop}
                translation={translation}
                browsingUsers={true}
                setActivePage={setActivePage}
              />
            </div>
            <div
              style={{
                display: "flex",
                margin: 10,
                justifyContent: "center",
              }}
            >
              <select
                style={{
                  padding: 10,
                  color: !!filterby ? "#ffffff" : "#000000",
                  backgroundColor: !!filterby
                    ? "rgba(16, 16, 16, 0.975)"
                    : isDesktop
                    ? "#eeeff0"
                    : "#ffffff",
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor: !!filterby ? "#101010" : "#ccc",
                  borderRadius: 8,
                  fontSize: 14,
                  height: 40,
                  minWidth: 140,
                }}
                value={!filterby ? "" : filterby}
                onChange={(event) => {
                  setFilterby(
                    event.target.value !== "clear" ? event.target.value : null
                  );
                  setCompanies(() => {
                    if (event.target.value === "global") {
                      return allCompanies;
                    } else if (event.target.value === "united_states") {
                      return allCompanies.filter(
                        (company) => company.slug.indexOf("_us") !== -1
                      );
                    } else if (event.target.value === "canada") {
                      return allCompanies.filter(
                        (company) => company.slug.indexOf("_ca") !== -1
                      );
                    } else if (event.target.value === "united_kingdom") {
                      return allCompanies.filter(
                        (company) => company.slug.indexOf("_gb") !== -1
                      );
                    } else if (event.target.value === "europe") {
                      return allCompanies.filter(
                        (company) =>
                          company.slug.indexOf("_fr") !== -1 ||
                          company.slug.indexOf("_it") !== -1 ||
                          company.slug.indexOf("_de") !== -1 ||
                          company.slug.indexOf("_pt") !== -1 ||
                          company.slug.indexOf("_nl") !== -1 ||
                          company.slug.indexOf("_es") !== -1 ||
                          company.slug.indexOf("_ch") !== -1 ||
                          company.slug.indexOf("_se") !== -1 ||
                          company.slug.indexOf("_dk") !== -1
                      );
                    } else if (event.target.value === "latin_america") {
                      return allCompanies.filter(
                        (company) =>
                          company.slug.indexOf("_mx") !== -1 ||
                          company.slug.indexOf("_br") !== -1
                      );
                    } else if (event.target.value === "asia") {
                      return allCompanies.filter(
                        (company) =>
                          company.slug.indexOf("_jp") !== -1 ||
                          company.slug.indexOf("_cn") !== -1 ||
                          company.slug.indexOf("_in") !== -1 ||
                          company.slug.indexOf("_hk") !== -1 ||
                          company.slug.indexOf("_kr") !== -1
                      );
                    } else if (event.target.value === "africa") {
                      return allCompanies.filter(
                        (company) =>
                          company.slug.indexOf("_ng") !== -1 ||
                          company.slug.indexOf("_za") !== -1
                      );
                    } else if (event.target.value === "oceania") {
                      return allCompanies.filter(
                        (company) => company.slug.indexOf("_au") !== -1
                      );
                    } else {
                      return allCompanies;
                    }
                  });
                }}
              >
                {filter.map((ordering, index) => (
                  <option key={index} value={ordering}>
                    {translation.explore[ordering]}
                  </option>
                ))}
              </select>

              <select
                style={{
                  padding: 10,
                  color: !!sortby ? "#ffffff" : "#000000",
                  backgroundColor: !!sortby
                    ? "rgba(16, 16, 16, 0.975)"
                    : isDesktop
                    ? "#eeeff0"
                    : "#ffffff",
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor: !!sortby ? "#101010" : "#ccc",
                  borderRadius: 8,
                  fontSize: 14,
                  height: 40,
                  marginLeft: 10,
                  minWidth: 200,
                }}
                value={!sortby ? "" : sortby}
                onChange={(event) => {
                  setSortby(
                    event.target.value !== "clear" ? event.target.value : null
                  );
                  setCompanies((prevCompanies) =>
                    prevCompanies.sort((a, b) => {
                      if (event.target.value === "clear") {
                        return 0;
                      } else if (event.target.value === "alphabetical_a") {
                        if (a.name.toUpperCase() < b.name.toUpperCase()) {
                          return -1;
                        }
                        if (a.name.toUpperCase() > b.name.toUpperCase()) {
                          return 1;
                        }
                      } else if (event.target.value === "alphabetical_z") {
                        if (a.name.toUpperCase() < b.name.toUpperCase()) {
                          return 1;
                        }
                        if (a.name.toUpperCase() > b.name.toUpperCase()) {
                          return -1;
                        }
                      } else {
                        const value = event.target.value.split("_")[0];
                        if (event.target.value === `${value}_score_low`) {
                          if (a.scores[value] < b.scores[value]) {
                            return -1;
                          }
                          if (a.scores[value] > b.scores[value]) {
                            return 1;
                          }
                        } else if (
                          event.target.value === `${value}_score_high`
                        ) {
                          if (a.scores[value] > b.scores[value]) {
                            return -1;
                          }
                          if (a.scores[value] < b.scores[value]) {
                            return 1;
                          }
                        }
                      }
                    })
                  );
                }}
              >
                {sort.map((ordering, index) => (
                  <option key={index} value={ordering}>
                    {translation.explore[ordering]}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div
            style={{
              height: isDesktop
                ? dimensions.height - 290
                : dimensions.height - 390,
              margin: 10,
              marginTop: 0,
              overflowY: "scroll",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
              backgroundColor: "rgba(16, 16, 16, 0.975)",
              borderRadius: 8,
            }}
          >
            {!!companies ? (
              <div
                style={{
                  paddingTop: 10,
                  display: "flex",
                  flexWrap: "wrap",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                {companies.map((company) => (
                  <div
                    key={company.slug}
                    style={{
                      margin: 10,
                      borderRadius: 8,
                      borderWidth: 1,
                      borderColor: "#101010",
                      borderStyle: "solid",
                    }}
                    onMouseOver={({ currentTarget }) => {
                      currentTarget.style.opacity = "50%";
                    }}
                    onMouseOut={({ currentTarget }) => {
                      currentTarget.style.opacity = "100%";
                    }}
                  >
                    <div
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "#ffffff",
                        borderColor: "#ffffff",
                        borderRadius: 0,
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        height: 20,
                      }}
                    >
                      <div
                        style={{
                          backgroundColor:
                            company.classification === ""
                              ? "#ed6826"
                              : company.classification === "identified"
                              ? "#f2c94c"
                              : company.classification === "tabulated"
                              ? "#13ed46"
                              : "#90d1fc",
                          borderRadius: 20,
                          margin: 10,
                          width: 12,
                          height: 12,
                        }}
                      />

                      <img
                        src={
                          !!credentials &&
                          !!currentBookmarks &&
                          !!currentBookmarks[company.id]
                            ? bookmarked
                            : bookmark
                        }
                        style={{
                          margin: 5,
                          width: 20,
                          height: 20,
                        }}
                        onClick={() => {
                          if (!!credentials) {
                            bookmarkCompany(
                              !currentBookmarks[company.id],
                              company.id
                            );
                          } else {
                            alert(
                              "To bookmark this company page you need to be signed in first!"
                            );
                          }
                        }}
                      />
                    </div>
                    <div
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        backgroundColor: "#ffffff",
                        borderRadius: 0,
                        paddingBottom: 20,
                        height: 100,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        navigate(`/explore/${company.slug}`);
                      }}
                    >
                      <img
                        style={{
                          margin: "auto",
                          maxWidth: 130,
                          maxHeight: 80,
                          padding: 10,
                          objectFit: "cover",
                        }}
                        src={company.image || sfh_wordmark}
                      />
                    </div>
                    <div
                      style={{
                        cursor: "pointer",
                        borderRadius: 0,
                        borderBottomLeftRadius: 8,
                        borderBottomRightRadius: 8,
                        backgroundColor: "#64804d",
                        width: 150,
                        height: 75,
                        textAlign: "left",
                        borderTopColor: "101010",
                        borderTopWidth: 1,
                        borderTopStyle: "solid",
                      }}
                      onClick={() => {
                        navigate(`/explore/${company.slug}`);
                      }}
                    >
                      <div style={{ padding: 10, color: "#ffffff" }}>
                        <Bold style={{ marginBottom: 10, fontSize: 15 }}>
                          {company.name.length > 30
                            ? `${company.name.substring(0, 27)}...`
                            : company.name}
                        </Bold>
                        <P
                          style={{
                            opacity: "50%",
                            fontStyle: "italic",
                            fontSize: 11,
                            marginTop: -5,
                          }}
                        >
                          {company.ticker}
                        </P>
                      </div>
                    </div>
                  </div>
                ))}
                <br />
              </div>
            ) : (
              <Loading
                style={{
                  position: "fixed",
                  top: "60%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            )}
          </div>
        </div>
      ) : (
        <Loading
          style={{
            position: "fixed",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      )}
    </div>
  );
};

export default ExplorePage;

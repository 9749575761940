"use es6";

import { useState, useEffect } from "react";
import P from "../Text/P";

const ReportDataCell = ({
  translation = null,
  reportData,
  newReportData,
  setNewReportData,
  newReportDataSources,
  setNewReportDataSources,
  variable = null,
  year = null,
  reports = null,
  type = null,
  enterprisevalue = null,
  setEnterprisevalue,
  historicalmarketcap = null,
  setHistoricalmarketcap,
}) => {
  const [cellVisible, setCellVisible] = useState(
    newReportData[year][variable] !== null &&
      newReportData[year][variable] !== undefined
      ? true
      : false
  );

  useEffect(() => {
    const newenterprisevalue =
      0 +
      Number(
        newReportData[year]["historical_market_cap"] !== null &&
          newReportData[year]["historical_market_cap"] !== undefined
          ? newReportData[year]["historical_market_cap"]
          : 0
      ) +
      Number(
        newReportData[year]["short_and_long_term_debt"] !== null &&
          newReportData[year]["short_and_long_term_debt"] !== undefined
          ? newReportData[year]["short_and_long_term_debt"]
          : 0
      ) -
      Number(
        newReportData[year]["cash_and_marketable_securities"] !== null &&
          newReportData[year]["cash_and_marketable_securities"] !== undefined
          ? newReportData[year]["cash_and_marketable_securities"]
          : 0
      );

    setEnterprisevalue(newenterprisevalue);
    setNewReportData({
      ...newReportData,
      [year]: {
        ...newReportData[year],
        ["enterprise_value"]: newenterprisevalue,
      },
    });
  }, [
    newReportData[year]["historical_market_cap"],
    newReportData[year]["short_and_long_term_debt"],
    newReportData[year]["cash_and_marketable_securities"],
  ]);

  useEffect(() => {
    const newhistoricalmarketcap =
      0 +
      Number(
        newReportData[year]["is_avg_num_sh_for_eps"] !== null &&
          newReportData[year]["is_avg_num_sh_for_eps"] !== undefined
          ? newReportData[year]["is_avg_num_sh_for_eps"]
          : 0
      ) *
        Number(
          newReportData[year]["px_last"] !== null &&
            newReportData[year]["px_last"] !== undefined
            ? newReportData[year]["px_last"]
            : 0
        );

    setHistoricalmarketcap(newhistoricalmarketcap);
    setNewReportData({
      ...newReportData,
      [year]: {
        ...newReportData[year],
        ["historical_market_cap"]: newhistoricalmarketcap,
      },
    });
  }, [
    newReportData[year]["is_avg_num_sh_for_eps"],
    newReportData[year]["px_last"],
  ]);

  return (
    <div
      style={{
        opacity: true ? "100%" : "0%",
        display:
          type === "confirm" && !reportData[year][variable] ? "none" : "block",
      }}
      onClick={() => {
        setCellVisible(true);
      }}
    >
      {variable === "enterprise_value" ? (
        <P>{enterprisevalue}</P>
      ) : variable === "historical_market_cap" ? (
        <P>{historicalmarketcap}</P>
      ) : (
        <input
          type="text"
          value={newReportData[year][variable]}
          placeholder={translation.miscellaneous.value}
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0)",
            borderWidth: 0,
          }}
          onChange={(event) => {
            if (!!reports[year]) {
              setNewReportData({
                ...newReportData,
                [year]: {
                  ...newReportData[year],
                  [variable]: event.target.value,
                },
              });
            }
          }}
        />
      )}

      {!!reports[year] &&
      variable !== "enterprise_value" &&
      variable !== "historical_market_cap" ? (
        <div>
          <hr />
          <input
            type="text"
            value={newReportDataSources[year][variable]}
            placeholder={translation.miscellaneous.page_number}
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0)",
              borderWidth: 0,
            }}
            onChange={(event) => {
              setNewReportDataSources({
                ...newReportDataSources,
                [year]: {
                  ...newReportDataSources[year],
                  [variable]: event.target.value,
                },
              });
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ReportDataCell;
